import { useId } from 'react';

import { css } from '@emotion/react';

import type { SerializedStyles } from '@emotion/react';
import type { ComponentPropsWithoutRef } from 'react';

interface Props extends ComponentPropsWithoutRef<'svg'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
	shineColor?: string;
	gradientAngle?: number;
	gradientOpacity?: number;
	offsetGap?: number;
	offsetPadding?: number;
}

export default function ContentSkeleton(props: Props) {
	const {
		cssExtra,
		shineColor = 'var(--muted-color-3)',
		gradientAngle = 0,
		gradientOpacity = 0.25,
		offsetGap = 0.5,

		...delegatedProps
	} = props;

	const defsId = useId();
	const gradientId = `${defsId}--gradient`;
	const clipPathId = `${defsId}--clip`;

	const stopAnimateAttributes = (n: number, i: number) => {
		const id = `animate${i}`;
		return {
			id,
			attributeName: 'offset',
			calcMode: 'spline',
			keySplines: '0.42 0 0.58 1.0',
			begin: `0s;${id}.end+0.5s`,
			dur: '2s',
			from: n,
			to: n + 1 + offsetGap,
		};
	};

	const content = (
		<>
			<rect height={15} rx={2} ry={2} width={100} />
			<rect height={8} rx={2} ry={2} width={50} x={0} y={27} />
			<rect height={8} rx={2} ry={2} width={240} y={38} />
			<rect height={16} rx={2} ry={2} width={160} y={62} />
			<rect height={12} rx={2} ry={2} width={42} x={48} y={94} />
			<rect height={12} rx={2} ry={2} width={42} y={94} />
		</>
	);

	const offsets = [0 - offsetGap, 0 - offsetGap / 2, 0];

	return (
		<svg css={[css``, cssExtra]} viewBox={'0 0 240 106'} xmlns={'http://www.w3.org/2000/svg'} {...delegatedProps}>
			<defs>
				<linearGradient
					gradientTransform={`rotate(${gradientAngle})`}
					gradientUnits={'userSpaceOnUse'}
					id={gradientId}
				>
					{offsets.map((n, i) => (
						<stop
							key={n}
							offset={n}
							stopColor={shineColor}
							stopOpacity={i === 0 || i === offsets.length - 1 ? 0 : gradientOpacity}
						>
							<animate {...stopAnimateAttributes(n, i)} />
						</stop>
					))}
				</linearGradient>
				<clipPath id={clipPathId}>{content}</clipPath>
			</defs>
			<rect clipPath={`url(#${clipPathId})`} fill={`url(#${gradientId})`} height={'100%'} width={'100%'} />
		</svg>
	);
}
