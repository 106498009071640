import { css } from '@emotion/react';

import DistressedUnderlineAnchor from '../../buttons/DistressedUnderlineAnchor';
import { contentClass as sectionClass } from './ArticleSection';
import { contentClass as subsectionClass } from './ArticleSubsection';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'nav'> {
	sections: TableOfContentsSection[];
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export interface TableOfContentsItem {
	header: string;
	id: string;
}

export interface TableOfContentsSection {
	section: TableOfContentsItem;
	subsections: TableOfContentsItem[];
}

const parseSection = (section: Element, selector = 'h2') => {
	const { id } = section;
	const header = section.querySelector(selector)?.textContent ?? undefined;

	return { header, id };
};

const evaluateInfo = (info: Partial<TableOfContentsItem>): info is TableOfContentsItem => {
	return !!(info.header && info.id);
};

const listStyle = css`
	padding-left: clamp(1em, 5vw, 2em);
`;

const listItemStyle = css`
	padding-left: 0.7em;

	&::marker {
		font-size: 0.8em;
		font-weight: 700;
		color: var(--accent-color-b);
	}
`;

export const parseSections = () => {
	return [...document.getElementsByClassName(sectionClass)].reduce((acc0, section) => {
		const sectionItem = parseSection(section);
		if (!evaluateInfo(sectionItem)) return acc0;

		const subsections = [...section.getElementsByClassName(subsectionClass)].reduce((acc1, subsection) => {
			const subsectionItem = parseSection(subsection, 'h3');
			if (!evaluateInfo(subsectionItem)) return acc1;

			acc1.push(subsectionItem);

			return acc1;
		}, [] as TableOfContentsItem[]);

		acc0.push({
			section: sectionItem,
			subsections,
		});

		return acc0;
	}, [] as TableOfContentsSection[]);
};

export const contentClass = 'article-toc';

export default function ArticleTableOfContents(props: Props) {
	const { sections, cssExtra, ...delegatedAttributes } = props;

	if (!sections.length) return null;

	return (
		<div
			className={contentClass}
			css={css`
				padding: 3em 0 6.5em;
				margin: 3em 0;
			`}
		>
			<h2
				css={css`
					font-size: 1.4em;
				`}
			>
				Table of Contents
			</h2>
			<nav
				css={css`
					${cssExtra};
				`}
				{...delegatedAttributes}
			>
				<ol
					css={css`
						margin: 1.4em 0 0 min(4vw, 1.4em);
						text-transform: capitalize;
						${listStyle};
					`}
				>
					{sections.map(({ section, subsections }) => {
						return (
							<li key={section.id} css={listItemStyle}>
								<DistressedUnderlineAnchor href={`#${section.id}`}>
									{section.header}
								</DistressedUnderlineAnchor>
								<ol
									css={css`
										margin-left: 0;

										&:not(:last-child) {
											margin-bottom: 0.1em;
										}

										${listStyle};
									`}
									type={'a'}
								>
									{subsections.map(subsection => {
										return (
											<li
												key={subsection.id}
												css={css`
													${listItemStyle};

													&::marker {
														color: var(--accent-color-b-alt-3);
													}
												`}
											>
												<DistressedUnderlineAnchor href={`#${subsection.id}`}>
													{subsection.header}
												</DistressedUnderlineAnchor>
											</li>
										);
									})}
								</ol>
							</li>
						);
					})}
				</ol>
			</nav>
		</div>
	);
}
