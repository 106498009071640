import { css } from '@emotion/react';

import Main from '../../components/layout/view/Main';
import WavesBackgroundScaled from '../../components/layout/WavesBackgroundScaled';
import ErrorView from '../../components/layout/error/ErrorView';
import SetMeta from '../../components/utils/SetMeta';

export default function NotFoundPage() {
	return (
		<>
			<SetMeta nofollow noindex nosnippet title={'Error'} />
			<Main>
				<WavesBackgroundScaled
					colors={['var(--accent-color-b)', null, null, null, 'var(--accent-color-b)', null]}
				>
					<div
						css={css`
							position: absolute;
							top: 0;
							left: 0;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100%;
							height: 100%;
						`}
					>
						<ErrorView errorMessage={'Page Not Found'} errorStatus={'404'} />
					</div>
				</WavesBackgroundScaled>
			</Main>
		</>
	);
}
