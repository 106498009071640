import { useId } from 'react';

import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { SentinelError } from '@daresay/api-client';

import TextEmailInput from '../TextEmailInput';
import StyledButton, { Style } from '../../buttons/core/StyledButton';
import { useUser } from '../../../hooks/user';

import type { ComponentPropsWithoutRef, BaseSyntheticEvent, ReactElement } from 'react';
import type { SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import type { SerializedStyles } from '@emotion/react';

interface FormValues {
	email: string;
}

interface Props extends Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
	onSubmitInvalid?: SubmitErrorHandler<FormValues>;
	onSubmitStart?: SubmitHandler<FormValues>;
	onSubmitError?: (error: Error, formData: FormValues, event: BaseSyntheticEvent | undefined) => void;
	onSubmitSuccess?: SubmitHandler<FormValues>;
	renderSubmitButton?: (submitButton: ReactElement) => ReactElement | (ReactElement | undefined)[];
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function ChangeEmailForm(props: Props) {
	const {
		onSubmitInvalid,
		onSubmitStart,
		onSubmitError,
		onSubmitSuccess,
		renderSubmitButton,
		cssExtra,
		...delegatedProps
	} = props;

	let id = useId();
	if (delegatedProps.id) id = delegatedProps.id;

	const { handleSubmit, control } = useForm<FormValues>({ mode: 'onTouched' });

	const { updateDaresayEmail } = useUser();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const onSubmit: SubmitHandler<FormValues> = (formData, e) => {
		e?.preventDefault();

		onSubmitStart?.(formData, e);

		(async () => {
			if (!executeRecaptcha) throw new SentinelError('Unexpected error', '');
			const recaptchaToken = await executeRecaptcha('UPDATE_EMAIL');

			return updateDaresayEmail({ email: formData.email, recaptchaToken });
		})()
			.then(() => {
				onSubmitSuccess?.(formData, e);
			})
			.catch((error: Error) => {
				onSubmitError?.(error, formData, e);
			});
	};

	const { user } = useUser();

	if (!user) throw new Error('User is not available');

	return (
		<>
			<form
				noValidate
				css={cssExtra}
				{...delegatedProps}
				id={id}
				onSubmit={e => {
					handleSubmit(
						onSubmit,
						onSubmitInvalid,
					)(e).catch(() => {
						/* ignore */
					});
				}}
			>
				<TextEmailInput
					controllerProps={{
						name: 'email',
						control,
						defaultValue: '',
						rules: {
							validate: (value: string) => {
								if (value === user.auth.daresay.email)
									return 'Email should be different from your current email address';

								return undefined;
							},
						},
					}}
					labelProps={{ children: 'New email' }}
				/>
			</form>
			{(() => {
				const button = (
					<StyledButton
						cssExtra={css`
							min-width: 96px;
							margin-top: 0.5em;
						`}
						form={id}
						styleType={Style.RECT}
						type={'submit'}
					>
						Save
					</StyledButton>
				);

				return renderSubmitButton ? renderSubmitButton(button) : button;
			})()}
		</>
	);
}
