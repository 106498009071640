import { css } from '@emotion/react';

import { textButtonStyle } from './Card';
import PolicyDisclaimer from '../PolicyDisclaimer';

export default function CardPolicyDisclaimer() {
	return (
		<PolicyDisclaimer
			css={css`
				color: var(--muted-color-3);

				& a {
					${textButtonStyle};

					font-weight: 400;
				}
			`}
		/>
	);
}
