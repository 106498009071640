import { css } from '@emotion/react';

import StyledNavLink, { Style } from '../buttons/core/StyledNavLink';

import type { SerializedStyles } from '@emotion/react';

interface Props {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export const textLinkStyle = css`
	font-weight: 400;
	transition: color var(--ease-time) var(--ease-fn);

	&:is(:link, :visited, :active) {
		color: var(--muted-color-3);
	}

	&:hover {
		color: var(--accent-color-b-alt-3);
	}
`;

export default function PolicyLinkSet(props: Props) {
	const { cssExtra } = props;

	return (
		<div
			css={css`
				display: flex;
				flex-wrap: wrap;
				row-gap: 0.5em;
				column-gap: 16px;
				justify-content: center;
				font-size: 0.8em;

				${cssExtra};
			`}
		>
			<StyledNavLink cssExtra={textLinkStyle} styleType={Style.TEXT} to={'/privacy'}>
				Privacy Notice
			</StyledNavLink>
			<StyledNavLink cssExtra={textLinkStyle} styleType={Style.TEXT} to={'/terms'}>
				Terms of Use
			</StyledNavLink>
		</div>
	);
}
