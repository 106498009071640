import { createContext, useContext, useEffect, useRef, useState } from 'react';

import structuredClone from '@ungap/structured-clone';
import * as Sentry from '@sentry/react';

import { apiClient } from '../../common/api-client';

import type { ApiClientResponse, MethodGretchOptions } from '@daresay/api-client';
import type { User } from '../../types';

export function useUserDispatch() {
	// eslint-disable-next-line react/hook-use-state
	const [user, _setUser] = useState<User | false | undefined>(undefined);

	const setUser = (data: User | false | undefined) => {
		_setUser(data);

		Sentry.setUser(
			(() => {
				if (!data) return null;

				return { id: data._id, email: data.auth.daresay.email };
			})(),
		);
	};

	const fetchUserPromise = useRef<Promise<ApiClientResponse<User>>>();

	return {
		user,
		fetchUserPromise,
		setUser,
		fetchUser(this: void) {
			if (fetchUserPromise.current) return fetchUserPromise.current;

			const promise = apiClient
				.get<User>('/api/users/_', {
					retry: {
						attempts: 5,
						codes: [408, 413, 429, 500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511],
					},
				})
				.then(res => {
					const { payload } = res;
					setUser(payload);
					return res;
				})
				.catch((error: Error) => {
					setUser(false);
					throw error;
				})
				.finally(() => {
					fetchUserPromise.current = undefined;
				});

			fetchUserPromise.current = promise;

			return promise;
		},
		logout(this: void, opts?: MethodGretchOptions) {
			return apiClient
				.post('/api/auth/logout', undefined, opts)
				.then(res => {
					setUser(false);
					window.dataLayer.push({ event: 'logout' });
					return res;
				})
				.catch((error: Error) => {
					setUser(undefined);
					throw error;
				});
		},
		updateDaresayEmail(this: void, body: { email: string; recaptchaToken: string }, opts?: MethodGretchOptions) {
			return apiClient
				.put<Exclude<User['auth']['daresay']['emailUpdate'], undefined>['email']>(
					'/api/users/_/actions/update-email',
					body,
					opts,
				)
				.then(res => {
					if (!user) throw new Error('User not available');

					const userDraft = structuredClone(user);
					userDraft.auth.daresay.emailUpdate = { ...user.auth.daresay.emailUpdate, email: res.payload };

					setUser(userDraft);
					return res;
				});
		},
		updateDaresayPassword(
			this: void,
			body: { password: string; currentPassword?: string; recaptchaToken: string },
			opts?: MethodGretchOptions,
		) {
			return apiClient
				.put<User['auth']['daresay']['password']>('/api/users/_/actions/update-password', body, opts)
				.then(res => {
					if (!user) throw new Error('User not available');

					const userDraft = structuredClone(user);
					userDraft.auth.daresay.password = res.payload;

					setUser(userDraft);
					return res;
				});
		},
		updateNotificationSettings(
			this: void,
			body: { notifications: User['settings']['notifications']; recaptchaToken: string },
			opts?: MethodGretchOptions,
		) {
			return apiClient
				.put<User['settings']['notifications']>('/api/users/_/settings/notifications', body, opts)
				.then(res => {
					if (!user) throw new Error('User not available');

					const notifications = res.payload;
					setUser({ ...user, settings: { ...user.settings, notifications } });
					return res;
				});
		},
		disconnectAuthAccount(
			this: void,
			strategy: Exclude<keyof User['auth'], 'daresay'>,
			opts?: MethodGretchOptions,
		) {
			return apiClient.delete(`/api/users/_/auth/${strategy}`, undefined, opts).then(res => {
				if (res.status === 204) {
					if (!user) throw new Error('User not available');

					const userDraft = structuredClone(user);
					delete userDraft.auth[strategy];

					setUser(userDraft);
					return res;
				}

				return res;
			});
		},
		redeemCode(this: void, body: { code: string; recaptchaToken: string }, opts?: MethodGretchOptions) {
			return apiClient.put('/api/users/_/actions/redeem-code', body, opts).then(res => {
				// TODO: update user

				return res;
			});
		},
	};
}

export type UserDispatch = ReturnType<typeof useUserDispatch>;

export const UserContext = createContext<UserDispatch>(null as unknown as UserDispatch);

export function useUser() {
	const context = useContext(UserContext);

	return context;
}

export function useUserPromise(timeout: number) {
	const context = useContext(UserContext);

	const [user, setUser] = useState<User | false | undefined>(undefined);
	const [userError, setUserError] = useState<Error | null>(null);

	const { fetchUser, fetchUserPromise } = context;

	useEffect(() => {
		if (!fetchUserPromise.current) {
			fetchUser().catch(() => {
				/* ignore */
			});
			return;
		}

		const promises = [fetchUserPromise.current];

		if (timeout === 0) {
			promises.push(
				new Promise<never>((_resolve, reject) => {
					setTimeout(() => {
						const error = new Error('Timeout exceeded while waiting for the user to be fetched');
						reject(error);
					}, timeout);
				}),
			);
		}

		Promise.race(promises)
			.then(res => {
				setUser(res.payload);
			})
			.catch((error: Error) => {
				setUserError(error);
			});
	}, [fetchUser, fetchUserPromise, timeout]);

	return [user, userError] as [typeof user, typeof userError];
}
