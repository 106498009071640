import { css } from '@emotion/react';

import VerifiedGraphic from '../../../../public/images/ui/check.svg';

interface Props {
	email: string;
	verified?: boolean;
	isCurrent?: boolean;
}

export default function AccountEmailDescription(props: Props) {
	const { email, verified, isCurrent = true } = props;
	return (
		<span
			css={css`
				display: block;
				word-break: break-word;
			`}
		>
			{isCurrent ? null : (
				<span
					css={css`
						margin-right: 0.75em;
						font-weight: 700;
						color: var(--muted-color-0);
					`}
				>
					↳
				</span>
			)}
			<span>{email}</span>
			{(() => {
				if (verified === undefined) return undefined;
				if (!verified) {
					return (
						<span
							css={css`
								margin-left: 0.25em;
								font-weight: 700;
								color: var(--muted-color-0);
								white-space: nowrap;
							`}
						>
							(check email to verify)
						</span>
					);
				}
				return (
					<VerifiedGraphic
						css={css`
							height: 1em;
							margin-left: 0.25em;
							color: var(--accent-color-g);
						`}
					/>
				);
			})()}
		</span>
	);
}
