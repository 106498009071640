import { css } from '@emotion/react';

import SocialLogo, { SocialLogoType } from '../graphics/vectors/social/SocialLogo';

import type { Props as SocialLogoProps } from '../graphics/vectors/social/SocialLogo';

export interface Props extends SocialLogoProps {
	url: string;
	title: string;
	fill?: string;
	invert?: boolean;
}

export default function SocialAnchor(props: Props) {
	const { height, type, url, title, fill, invert, cssExtra } = props;

	const style = (() => {
		const fillValue = fill ?? 'var(--muted-color-1)';
		const selector = invert ? '&:hover' : '&:not(:hover)';

		return css`
			&:is(:link, :visited, :hover, :active) {
				color: var(--primary-color);
			}

			& stop {
				transition: stop-color var(--ease-time) var(--ease-fn);
			}

			& [fill]:not([fill^='url']) {
				transition: fill var(--ease-time) var(--ease-fn);
			}

			${selector} stop {
				stop-color: ${fillValue};
			}

			// noinspection Stylelint
			${selector} [fill]:not([fill^='url']) {
				fill: ${fillValue};
			}

			${cssExtra};
		`;
	})();

	return (
		<a
			aria-label={SocialLogoType[type].toLowerCase()}
			css={style}
			href={url}
			rel={'noopener noreferrer'}
			role={'link'}
			target={'_blank'}
			title={title}
		>
			<SocialLogo height={height} type={type} />
		</a>
	);
}
