import { useId, useState } from 'react';

import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { RenderData, SentinelError } from '@daresay/api-client';

import { textButtonStyle } from '../../layout/card/Card';
import StyledButton, { Style } from '../../buttons/core/StyledButton';
import StyledNavLink from '../../buttons/core/StyledNavLink';
import TextPasswordInput from '../TextPasswordInput';
import RememberMe from '../RememberMe';
import TextEmailInput from '../TextEmailInput';
import { apiClient } from '../../../../common/api-client';
import DancingCirclesSpinner from '../../graphics/animations/DancingCirclesSpinner';
import { useUser } from '../../../hooks/user';

import type { ComponentPropsWithoutRef } from 'react';
import type { Control, SubmitHandler, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import type { SerializedStyles } from '@emotion/react';

interface Props extends Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
	register: UseFormRegister<FormValues>;
	handleSubmit: UseFormHandleSubmit<FormValues>;
	control: Control<FormValues>;
	redirectUri?: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export interface FormValues {
	email: string;
	password: string;
	remember: boolean;
}

export default function LoginForm(props: Props) {
	const { register, handleSubmit, control, redirectUri: _redirectUri = '/', cssExtra, ...delegatedProps } = props;

	const navigate = useNavigate();

	let id = useId();
	if (delegatedProps.id) id = delegatedProps.id;

	const [loading, setLoading] = useState(false);

	const { executeRecaptcha } = useGoogleReCaptcha();

	const { fetchUser } = useUser();

	const redirectUri = (() => {
		const params = new URLSearchParams({ strategy: 'daresay', redirect_uri: _redirectUri });
		return `/login/success?${params.toString()}`;
	})();

	const onSubmit: SubmitHandler<FormValues> = (formData, e) => {
		e?.preventDefault();

		if (loading) return;

		setLoading(true);

		const { email, password, remember } = formData;

		const params = new URLSearchParams({
			mode: 'login',
		});
		if (remember) params.set('remember', '');

		(async () => {
			if (!executeRecaptcha) throw new SentinelError('Unexpected error', '');
			const recaptchaToken = await executeRecaptcha('AUTH_LOGIN');

			return apiClient.post(`/api/auth/daresay?${params.toString()}`, { email, password, recaptchaToken });
		})()
			.then(() => {
				return fetchUser()
					.then(() => {
						navigate(redirectUri);
					})
					.catch(() => {
						// try to fetch the user again on reload
						window.location.href = redirectUri;
					});
			})
			.catch(error => {
				const renderError = SentinelError.isParseable(error)
					? error
					: new SentinelError('Unexpected error', '');
				const renderData = new RenderData(renderError, {});

				const searchParams = new URLSearchParams();

				searchParams.set(...renderData.toParams());

				navigate(`/login/error?${searchParams.toString()}`);
			})

			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<form
				noValidate
				css={css`
					display: flex;
					flex-direction: column;
					row-gap: inherit;
					align-items: stretch;
					justify-content: space-between;

					${cssExtra};
				`}
				{...delegatedProps}
				id={id}
				onSubmit={e => {
					handleSubmit(onSubmit)(e).catch(() => {
						/* ignore */
					});
				}}
			>
				<TextEmailInput controllerProps={{ name: 'email', control, defaultValue: '' }} />
				<TextPasswordInput
					controllerProps={{ name: 'password', control, defaultValue: '' }}
					validateStrength={false}
				/>
			</form>

			<div
				css={css`
					display: flex;
					align-items: center;
					justify-content: space-between;

					@media (max-width: 325px) {
						flex-direction: column-reverse;
						row-gap: 0.5em;
						align-items: center;
						justify-content: center;
						margin-top: 1.25em;
					}
				`}
			>
				<RememberMe
					cssExtra={css`
						font-size: 0.85em;

						& .switchbox {
							font-size: 10px;
							transform: translateY(-0.2em);
						}
					`}
					register={register}
					registerName={'remember'}
				/>
				<StyledNavLink
					cssExtra={css`
						${textButtonStyle};
						font-size: 0.85em;
						font-weight: 400;
					`}
					styleType={Style.TEXT}
					to={'/login/help'}
				>
					Forgot your password?
				</StyledNavLink>
			</div>
			<StyledButton
				cssExtra={css`
					align-self: center;
					min-width: 128px;
					padding: 0.5em 2.25em 0.45em;
					margin-top: 10px;
					border-radius: 99rem;
				`}
				form={id}
				styleType={Style.RECT}
				type={'submit'}
			>
				Log in
			</StyledButton>
			<CSSTransition
				unmountOnExit
				addEndListener={(node, done) => {
					node.addEventListener('transitionend', done, false);
				}}
				classNames={'fade'}
				in={loading}
			>
				<DancingCirclesSpinner
					cssExtra={css`
						align-self: center;
						font-size: 0.6em;
					`}
				/>
			</CSSTransition>
		</>
	);
}
