import { useId } from 'react';

import type { ComponentPropsWithoutRef } from 'react';

export default function InstagramLogo(props: ComponentPropsWithoutRef<'svg'>) {
	const defsId = useId();

	return (
		<svg viewBox={'0 0 160 160'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
			<defs>
				<linearGradient gradientUnits={'userSpaceOnUse'} id={defsId} x2={'100%'} y1={'70%'}>
					<stop offset={'0'} stopColor={'#ffd522'} />
					<stop offset={'48%'} stopColor={'#f1000b'} />
					<stop offset={'106%'} stopColor={'#b900b3'} />
				</linearGradient>
			</defs>
			<g fill={`url(#${defsId})`}>
				<path
					d={
						'M80,14.4144c21.361,0,23.8911.0815,32.3268.4664,7.8.3559,12.0359,1.659,14.8549,2.7546A24.7861,24.7861,0,0,1,136.38,23.62a24.7851,24.7851,0,0,1,5.9844,9.1986c1.0955,2.819,2.3987,7.055,2.7546,14.8548.3849,8.4358.4664,10.966.4664,32.3269s-.0815,23.8911-.4664,32.3268c-.3559,7.8-1.6591,12.0359-2.7546,14.8549a26.493,26.493,0,0,1-15.1829,15.1829c-2.819,1.0955-7.055,2.3987-14.8549,2.7546-8.4345.3849-10.9644.4664-32.3268.4664s-23.8923-.0815-32.3268-.4664c-7.8-.3559-12.0359-1.6591-14.8549-2.7546A24.7849,24.7849,0,0,1,23.62,136.38a24.7863,24.7863,0,0,1-5.9844-9.1986c-1.0956-2.819-2.3987-7.055-2.7546-14.8548-.3849-8.4358-.4664-10.9659-.4664-32.3269s.0815-23.8911.4664-32.3268c.3559-7.8,1.659-12.0359,2.7546-14.8549A24.7861,24.7861,0,0,1,23.62,23.62a24.7863,24.7863,0,0,1,9.1986-5.9844c2.819-1.0956,7.055-2.3987,14.8548-2.7546,8.4358-.3849,10.966-.4664,32.3269-.4664M80,0C58.2733,0,55.5489.092,47.0161.4813,38.5009.87,32.6857,2.2222,27.5969,4.2a39.2134,39.2134,0,0,0-14.17,9.2273A39.2134,39.2134,0,0,0,4.2,27.5969C2.2222,32.6857.87,38.5009.4813,47.0161.092,55.5489,0,58.2732,0,80s.092,24.451.4813,32.9839c.3886,8.5152,1.7409,14.33,3.7186,19.4192a39.2142,39.2142,0,0,0,9.2273,14.17,39.2134,39.2134,0,0,0,14.17,9.2273c5.0888,1.9777,10.904,3.33,19.4192,3.7186C55.5489,159.908,58.2733,160,80,160s24.451-.092,32.9839-.4813c8.5152-.3886,14.33-1.7409,19.4192-3.7186a40.9069,40.9069,0,0,0,23.397-23.397c1.9777-5.0888,3.33-10.904,3.7186-19.4192C159.908,104.451,160,101.7267,160,80s-.092-24.4511-.4813-32.9839c-.3886-8.5152-1.7409-14.33-3.7186-19.4192a39.2134,39.2134,0,0,0-9.2273-14.17A39.2134,39.2134,0,0,0,132.4031,4.2C127.3143,2.2222,121.4991.87,112.9839.4813,104.451.092,101.7267,0,80,0Z'
					}
				/>
				<path
					d={
						'M80,38.9189A41.0811,41.0811,0,1,0,121.0811,80,41.0811,41.0811,0,0,0,80,38.9189Zm0,67.7477A26.6667,26.6667,0,1,1,106.6667,80,26.6666,26.6666,0,0,1,80,106.6666Z'
					}
				/>
				<circle cx={'122.7042'} cy={'37.2958'} r={'9.6'} />
			</g>
		</svg>
	);
}
