import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'span'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
	children?: undefined;
}

export default function Dot(props: Props) {
	const { cssExtra, ...delegatedAttributes } = props;
	return (
		<span
			css={css`
				font-size: 4px;
				color: var(--accent-color-b-alt-1);
				user-select: none;

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			⬤
		</span>
	);
}
