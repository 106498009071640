import { css } from '@emotion/react';

interface Props {
	strength: PasswordStrength;
}

export enum PasswordStrength {
	NONE,
	WEAK,
	GOOD,
	GREAT,
	STRONG,
}

const getPasswordStrengthClass = (strength: PasswordStrength) => `strength-${strength}`;

function PasswordStrengthUnit(props: { active: boolean }) {
	const { active } = props;

	return (
		<div
			className={active ? 'active' : undefined}
			css={css`
				background-color: var(--muted-color-4);
				border-radius: 99rem;
				transition: background-color var(--ease-time) var(--ease-fn);
			`}
		/>
	);
}

export default function PasswordStrengthIndicator(props: Props) {
	const { strength } = props;

	return (
		<div
			className={getPasswordStrengthClass(strength)}
			css={css`
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-column-gap: 0.25em;
				width: 100%;
				height: 3px;
				padding: 0 var(--border-radius-1);
				margin: 1em 0 0.5em;

				&.${getPasswordStrengthClass(PasswordStrength.WEAK)} > div.active {
					background-color: var(--accent-color-r);
				}

				&.${getPasswordStrengthClass(PasswordStrength.GOOD)} > div.active {
					background-color: var(--accent-color-y);
				}

				&.${getPasswordStrengthClass(PasswordStrength.GREAT)} > div.active {
					background-color: var(--accent-color-g);
				}

				&.${getPasswordStrengthClass(PasswordStrength.STRONG)} > div.active {
					background-color: var(--accent-color-b);
				}
			`}
		>
			<PasswordStrengthUnit active={strength >= PasswordStrength.WEAK} />
			<PasswordStrengthUnit active={strength >= PasswordStrength.GOOD} />
			<PasswordStrengthUnit active={strength >= PasswordStrength.GREAT} />
			<PasswordStrengthUnit active={strength >= PasswordStrength.STRONG} />
		</div>
	);
}
