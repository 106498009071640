import { useState } from 'react';

import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { SentinelError } from '@daresay/api-client';

import Card, { rectButtonStyle } from '../../../components/layout/card/Card';
import CardDaresayLogo from '../../../components/layout/card/CardDaresayLogo';
import ResetPasswordForm from '../../../components/input/forms/ResetPasswordForm';
import PolicyLinkSet from '../../../components/layout/PolicyLinkSet';
import BoxNotification from '../../../components/layout/notification/BoxNotification';
import { NotificationContext, useNotificationDispatch } from '../../../hooks/notification';
import LockGraphic from '../../../../../public/images/ui/lock.svg';
import CardHeading from '../../../components/layout/card/CardHeading';
import CardDancingCirclesSpinner from '../../../components/layout/card/CardDancingCirclesSpinner';
import CardFooter from '../../../components/layout/card/CardFooter';
import CardFooterNavLink from '../../../components/layout/card/CardFooterNavLink';
import CardPanel from '../../../components/layout/card/CardPanel';
import CardMain from '../../../components/layout/card/CardMain';
import CardMainSection from '../../../components/layout/card/CardMainSection';

const parseResetPasswordError = (error: Error) => {
	if (!(error instanceof SentinelError)) {
		Sentry.captureException(error);
		return undefined;
	}

	switch (error.name) {
		case 'UserError.InvalidToken':
			return 'This link is invalid or has expired';
		default:
			Sentry.captureException(error);
			return undefined;
	}
};

export default function ResetPasswordCard() {
	const navigate = useNavigate();

	const notificationDispatch = useNotificationDispatch();
	const { setNotification, removeNotification } = notificationDispatch;

	const [inProgress, setInProgress] = useState(false);

	const token = (() => {
		const params = new URLSearchParams(window.location.search);
		return params.get('token');
	})();

	if (!token) {
		navigate('/', { replace: true });
		return null;
	}

	return (
		<NotificationContext.Provider value={notificationDispatch}>
			<Card
				cssExtra={css`
					display: flex;
					flex-grow: 0.15;
				`}
			>
				<CardPanel>
					<CardDaresayLogo />

					<BoxNotification
						cssExtra={css`
							--close-size: 7px;

							margin-top: -6px;
							margin-bottom: -10px;

							&.empty {
								margin-top: -12px;
								margin-bottom: -20px;
							}
						`}
						substitute={
							<LockGraphic
								css={css`
									width: 1.65em;
									height: 1.65em;
									margin: auto;
									color: var(--accent-color-v);
								`}
							/>
						}
					/>

					<CardMain>
						<CardHeading>Reset your password</CardHeading>

						<CardMainSection description={'Enter a new password for your account.'}>
							<ResetPasswordForm
								cssExtra={css`
									& + button[type='submit'] {
										${rectButtonStyle};
									}
								`}
								token={token}
								onSubmitError={error => {
									setInProgress(false);
									setNotification({
										message: parseResetPasswordError(error) ?? 'An unexpected error has occurred',
										type: 'error',
									});
								}}
								onSubmitStart={() => {
									setInProgress(true);
									removeNotification();
								}}
								onSubmitSuccess={() => {
									setInProgress(false);
									setNotification({
										type: 'success',
										message: 'Your password has been updated',
									});
								}}
							/>
							<CardDancingCirclesSpinner show={inProgress} />
						</CardMainSection>
					</CardMain>
					<CardFooter>
						<CardFooterNavLink emphasis={'Return to login'} to={'/login'}>
							Figure it out?
						</CardFooterNavLink>
						<PolicyLinkSet />
					</CardFooter>
				</CardPanel>
			</Card>
		</NotificationContext.Provider>
	);
}
