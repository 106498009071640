import { useEffect, useRef, useState } from 'react';

import { css } from '@emotion/react';

import PlatformIconSet from '../PlatformIconSet';
import { boxShadow } from '../../styles';
import StyledAnchor, { Style } from '../../buttons/core/StyledAnchor';

import type { ReactNode } from 'react';
import type { Platform } from '../../graphics/vectors/PlatformIcon';

interface Props {
	title: string;
	href: string;
	backgroundColor: string;
	platforms: Platform[];
	logo: ReactNode;
	children: ReactNode;
}

const MIN_WIDTH_1 = 1000;
const MIN_WIDTH_2 = 600;

const cardStyle = css`
	display: flex;
	overflow: hidden;
	border-top: 1px solid var(--primary-color);
	border-bottom: 1px solid var(--primary-color);

	${boxShadow(2)};
`;

export default function GameCard(props: Props) {
	const { href, title, backgroundColor, platforms, logo, children } = props;

	const cardStyleWithBackground = [
		cardStyle,
		css`
			background-color: ${backgroundColor};
		`,
	];

	const resizeObserver = useRef<ResizeObserver>();
	const ref = useRef<HTMLDivElement>(null);

	const [compactMode, setCompactMode] = useState(window.innerWidth < MIN_WIDTH_1);
	const [tinyMode, setTinyMode] = useState(window.innerWidth < MIN_WIDTH_2);

	useEffect(() => {
		if (!ref.current) return undefined;

		resizeObserver.current = new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
			setCompactMode(entry.contentRect.width < MIN_WIDTH_1);
			setTinyMode(entry.contentRect.width < MIN_WIDTH_2);
		});
		resizeObserver.current.observe(ref.current);

		return () => {
			resizeObserver.current?.disconnect();
		};
	}, []);

	return (
		<div ref={ref} css={cardStyleWithBackground}>
			<div
				className={compactMode ? 'compact' : undefined}
				css={css`
					display: flex;
					flex-direction: column;
					align-items: stretch;
					justify-content: center;
					width: calc(100% * (13 / 45));
					padding: 8% min(6%, 4vw);

					&.compact {
						row-gap: 24%;
						justify-content: center;
					}
				`}
			>
				{logo}
				{compactMode ? undefined : (
					<PlatformIconSet
						cssExtra={css`
							margin: 1.15em auto auto;
							font-size: 2vw;

							&:last-child {
								margin-top: 1.5em;
								margin-bottom: 0;
							}
						`}
						platforms={platforms}
					/>
				)}
				{tinyMode || !href ? undefined : (
					<div
						css={css`
							display: flex;
							justify-content: center;
						`}
					>
						<StyledAnchor
							cssExtra={css`
								min-width: 96px;
								margin: auto;
							`}
							href={href}
							styleType={Style.RECT}
							title={`Visit ${title}`}
						>
							Visit
						</StyledAnchor>
					</div>
				)}
			</div>

			<div
				css={css`
					position: relative;
					display: flex;
					width: calc(100% * (32 / 45));
					height: 100%;
				`}
			>
				<div
					css={css`
						flex-grow: 1;
					`}
				>
					{children}
				</div>
				{compactMode ? (
					<div
						css={css`
							position: absolute;
							top: 1.15em;
							display: flex;
							justify-content: flex-end;
							width: 100%;
							padding-right: 1.25em;
						`}
					>
						<PlatformIconSet
							cssExtra={css`
								font-size: clamp(1em, 3vw, 1.5em);
							`}
							platforms={platforms}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
}
