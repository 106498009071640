import DesktopMacOSGraphic from '../../../../../public/images/social/apple.svg';
import DesktopWindowsGraphic from '../../../../../public/images/social/windows.svg';
import MobileGraphic from '../../../../../public/images/ui/mobile.svg';
import SteamGraphic from '../../../../../public/images/social/steam.svg';

import type { SVGProps, FunctionComponent } from 'react';
import type { SerializedStyles } from '@emotion/react';

export enum Platform {
	DESKTOP_WINDOWS,
	DESKTOP_MACOS,
	STEAM,
	MOBILE_ANDROID,
	MOBILE_IOS,
}

interface Props {
	platform: Platform;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

interface PlatformData {
	text: string;
	component: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const platformData: { [key in Platform]: PlatformData } = {
	[Platform.DESKTOP_WINDOWS]: { text: 'Windows', component: DesktopWindowsGraphic },
	[Platform.DESKTOP_MACOS]: { text: 'MacOS', component: DesktopMacOSGraphic },
	[Platform.STEAM]: { text: 'Steam', component: SteamGraphic },
	[Platform.MOBILE_ANDROID]: { text: 'Android', component: MobileGraphic },
	[Platform.MOBILE_IOS]: { text: 'iOS', component: MobileGraphic },
};

export default function PlatformIcon(props: Props) {
	const { platform, cssExtra } = props;

	const data = platformData[platform];
	const Graphic = data.component;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return <Graphic css={cssExtra} />;
}
