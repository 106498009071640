import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function CardMain(props: Props) {
	const { cssExtra, ...delegatedProps } = props;
	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				row-gap: inherit;
				align-items: center;
				align-self: stretch;
				margin: 20px 0;

				${cssExtra}
			`}
			{...delegatedProps}
		/>
	);
}
