import { css } from '@emotion/react';

import { useNotification } from '../../../hooks/notification';

import type { SerializedStyles } from '@emotion/react';

interface Props extends React.ComponentPropsWithoutRef<'span'> {
	invert?: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function InlineNotification(props: Props) {
	const { invert, cssExtra, ...delegatedProps } = props;

	const { notification } = useNotification();

	if (!notification) return null;

	return (
		<span
			className={(() => {
				const classes = [`notification--${notification.type}`];
				if (invert) classes.push('invert');

				return classes.join(' ');
			})()}
			css={css`
				--notification-color: var(--accent-color-b-alt-3);

				padding-top: 0.15em;
				padding-left: 1em;
				border-left: 3px solid var(--notification-color);

				&.invert {
					padding-right: 1em;
					padding-left: 0;
					text-align: right;
					border-right: 3px solid var(--notification-color);
					border-left: none;
				}

				&.notification--success {
					--notification-color: var(--accent-color-g);
				}

				&.notification--warn {
					--notification-color: var(--accent-color-y);
				}

				&.notification--error {
					--notification-color: var(--accent-color-r);
				}

				${cssExtra}
			`}
			{...delegatedProps}
		>
			{notification.message}
		</span>
	);
}
