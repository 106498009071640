import { css } from '@emotion/react';

import { useMeta } from '../../../hooks/dom';

export interface Props {
	errorStatus?: string;
	errorMessage?: string;
}

export default function ErrorView(props: Props) {
	const { errorStatus, errorMessage } = props;

	const meta = useMeta();

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				align-items: center;
				font-weight: 700;
				text-transform: uppercase;
				user-select: none;
			`}
		>
			<span
				css={css`
					font-family: Synthemesc;
					font-size: min(20vw, 20vh);
					line-height: 1;
					color: var(--accent-color-y);
					letter-spacing: 0.08em;
				`}
			>
				{meta.current['daresay:template:error:status'] ?? errorStatus ?? 'Oof'}
			</span>
			<span
				css={css`
					margin-top: 1em;
					font-size: clamp(10px, 1.8vw, 1.8vh);
					letter-spacing: 0.25em;
				`}
			>
				{meta.current['daresay:template:error:message'] ?? errorMessage ?? 'Something went wrong'}
			</span>
		</div>
	);
}
