import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'nav'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function HeaderNav(props: Props) {
	const { children, cssExtra, ...delegatedAttributes } = props;
	return (
		<nav
			css={css`
				display: flex;
				column-gap: 28px;
				align-items: center;

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			{children}
		</nav>
	);
}
