import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

import Main from '../../components/layout/view/Main';
import CardContainer from '../../components/layout/card/CardContainer';
import SignupCard from './SignupCard';
import SetMeta from '../../components/utils/SetMeta';

export default function SignupPage() {
	return (
		<>
			<SetMeta description={'Sign up for a Daresay Games account.'} title={'Sign up'} />
			<Main
				cssExtra={css`
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				`}
			>
				<CardContainer
					cssExtra={css`
						flex-grow: 1;
					`}
				>
					<CSSTransition
						appear
						in
						unmountOnExit
						addEndListener={(node, done) => {
							node.addEventListener('transitionend', done, false);
						}}
						classNames={'modal-card'}
					>
						<SignupCard />
					</CSSTransition>
				</CardContainer>
			</Main>
		</>
	);
}
