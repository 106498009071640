import { useEffect, useState } from 'react';

import ArticleTableOfContents, { parseSections } from './ArticleTableOfContents';

import type { ReactElement } from 'react';
import type ArticleSection from './ArticleSection';
import type { TableOfContentsSection } from './ArticleTableOfContents';

interface Props {
	children: ReactElement<typeof ArticleSection>[];
}

export default function ArticleContent(props: Props) {
	const { children } = props;

	const [sections, setSections] = useState<TableOfContentsSection[]>([]);

	useEffect(() => {
		setSections(parseSections());
	}, []);

	return (
		<>
			<ArticleTableOfContents sections={sections} />
			{children}
		</>
	);
}
