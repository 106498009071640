import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'p'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function ArticleParagraph(props: Props) {
	const { cssExtra, children, ...delegatedAttributes } = props;
	return (
		<p
			css={[
				css`
					&:not(:first-child) {
						margin-top: 1.4em;
					}

					&:not(:last-child) {
						margin-bottom: 1.4em;
					}
				`,
				cssExtra,
			]}
			{...delegatedAttributes}
		>
			{children}
		</p>
	);
}
