import { forwardRef } from 'react';

import { css } from '@emotion/react';

import HamburgerGraphic from '../../../../public/images/ui/menu-burger.svg';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'button'> {
	checked: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
	children?: undefined;
	height?: string;
	width?: string;
}

const standardStyle = css`
	& > path {
		--length: 24;
		--offset: -38;

		fill: none;
		stroke-dasharray: var(--length) var(--total-length);
		stroke-dashoffset: var(--offset);
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-width: 3;
		transition: all calc(var(--ease-time) * 4) var(--ease-fn-slide), color var(--ease-time) var(--ease-fn),
			stroke var(--ease-time) var(--ease-fn);
	}

	& > :is(.line--1, .line--3) {
		--total-length: 126.64183044433594;
	}

	& > .line--2 {
		--total-length: 70;
	}
`;

const checkedStyle = css`
	& > :is(.line--1, .line--3) {
		--length: 22.627416998;
		--offset: -94.1149185097;
	}

	& > .line--2 {
		--length: 0;
		--offset: -50;
	}
`;

const HamburgerButton = forwardRef<HTMLButtonElement, Props>(function HamburgerButton(props, ref) {
	const { checked, height, width, cssExtra, ...delegatedAttributes } = props;

	return (
		<button ref={ref} css={cssExtra} type={'button'} {...delegatedAttributes}>
			<HamburgerGraphic
				css={css`
					${standardStyle};

					${checked && checkedStyle};
				`}
				height={height}
				width={width}
			/>
		</button>
	);
});

export default HamburgerButton;
