import { css } from '@emotion/react';

import InlineNotification from '../../components/layout/notification/InlineNotification';
import DancingCirclesSpinner from '../../components/graphics/animations/DancingCirclesSpinner';

import type { ComponentPropsWithoutRef, ReactElement } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	children: ReactElement<'button'>;
	inProgress: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function AccountActionContainerSubmit(props: Props) {
	const { children, inProgress, cssExtra, ...delegatedProps } = props;

	return (
		<div
			css={css`
				display: flex;
				column-gap: 2em;
				align-items: center;
				margin-top: 0.5em;

				& > button {
					margin-top: 0;
				}

				${cssExtra};
			`}
			{...delegatedProps}
		>
			{children}
			{inProgress ? (
				<DancingCirclesSpinner
					cssExtra={css`
						font-size: 0.5em;
					`}
				/>
			) : undefined}
			<InlineNotification
				cssExtra={css`
					font-size: 0.9em;
				`}
			/>
		</div>
	);
}
