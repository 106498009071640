import { useEffect, useRef } from 'react';

export const usePrevious = <T>(value: T) => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export const useUpdate = (effect: React.EffectCallback, deps?: React.DependencyList | undefined) => {
	const ref = useRef(true);

	useEffect(() => {
		if (ref.current) {
			ref.current = false;
			return undefined;
		}

		return effect();
	}, deps);
};
