import { css, useTheme } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'p'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
	children?: string;
}

export default function ValidationMessageWrapper(props: Props) {
	const { cssExtra, children, ...delegatedProps } = props;

	const theme = useTheme();

	return (
		<p
			css={css`
				margin: 0.5em 0.85em 0;
				font-size: 0.85em;
				line-height: 1.1;
				color: ${theme.inputs.colors.error};

				${cssExtra};
			`}
			{...delegatedProps}
		>
			{children}
		</p>
	);
}
