import { css } from '@emotion/react';

import SocialLogo from '../graphics/vectors/social/SocialLogo';
import { boxShadow } from '../styles';
import { AuthProvider, getProviderString, getProviderStyle } from './AuthButton';

import type { ComponentPropsWithoutRef } from 'react';
import type { SocialLogoType } from '../graphics/vectors/social/SocialLogo';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'a'> {
	provider: AuthProvider;
	prefix: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export const getProviderOverrideStyle = (provider: AuthProvider) => {
	switch (provider) {
		case AuthProvider.APPLE:
			return css`
				background: #ffffff;

				& svg {
					& [fill]:not([fill^='url']) {
						fill: #000000;
					}
				}
			`;
		case AuthProvider.STEAM:
			return css`
				background: linear-gradient(45deg, #1384b6, #101c2f);

				& svg {
					& [fill^='url'] {
						fill: #ffffff;
					}

					& [opacity='0'] {
						opacity: 0;
					}
				}
			`;
		case AuthProvider.FACEBOOK:
			return css`
				background: #1877f2;

				& svg {
					& [fill]:not([fill^='url']) {
						fill: #ffffff;
					}

					& [opacity='0'] {
						opacity: 0;
					}
				}
			`;
		case AuthProvider.TWITCH:
			return css`
				background: #9146ff;

				& svg {
					& [fill]:not([fill^='url']) {
						fill: #ffffff;
					}
				}
			`;
		default:
			return null;
	}
};

export const getProviderSpanStyle = (provider: AuthProvider) => {
	switch (provider) {
		case AuthProvider.GOOGLE:
			return css`
				color: #000000;
			`;
		case AuthProvider.APPLE:
			return css`
				color: #000000;
			`;
		default: {
			return css`
				color: #ffffff;
			`;
		}
	}
};

export default function AuthButtonAlt(props: Props) {
	const { prefix, provider, cssExtra, ...delegatedAttributes } = props;

	const providerStr = getProviderString(provider);
	const title = `${prefix} ${providerStr}`;

	return (
		<a
			css={css`
				display: flex;
				column-gap: 1em;
				align-items: center;
				justify-content: center;
				padding: 0.5em 1em;
				border-radius: var(--border-radius-1);

				&:active {
					transform: scale(0.95);
				}

				${boxShadow(2)}
				${getProviderOverrideStyle(provider) ?? getProviderStyle(provider)}
				${cssExtra}
			`}
			title={title}
			{...delegatedAttributes}
		>
			<SocialLogo
				height={'1.5em !important'}
				type={provider as unknown as typeof SocialLogoType[keyof typeof SocialLogoType]}
			/>
			<span
				css={css`
					font-weight: 700;
					${getProviderSpanStyle(provider)}
				`}
			>
				{title}
			</span>
		</a>
	);
}
