import { css } from '@emotion/react';

import GameCard from '../components/layout/card/GameCard';
import DriftArenaLogo from '../../../public/images/games/drift-arena.svg';
import { svgShadow } from '../components/styles';
import { Platform } from '../components/graphics/vectors/PlatformIcon';
import DotPattern1Graphic from '../../../public/images/ui/dot-pattern-1.svg';
import GameCardsSet from '../components/layout/card/GameCardsSet';

export default function GamesBanner() {
	return (
		<div
			css={css`
				padding: clamp(160px, 10vw, 240px) 0;
				background: linear-gradient(135deg, var(--accent-color-b-alt-5) -40%, var(--muted-color-6));
			`}
		>
			<h1
				css={css`
					position: relative;
					top: 0.1em;
					font-size: 3em;
					font-weight: 600;
					text-align: center;
					text-transform: uppercase;
				`}
			>
				Our Games
			</h1>
			<GameCardsSet
				cssExtra={css`
					margin: 120px 0;
				`}
			>
				<GameCard
					backgroundColor={'#11161d'}
					href={''}
					logo={<DriftArenaLogo css={svgShadow(2)} />}
					platforms={[Platform.DESKTOP_WINDOWS, Platform.DESKTOP_MACOS, Platform.STEAM]}
					title={'Drift Arena'}
				>
					<div
						css={css`
							display: flex;
							align-items: center;
							justify-content: center;
							height: 100%;
							color: var(--muted-color-0);
							background-color: #3b404d;
						`}
					>
						<span
							css={css`
								font-family: 'Brandon Grotesque';
								font-size: 1.1em;
								font-style: italic;
								font-weight: 600;
								text-transform: uppercase;
							`}
						>
							Coming soon
						</span>
					</div>
				</GameCard>
			</GameCardsSet>
			<DotPattern1Graphic
				css={css`
					height: 16px;
					padding: 0 var(--padding-h-main);
					margin-left: auto;
					fill: var(--muted-color-5);
				`}
			/>
		</div>
	);
}
