import { useEffect, useState } from 'react';

import { Navigate, Outlet } from 'react-router-dom';
import { css } from '@emotion/react';

import { useScrollOnMount } from '@daresay/react-hooks';

import SetMeta from '../../components/utils/SetMeta';
import Main from '../../components/layout/view/Main';
import AccountMenu from './AccountMenu';
import Fallback from '../../components/layout/Fallback';
import { useUser } from '../../hooks/user';

import type { MenuItem } from './AccountMenu';

interface Props {
	menuItems: MenuItem[];
}

const COMPACT_WIDTH = 900;

const isCompact = () => window.innerWidth < COMPACT_WIDTH;

export default function AccountPage(props: Props) {
	const { menuItems } = props;

	const [compact, setCompact] = useState(isCompact());

	const onResize = () => {
		setCompact(isCompact());
	};

	useEffect(() => {
		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const { user } = useUser();

	useScrollOnMount();

	return (
		<>
			<SetMeta description={'Manage your Daresay account settings'} title={'Account'} />
			{(() => {
				if (user) {
					return (
						<Main
							cssExtra={css`
								position: relative;
							`}
						>
							<div
								className={compact ? 'compact' : undefined}
								css={css`
									display: flex;
									column-gap: 40px;
									padding: 80px var(--padding-h-alt);

									&.compact {
										flex-direction: column;
										padding: initial;
									}
								`}
							>
								<div
									className={compact ? 'compact' : undefined}
									css={css`
										&.compact {
											overflow-x: auto;

											&::-webkit-scrollbar {
												display: none;
											}
										}
									`}
								>
									<AccountMenu
										className={compact ? 'compact' : undefined}
										cssExtra={css`
											&.compact {
												flex-direction: row;
												flex-wrap: nowrap;
												column-gap: 0.5em;
												align-items: center;
												width: fit-content;
												padding-bottom: 0.5em;
												margin: 80px calc(var(--padding-h-alt) - 0.5em) 6em;
												border-bottom: 1px solid var(--primary-color);

												& > a:is(:link, :visited, :hover, :active).active {
													background-color: rgba(255, 255, 255, 0.05);
												}

												& > * {
													flex: 0 0 auto;
													white-space: nowrap;
												}
											}
										`}
										menuItems={menuItems}
									/>
								</div>
								<div
									className={compact ? 'compact' : undefined}
									css={css`
										flex-grow: 999;
										max-width: ${COMPACT_WIDTH}px;

										&.compact {
											padding: 0 var(--padding-h-alt) 80px;
										}
									`}
								>
									<Outlet />
								</div>
							</div>
						</Main>
					);
				}

				if (user === false) {
					const params = new URLSearchParams({ redirect_uri: window.location.pathname });
					return (
						<Main>
							<Navigate to={`/login?${params.toString()}`} />
						</Main>
					);
				}

				return <Fallback />;
			})()}
		</>
	);
}
