import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const cardPanelStyle = css`
	display: flex;
	flex-direction: column;
	row-gap: 48px;
	align-items: center;
	justify-content: space-evenly;
	width: min(400px, 90vw);
	padding: 80px min(40px, 8vw);
`;

export default function CardPanel(props: Props) {
	const { cssExtra, ...delegatedProps } = props;

	return <div css={[cardPanelStyle, cssExtra]} {...delegatedProps} />;
}
