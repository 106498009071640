import { css } from '@emotion/react';

import StyledButton, { Style } from './core/StyledButton';

import type { ReactNode, ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'button'> {
	children: ReactNode;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function ScrollToTop(props: Props) {
	const { children, cssExtra, ...delegatedAttributes } = props;
	return (
		<div
			css={css`
				position: sticky;
				bottom: 0;
				display: flex;
				justify-content: flex-end;
				width: 100%;

				${cssExtra};
			`}
		>
			<StyledButton
				cssExtra={css`
					margin-left: auto;
				`}
				styleType={Style.TEXT}
				{...delegatedAttributes}
				onClick={() => {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}}
			>
				{children}
			</StyledButton>
		</div>
	);
}
