import { css } from '@emotion/react';

import PlatformIcon, { Platform, platformData } from '../graphics/vectors/PlatformIcon';
import { boxShadow } from '../styles';

import type { SerializedStyles } from '@emotion/react';

interface Props {
	platforms: Platform[];
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const getComponentStyle = (platform: Platform) => {
	switch (platform) {
		case Platform.DESKTOP_MACOS:
			return css`
				position: relative;
				bottom: 0.03em;
			`;
		case Platform.DESKTOP_WINDOWS:
			return css`
				height: 90%;
			`;
		case Platform.MOBILE_IOS:
		case Platform.MOBILE_ANDROID:
			return css`
				height: 95%;
			`;
		case Platform.STEAM:
			return css`
				height: 95%;
			`;
		default: {
			const exhaustiveCheck: never = platform;
			throw new Error(exhaustiveCheck);
		}
	}
};

export default function PlatformIconSet(props: Props) {
	const { platforms, cssExtra } = props;

	const title = (() => {
		const _platforms = platforms.map(platform => platformData[platform].text);
		return `Available on ${_platforms.slice(0, -1).join(', ')}, and ${_platforms[platforms.length - 1]}`;
	})();

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-around;
				width: fit-content;
				height: 1em;
				padding: 0.2em 0.4em;
				background-color: rgb(255 255 255 / 25%);
				border-radius: 99rem;
				writing-mode: vertical-lr;

				${boxShadow(1)};

				${cssExtra};
			`}
			title={title}
		>
			{platforms.map(platform => {
				return (
					<PlatformIcon
						key={platform}
						cssExtra={[
							getComponentStyle(platform),
							css`
								margin-right: 0.2em;
								margin-left: 0.2em;

								& * {
									fill: var(--muted-color-0);
								}
							`,
						]}
						platform={platform}
					/>
				);
			})}
		</div>
	);
}
