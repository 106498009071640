import { forwardRef, useId } from 'react';

import { css, useTheme } from '@emotion/react';

import EyeIcon from '../../../../public/images/ui/eye.svg';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props {
	inputProps?: ComponentPropsWithoutRef<'input'>;
	labelProps?: ComponentPropsWithoutRef<'label'>;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const PasswordHider = forwardRef<HTMLInputElement, Props>(function PasswordHider(props, ref) {
	const { inputProps, labelProps, cssExtra } = props;

	let inputId = useId();
	if (inputProps?.id) inputId = inputProps.id;

	const theme = useTheme();

	return (
		<div
			css={css`
				--element-size: 16px;

				position: absolute;
				top: 0;
				right: 10px;
				bottom: 0;
				width: var(--element-size);
				height: var(--element-size);
				margin: auto;

				${cssExtra};
			`}
		>
			<input
				ref={ref}
				id={inputId}
				type={'checkbox'}
				{...inputProps}
				css={css`
					position: absolute;
					width: 100%;
					height: 100%;
					appearance: none;
					cursor: pointer;

					& + label {
						fill: ${theme.inputs.colors.inactive};
					}

					&:checked + label {
						fill: ${theme.inputs.colors.activeStronger};
					}

					&:not(:checked) + label:hover {
						fill: ${theme.inputs.colors.active};
					}
				`}
			/>
			<label
				htmlFor={inputId}
				{...labelProps}
				css={css`
					width: 100%;
					height: 100%;
					cursor: pointer;
					transition: fill var(--ease-time) var(--ease-fn);
				`}
			>
				<EyeIcon
					css={css`
						height: 100%;
					`}
				/>
			</label>
		</div>
	);
});

export default PasswordHider;
