import { useId } from 'react';

import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { SentinelError } from '@daresay/api-client';

import StyledButton, { Style } from '../../buttons/core/StyledButton';
import TextPasswordInput from '../TextPasswordInput';
import { useUser } from '../../../hooks/user';

import type { ComponentPropsWithoutRef, BaseSyntheticEvent, ReactElement } from 'react';
import type { SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import type { SerializedStyles } from '@emotion/react';

interface FormValues {
	password: string;
}

interface Props extends Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
	onSubmitInvalid?: SubmitErrorHandler<FormValues>;
	onSubmitStart?: SubmitHandler<FormValues>;
	onSubmitError?: (error: Error, formData: FormValues, event: BaseSyntheticEvent | undefined) => void;
	onSubmitSuccess?: SubmitHandler<FormValues>;
	renderSubmitButton?: (submitButton: ReactElement) => ReactElement | (ReactElement | undefined)[];
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function CreatePasswordForm(props: Props) {
	const {
		onSubmitInvalid,
		onSubmitStart,
		onSubmitError,
		onSubmitSuccess,
		renderSubmitButton,
		cssExtra,
		...delegatedProps
	} = props;

	let id = useId();
	if (delegatedProps.id) id = delegatedProps.id;

	const { handleSubmit, control } = useForm<FormValues>({ mode: 'onTouched' });

	const { updateDaresayPassword } = useUser();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const onSubmit: SubmitHandler<FormValues> = (formData, e) => {
		e?.preventDefault();

		onSubmitStart?.(formData, e);

		(async () => {
			if (!executeRecaptcha) throw new SentinelError('Unexpected error', '');
			const recaptchaToken = await executeRecaptcha('CREATE_PASSWORD');

			return updateDaresayPassword({ password: formData.password, recaptchaToken });
		})()
			.then(() => {
				onSubmitSuccess?.(formData, e);
			})
			.catch((error: Error) => {
				onSubmitError?.(error, formData, e);
			});
	};

	return (
		<>
			<form
				noValidate
				css={cssExtra}
				{...delegatedProps}
				id={id}
				onSubmit={e => {
					handleSubmit(
						onSubmit,
						onSubmitInvalid,
					)(e).catch(() => {
						/* ignore */
					});
				}}
			>
				<TextPasswordInput isNew controllerProps={{ name: 'password', control, defaultValue: '' }} />
			</form>
			{(() => {
				const button = (
					<StyledButton
						cssExtra={css`
							min-width: 96px;
							margin-top: 0.5em;
						`}
						form={id}
						styleType={Style.RECT}
						type={'submit'}
					>
						Save
					</StyledButton>
				);

				return renderSubmitButton ? renderSubmitButton(button) : button;
			})()}
		</>
	);
}
