import { css, useTheme } from '@emotion/react';

import type { SerializedStyles } from '@emotion/react';
import type { ComponentPropsWithoutRef } from 'react';

interface Props extends ComponentPropsWithoutRef<'label'> {
	active?: boolean;
	invalid?: boolean;
	readonly?: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function StyledLabel(props: Props) {
	const { active, invalid, readonly, cssExtra, ...delegatedProps } = props;

	const theme = useTheme();

	const classes = [];
	if (active) classes.push('active');
	if (invalid) classes.push('invalid');
	if (readonly) classes.push('readonly');

	return (
		<label
			className={classes.join(' ')}
			css={css`
				position: absolute;
				top: 0.1em;
				bottom: 0;
				left: 0.85em;
				height: 1.5em;
				padding: 0 0.25em;
				margin: auto;
				font-size: 0.95em;
				line-height: 1.6;
				color: ${theme.inputs.colors.inactive};
				cursor: text;
				user-select: none;
				background-color: ${theme.inputs.colors.background};
				border-radius: var(--border-radius-1);
				transition: transform calc(var(--ease-time) / 2) var(--ease-fn),
					color calc(var(--ease-time) / 2) var(--ease-fn);
				transform: translateY(-0.05em);
				transform-origin: 0 0;

				&.active {
					color: ${theme.inputs.colors.active};
					text-shadow: 0.005em 0 0 currentColor;
					transform: scale(0.7) translate(-0.15em, -0.6em);
				}

				&.invalid {
					color: ${theme.inputs.colors.error};
				}

				&.readonly {
					color: ${theme.inputs.colors.readonly};
					background-color: ${theme.inputs.colors.readonlyBackground};
				}

				${cssExtra}
			`}
			{...delegatedProps}
		/>
	);
}
