import { css } from '@emotion/react';

import Main from '../../components/layout/view/Main';
import Article from '../../components/layout/article/Article';
import ArticleSection from '../../components/layout/article/ArticleSection';
import ArticleLabeledText from '../../components/layout/article/ArticleLabeledText';
import ArticleSubsection from '../../components/layout/article/ArticleSubsection';
import ArticleIntroSection from '../../components/layout/article/ArticleIntroSection';
import ArticleParagraph from '../../components/layout/article/ArticleParagraph';
import ArticleList from '../../components/layout/article/ArticleList';
import ArticleContent from '../../components/layout/article/ArticleContent';
import DistressedUnderlineAnchor from '../../components/buttons/DistressedUnderlineAnchor';
import DistressedUnderlineLink from '../../components/buttons/DistressedUnderlineLink';
import WavesBackgroundScaled from '../../components/layout/WavesBackgroundScaled';
import ArticleScrollToTop from '../../components/buttons/ArticleScrollToTop';
import SetMeta from '../../components/utils/SetMeta';

const REVISAL = new Date(2022, 6, 22);

export default function PrivacyPage() {
	return (
		<>
			<SetMeta description={''} title={'Privacy Notice'} />
			<Main
				cssExtra={css`
					color: var(--background-color);
					background: var(--muted-color-e);
				`}
			>
				<WavesBackgroundScaled
					colors={[null, null, null, null, null, 'var(--accent-color-b)']}
					cssExtra={css`
						height: auto;
						transform: scale(0.6);
						transform-origin: top right;
					`}
				/>
				<WavesBackgroundScaled
					colors={['var(--accent-color-y)', null, null, null, null, null]}
					cssExtra={css`
						bottom: 0;
						height: auto;
						transform: scale(0.5);
						transform-origin: bottom left;
					`}
				/>
				<Article header={'Privacy Notice'} revisal={REVISAL}>
					<ArticleIntroSection>
						<ArticleParagraph
							cssExtra={css`
								font-style: italic;
								font-weight: 700;
							`}
						>
							Welcome to Daresay!
						</ArticleParagraph>
						<ArticleParagraph>
							When you use our services, you are placing trust in us with your information. We understand
							this is a big responsibility and are committed to protecting your privacy. We’re dedicated
							to being transparent about how we handle your info.
						</ArticleParagraph>
						<ArticleParagraph>
							This privacy notice describes the ways we may collect, use, and share information when you
							use the Daresay Services. We encourage you to review this notice and our{' '}
							<DistressedUnderlineLink to={'/terms'}>Terms of Use</DistressedUnderlineLink> before using
							the Daresay Services. By using the Daresay Services, you agree to our policies and
							practices.
						</ArticleParagraph>
						<ArticleParagraph>
							When we refer to “Daresay” (or similar terms like “Daresay Games” and the pronouns “we”,
							“us”, and “our”), we mean Daresay Studio, LLC, which is the entity that handles and is
							responsible for your information. When we refer to our “Services”, we mean any of our games,
							apps, websites, and services that link to this notice.
						</ArticleParagraph>
						<ArticleParagraph>
							If you have any questions or concerns, please{' '}
							<DistressedUnderlineAnchor href={'#contact-us'}>contact us</DistressedUnderlineAnchor>.
						</ArticleParagraph>
					</ArticleIntroSection>
					<ArticleContent>
						<ArticleSection header={'Info we collect'} id={'info-we-collect'}>
							<ArticleParagraph>
								The information we collect depends on how you interact with the Daresay Services. There
								are three general categories of info we collect:{' '}
								<ArticleLabeledText>(i)</ArticleLabeledText> info you provide to us,{' '}
								<ArticleLabeledText>(ii)</ArticleLabeledText> info we automatically collect, and{' '}
								<ArticleLabeledText>(iii)</ArticleLabeledText> info from service providers and third
								parties.
							</ArticleParagraph>
							<ArticleSubsection header={'Info you provide'} id={'info-you-provide'}>
								<ArticleParagraph>
									We collect the info you choose to provide to us. You don’t have to provide
									information when we ask for it. However, if you decline, you may not be able to
									access certain features within the Daresay Services. Common examples include:
								</ArticleParagraph>
								<ArticleList>
									<li>
										Your email address, password, and other registration details for creating your
										Daresay account and securing your access to the Daresay Services.
									</li>
									<li>
										Your account information like game settings, key bindings, and friends list.
									</li>
									<li>
										Your name, billing address, payment information, and other details that allow us
										to process purchases you make.
									</li>
									<li>
										Your preferences, interests, and additional demographic info that you may
										provide to us (e.g. when using certain features or responding to surveys). This
										may include “sensitive” information.
									</li>
								</ArticleList>
							</ArticleSubsection>
							<ArticleSubsection
								header={'Info we collect automatically'}
								id={'info-we-collect-automatically'}
							>
								<ArticleParagraph>
									We automatically collect some info about how you interact with and navigate the
									Daresay Services, including the device and software you use to do so. The specific
									types of information we automatically collect may vary, but they generally include:
								</ArticleParagraph>
								<ArticleList>
									<li>
										Your usage-related interactions with the Daresay Services, like the game played,
										when your game session started and how long it lasted, in-game actions (like
										recorded gameplay), user interface actions (like clicks and searches), and
										social interactions (like chat logs and friend requests).
									</li>
									<li>
										Technical details about the device, hardware, and software you use to interact
										with the Daresay Services, including IP address, device make and model, device
										identifiers, processing capabilities, operating system, geographic location, and
										language preferences.
									</li>
									<li>
										Performance of the Daresay Services, including problems you may encounter (such
										as crash reports and response times).
									</li>
								</ArticleList>
								<ArticleParagraph>
									We use technologies like cookies, pixels, log files, and our servers to collect,
									store, and access info about how you interact with the Daresay Services. These
									technologies allow us to authenticate users, remember preferences, personalize
									experiences, manage ads, and conduct data analytics. You can learn more by reviewing
									our{' '}
									<DistressedUnderlineLink to={'/cookie-policy'}>
										Cookie Policy
									</DistressedUnderlineLink>
									.
								</ArticleParagraph>
								<ArticleParagraph>
									Please note that certain features in the Daresay Services are provided by third
									parties, which may also automatically collect info about your use of the Daresay
									Services. These features are subject to the respective third party’s privacy
									policies. For example, parts of the Daresay Services (including this website) are
									protected by reCAPTCHA, for which the Google{' '}
									<DistressedUnderlineAnchor href={'https://policies.google.com/privacy'}>
										Privacy Policy
									</DistressedUnderlineAnchor>{' '}
									and{' '}
									<DistressedUnderlineAnchor href={'https://policies.google.com/terms'}>
										Terms of Service
									</DistressedUnderlineAnchor>{' '}
									apply.
								</ArticleParagraph>
							</ArticleSubsection>
							<ArticleSubsection
								header={'Info we collect from third parties'}
								id={'info-from-third-parties'}
							>
								<ArticleParagraph>
									We sometimes receive info from third parties regarding your use of the Daresay
									Services or your interactions with us on other platforms. This info is shared with
									us following the respective third party’s privacy policy. Some examples include:
								</ArticleParagraph>
								<ArticleList>
									<li>
										Your account details from other platforms (e.g. Discord, Google, Reddit, Steam)
										that you choose to connect with the Daresay Services. We use these integrations
										to improve the Daresay Services. For example, they can make it easier to sign in
										or allow you to discover friends playing a game.
									</li>
									<li>
										Info about how you purchase, download, or access some Daresay Services through
										services operated by third parties. For example, suppose you decide to download
										our games on platforms like Steam, an app store, or a gaming console. In that
										case, the company operating the service may share some information with us to
										facilitate your access to and use of the Daresay Services.
									</li>
									<li>
										We maintain social media pages for both Daresay and our specific games. Examples
										include Facebook fan pages, Twitter profiles, Discord servers, and Reddit
										communities. In some cases, we collect statistics and insights from the
										respective platform for such pages. Each platform that shares statistics and
										insights with Daresay collects data according to its own privacy policy.
									</li>
									<li>
										Advertising and analytics info from third parties that help us improve Daresay
										Services, secure our websites, support our marketing initiatives, and manage our
										ad campaigns. These services, like Google Analytics, may collect info from you
										automatically.
									</li>
								</ArticleList>
								<ArticleParagraph>
									Please also note that when you click on links to external websites or services that
									third parties operate, they may process your info according to their policies and
									not ours. When we include links to third-party services, it doesn’t necessarily mean
									we endorse their practices.
								</ArticleParagraph>
							</ArticleSubsection>
						</ArticleSection>

						<ArticleSection header={'How we use & share info'} id={'how-we-use-info'}>
							<ArticleParagraph>
								We use and share your info following the legal bases below to help us provide, operate,
								improve, understand, customize, support, and market the Daresay Services.
							</ArticleParagraph>

							<ArticleSubsection header={'Legal Bases'} id={'legal-basis'}>
								{/* TODO: reference international data processing, e.g. GDPR */}
								<ArticleParagraph>
									We use your info <ArticleLabeledText>(i)</ArticleLabeledText> to provide the
									services outlined in the{' '}
									<DistressedUnderlineLink to={'/terms'}>Terms of Use</DistressedUnderlineLink>,{' '}
									<ArticleLabeledText>(ii)</ArticleLabeledText> with your consent, which you may
									revoke at any time, <ArticleLabeledText>(iii)</ArticleLabeledText> in our or others’
									legitimate interests (such as safety and security), and{' '}
									<ArticleLabeledText>(iv)</ArticleLabeledText> for legal obligations.
								</ArticleParagraph>
								<ArticleParagraph>
									For example, Daresay may use, share, or otherwise process data:
								</ArticleParagraph>
								<ArticleList>
									<li>
										As necessary to fulfill our contracts and terms (including the{' '}
										<DistressedUnderlineLink to={'/terms'}>Terms of Use</DistressedUnderlineLink>)
										and to provide the Daresay Services.
									</li>
									<li>To comply with a legal obligation or to exercise and defend legal claims.</li>
									<li>To protect the vital interests, including safety, of you and others.</li>
									<li>As necessary in the public interest.</li>
									<li>As necessary for the legitimate interests of Daresay or a third party.</li>
								</ArticleList>
							</ArticleSubsection>
							<ArticleSubsection header={'Info Usage'} id={'info-usage'}>
								<ArticleParagraph>
									We use the collected information to help us provide and improve the Daresay
									Services, communicate with you, and advertise effectively. The specific ways we use
									your info depend on how you use the Daresay Services.
								</ArticleParagraph>
								<ArticleParagraph>Here are some common ways we use info:</ArticleParagraph>
								<ArticleList>
									<li>
										To provide the Daresay Services to you, as well as support for them, such as
										helping you find a match in a game or resolving support tickets.
									</li>
									<li>
										To facilitate creating, authenticating, and otherwise managing your user
										account.
									</li>
									<li>
										To securely fulfill your payment transactions made through the Daresay Services.
									</li>
									<li>
										To deliver and customize our communications with you, like sending you important
										account-related announcements, requests for feedback, or promotions that we
										believe may interest you.
									</li>
									<li>
										To monitor, analyze, test, and improve our services and operations (e.g.
										measuring how the Daresay Services perform, tracking usage patterns, detecting
										bugs or errors).
									</li>
									<li>
										To personalize your experience with the Daresay Services, like presenting
										content tailored to your interests.
									</li>
									<li>
										To facilitate integrations with the Daresay Services, like social sharing and
										related functionality.
									</li>
									<li>
										To make the Daresay Services more accessible to you by accommodating specific
										needs such as visual or hearing impairments.
									</li>
									<li>To administer surveys, contests, and similar offerings.</li>
									<li>
										To promote the Daresay Services, including managing and measuring the
										effectiveness of promotional campaigns.
									</li>
								</ArticleList>
							</ArticleSubsection>
							<ArticleSubsection header={'Info Sharing'} id={'info-sharing'}>
								<ArticleParagraph>
									We don’t share your identifiable or sensitive info with third parties except as
									described in this notice. We never sell your information to third parties. We do
									share some info to help operate the Daresay Services. Examples include sharing:
								</ArticleParagraph>
								<ArticleList>
									<li>With console and platform providers.</li>
									<li>
										With other users (like when you use social features such as chat) - please be
										careful not to share your sensitive information when using social features!
									</li>
									<li>Publicly (like recorded gameplay, gameplay stats, and match history).</li>
									<li>
										With service providers that operate on our behalf (like cloud service providers,
										payment processors, or advertising partners).
									</li>
									<li>With your permission (like when you link external accounts).</li>
									<li>
										As a result of corporate transactions, such as a reorganization, merger, sale,
										joint venture, assignment, transfer, or disposition of all or any portion of our
										business or assets.
									</li>
									<li>
										Non-identifiable info (like aggregate data or anonymized info) with third
										parties.
									</li>
								</ArticleList>
								<ArticleParagraph>
									We may also use, disclose, and withhold info as necessary for a legal obligation,
									legitimate or public interest, or to protect the vital interest of you or someone
									else, such as:
								</ArticleParagraph>
								<ArticleList>
									<li>
										To comply with legal processes under applicable law (like requests from law
										enforcement or government authorities).
									</li>
									<li>
										To secure the Daresay Services (like detecting fraud or preventing
										cyberattacks).
									</li>
									<li>To protect players and third parties from injury, abuse, or crime.</li>
									<li>
										To protect our rights, property, and operations (like enforcing our contracts
										and terms, including our{' '}
										<DistressedUnderlineLink to={'/terms'}>Terms of Use</DistressedUnderlineLink>,
										and pursuing available remedies or limiting the damages we may sustain).
									</li>
								</ArticleList>
								<ArticleParagraph>
									Our privacy notice does not apply to entities we don’t control or instruct. We
									recommend you review our third-party service providers’ privacy policies to
									understand how they collect, use and share your personal information and how you can
									set your privacy preferences on their sites and apps.
								</ArticleParagraph>
							</ArticleSubsection>
						</ArticleSection>

						<ArticleSection header={'Your choices & controls'} id={'privacy-rights'}>
							<ArticleParagraph>
								We aim to give you meaningful control over the information we collect and process. Since
								you can interact with us in various ways, the specific choices available to you depend
								on the nature of our relationship. Common examples include:
							</ArticleParagraph>
							<ArticleList>
								<li>
									You can request that we provide access to, update, or delete personal info we have
									collected from you by reaching out to us as described in the{' '}
									<DistressedUnderlineAnchor href={'#contact-us'}>
										Contact Us
									</DistressedUnderlineAnchor>{' '}
									section. Upon receiving and verifying a request for account termination, we aim to
									deactivate or delete your account and information from our databases within
									approximately two weeks. However, some information may be retained in our files to
									prevent fraud, troubleshoot problems, assist with investigations, enforce our{' '}
									<DistressedUnderlineLink to={'/terms'}>Terms of Use</DistressedUnderlineLink>, or
									comply with legal requirements.
								</li>
								<li>
									You can update your email communication preferences using the unsubscribe links in
									our promotional emails or updating your Daresay account settings.
								</li>
								<li>
									You can modify your privacy settings with third parties (e.g. social login
									providers) to limit the info they may share with us.
								</li>
								<li>
									You can configure your device or browser settings to block, manage, or delete
									tracking technologies like cookies. However, doing so may cause side effects when
									using the Daresay Services, such as limited functionality.
								</li>
								<li>
									If you are under 18 years of age and have a Daresay account, you can request that we
									remove or anonymize certain content you have provided on the Daresay Services as
									described in the{' '}
									<DistressedUnderlineAnchor href={'#contact-us'}>
										Contact Us
									</DistressedUnderlineAnchor>{' '}
									section.
								</li>
							</ArticleList>
							<ArticleParagraph>
								You can access some parts of the Daresay Services without creating an account, making
								purchases, or submitting info to us. If you choose to use the Daresay Services, we
								generally retain your personal information as long as your account is active unless a
								more extended retention period is required for our operations (e.g. enforcing our{' '}
								<DistressedUnderlineAnchor href={'terms'}>Terms of Use</DistressedUnderlineAnchor>,
								resolving disputes, or complying with legal obligations).
							</ArticleParagraph>
						</ArticleSection>

						<ArticleSection header={'Global Operations'} id={'global-operations'}>
							<ArticleParagraph>
								We may process and store data in the United States or other countries where our service
								providers or we operate. Data protection laws may differ in these regions than in those
								where you live. If permissible under local law, by using the Daresay Services, you are
								authorizing Daresay to process info in any of these locations.
							</ArticleParagraph>
							<ArticleSubsection
								header={'Residents of the European Economic Area, United Kingdom, and Switzerland'}
								id={'euro-residents'}
							>
								<ArticleParagraph>
									In the European Economic Area, you are afforded certain rights under applicable data
									protection laws. You have the right to access, correct, or delete your info
									collected through the Daresay Services. In certain circumstances, you have the right
									to object to the processing of your information. In cases where you have provided
									our consent to process your information, you have the right to withdraw consent. To
									submit a request regarding these rights, refer to the{' '}
									<DistressedUnderlineAnchor href={'#contact-us'}>
										Contact Us
									</DistressedUnderlineAnchor>{' '}
									section below.
								</ArticleParagraph>
								<ArticleParagraph>
									We encourage you to address any concerns you may have by{' '}
									<DistressedUnderlineAnchor href={'#contact-us'}>
										contacting us
									</DistressedUnderlineAnchor>
									. You also have the right to complain to your local Data Protection Authority, which
									you can find more info about as follows:
								</ArticleParagraph>
								<ArticleList>
									<li>
										<DistressedUnderlineAnchor
											href={'https://edpb.europa.eu/about-edpb/board/members_en'}
											rel={'noopener noreferrer'}
											target={'_blank'}
										>
											European Economic Area
										</DistressedUnderlineAnchor>
									</li>
									<li>
										<DistressedUnderlineAnchor
											href={'https://ico.org.uk/global/contact-us/'}
											rel={'noopener noreferrer'}
											target={'_blank'}
										>
											United Kingdom
										</DistressedUnderlineAnchor>
									</li>
									<li>
										<DistressedUnderlineAnchor
											href={'https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html'}
											rel={'noopener noreferrer'}
											target={'_blank'}
										>
											Switzerland
										</DistressedUnderlineAnchor>
									</li>
								</ArticleList>
								<ArticleParagraph>
									For more information regarding the data we collect and our methods for collecting
									info, please see the{' '}
									<DistressedUnderlineAnchor href={'#info-we-collect'}>
										Info We Collect
									</DistressedUnderlineAnchor>
									. For more information regarding how we disclose info, please see the{' '}
									<DistressedUnderlineAnchor href={'#how-we-use-info'}>
										How We Use & Share Info
									</DistressedUnderlineAnchor>{' '}
									section. For more information regarding your rights, choices, and controls, please
									see the{' '}
									<DistressedUnderlineAnchor href={'#privacy-rights'}>
										Your Choices & Controls
									</DistressedUnderlineAnchor>{' '}
									section.
								</ArticleParagraph>
							</ArticleSubsection>
							<ArticleSubsection header={'Residents of California'} id={'california-residents'}>
								<ArticleParagraph>
									The California Consumer Privacy Act (CCPA) provides those of our users who are
									California residents the right to request information about the categories of
									personal info we collect, use, and disclose in the previous calendar year and
									request deletion of personal info. You will not be discriminated against for
									exercising these rights. To submit a request, refer to the{' '}
									<DistressedUnderlineAnchor href={'#contact-us'}>
										Contact Us
									</DistressedUnderlineAnchor>{' '}
									section below.
								</ArticleParagraph>
								<ArticleParagraph>
									In the past 12 months, we have collected the following categories of info, as
									described by the CCPA:
								</ArticleParagraph>
								<ArticleList>
									<li>Identifiers (like email address).</li>
									<li>Demographic info (like age).</li>
									<li>Commercial info (like purchase history).</li>
									<li>Internet or similar network activity (like game or website sessions).</li>
									<li>Audio, electronic, visual, or similar info (like voice chat).</li>
									<li>Geolocation info.</li>
									<li>Other “personal information” as defined under California law.</li>
									<li>
										Inferences drawn from any of these categories (like preferences or interests).
									</li>
								</ArticleList>
								<ArticleParagraph>
									For more information regarding the data we collect and our methods for collecting
									info, please see the{' '}
									<DistressedUnderlineAnchor href={'#info-we-collect'}>
										Info We Collect
									</DistressedUnderlineAnchor>
									. For more information regarding how we disclose info, please see the{' '}
									<DistressedUnderlineAnchor href={'#how-we-use-info'}>
										How We Use & Share Info
									</DistressedUnderlineAnchor>{' '}
									section. For more information regarding your rights, choices, and controls, please
									see the{' '}
									<DistressedUnderlineAnchor href={'#privacy-rights'}>
										Your Choices & Controls
									</DistressedUnderlineAnchor>{' '}
									section.
								</ArticleParagraph>
							</ArticleSubsection>
						</ArticleSection>

						<ArticleSection header={'Protecting Your Info'} id={'protecting-your-info'}>
							<ArticleSubsection header={'Our measures'} id={'our-measures'}>
								<ArticleParagraph>
									We’re committed to protecting your info and fulfilling our obligations under
									applicable data protection laws. However, we can’t guarantee the security of any
									information we collect from you. You’re acknowledging this risk by using the Daresay
									Services and sharing info with us.
								</ArticleParagraph>
								<ArticleParagraph>
									We maintain appropriate technical, organizational, and physical safeguards to
									protect your info from unauthorized access, use, loss, disclosure, alteration, or
									other forms of unlawful processing. For example, we use encryption and hashing to
									protect some of the info we collect (such as passwords and payment details).
								</ArticleParagraph>
								<ArticleParagraph>
									However, please note that despite our safeguards and efforts to protect your info,
									no server, network, or electronic transmission over the Internet is 100% secure. The
									Daresay Services are no exception. Although we do our best to protect your info, we
									can’t make assurances about our ability to prevent the loss or misuse of the
									information we collect.
								</ArticleParagraph>
							</ArticleSubsection>
							<ArticleSubsection header={'Your measures'} id={'your-measures'}>
								<ArticleParagraph>
									There are general measures you can take to help protect your digital privacy. Some
									examples include:
								</ArticleParagraph>
								<ArticleList>
									<li>
										Using strong passwords that are unique and complex (including uppercase and
										lowercase letters, numbers, and symbols) for your Daresay account.
									</li>
									<li>
										Cross-referencing your email address for data breaches with tools like{' '}
										<DistressedUnderlineAnchor href={'https://haveibeenpwned.com/'}>
											Have I Been Pwned?
										</DistressedUnderlineAnchor>
									</li>
									<li>
										Being careful not to expose private personal info when using social features
										within the Daresay Services or affiliated pages.
									</li>
									<li>
										Enabling security options like two-factor authentication on external accounts
										(like Google or Discord) that you connect to your Daresay account.
									</li>
								</ArticleList>
							</ArticleSubsection>
						</ArticleSection>

						<ArticleSection header={"Children's privacy"} id={'childrens-privacy'}>
							<ArticleParagraph>
								The Daresay Services are intended for general audiences and are not directed to children
								(generally considered to be under the age of 13). Daresay does not intentionally collect
								personal info from children. If your child is under 13 years of age and has provided us
								with personal info, please notify us by submitting a request as described in the{' '}
								<DistressedUnderlineAnchor href={'#contact-us'}>Contact Us</DistressedUnderlineAnchor>{' '}
								section. We will promptly remove any records of their information.
							</ArticleParagraph>
						</ArticleSection>

						<ArticleSection header={'Updates'} id={'updates'}>
							<ArticleParagraph>
								We’ll occasionally update this policy to keep you updated on how we handle your info. As
								technology, relevant law, and our business operations change, we’ll reflect those
								changes in this privacy notice. When we do, we’ll revise the date at the top of this
								notice. Sometimes, we may notify you about the changes via email or within the Daresay
								Services. We encourage you to review this notice periodically for changes since your
								last visit.
							</ArticleParagraph>
						</ArticleSection>

						<ArticleSection header={'Contact us'} id={'contact-us'}>
							<ArticleParagraph>
								For any request involving handling your info (like accessing, modifying, or deleting
								your information), please get in touch with our support team at{' '}
								<DistressedUnderlineAnchor href={'mailto:support@daresaygames.com'}>
									support@daresaygames.com
								</DistressedUnderlineAnchor>
								. Note that we may ask you to provide information to help us verify your identity before
								completing the request.
							</ArticleParagraph>
							<ArticleParagraph>
								If you have questions or concerns about this notice or our privacy practices, please
								share them with our Data Protection Officer (DPO) via email at{' '}
								<DistressedUnderlineAnchor href={'mailto:dpo@daresaygames.com'}>
									dpo@daresaygames.com
								</DistressedUnderlineAnchor>
								.
							</ArticleParagraph>
						</ArticleSection>
					</ArticleContent>
				</Article>
				<ArticleScrollToTop />
			</Main>
		</>
	);
}
