import { useRef, useState } from 'react';

import { css } from '@emotion/react';

import Caret from '../../../../public/images/ui/caret.svg';
import DropdownMenu from './DropdownMenu';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	menuChildren: ReactNode;
	buttonProps?: ComponentPropsWithoutRef<'button'>;
	menuStyle?: SerializedStyles;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function Dropdown(props: Props) {
	const buttonRef = useRef<HTMLButtonElement>(null);

	const { menuChildren, menuStyle, buttonProps, cssExtra, children, ...delegatedAttributes } = props;

	const [open, setOpen] = useState(false);

	return (
		<div
			className={open ? 'open' : ''}
			css={css`
				position: relative;
				display: flex;

				&.open > button > .caret {
					transform: rotate(180deg);
				}

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			<button
				css={css`
					display: flex;
					column-gap: 12px;
					align-items: center;
					padding-right: 12px;
					color: white;
				`}
				{...buttonProps}
				ref={buttonRef}
				aria-expanded={open}
				type={'button'}
				onClick={e => {
					setOpen(!open);
					buttonProps?.onClick?.(e);
				}}
			>
				{children}
				<Caret
					className={'caret'}
					css={css`
						height: 0.7em;
						transition: transform var(--ease-time) var(--ease-fn);
					`}
				/>
			</button>
			{open ? (
				<DropdownMenu
					cssExtra={menuStyle}
					onClickOutside={(event: MouseEvent) => {
						if (buttonRef.current?.contains(event.target as Node)) return;
						setOpen(false);
					}}
				>
					{menuChildren}
				</DropdownMenu>
			) : null}
		</div>
	);
}
