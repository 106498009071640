import { css } from '@emotion/react';

import { getProviderString } from '../../../components/buttons/AuthButton';
import AuthButtonAlt from '../../../components/buttons/AuthButtonAlt';
import AccountActionContainer from '../AccountActionContainer';
import StyledButton, { Style } from '../../../components/buttons/core/StyledButton';
import { useUser } from '../../../hooks/user';

import type { SerializedStyles } from '@emotion/react';
import type { User } from '../../../../types';
import type { AuthProvider } from '../../../components/buttons/AuthButton';

interface Props<T extends keyof User['auth']> {
	provider: AuthProvider;
	description: string;
	strategy: T;
	redirectUri: string;
	failureRedirectUri: string;
	getIdentifier?: (account: User['auth'][T]) => string | undefined;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const hasEmail = <T,>(data: T): data is T & { email: string } => {
	if (typeof data !== 'object' || data === null || Array.isArray(data)) return false;
	return typeof (data as Record<string, unknown>).email === 'string';
};

export default function AccountConnectedAccount<T extends keyof User['auth']>(props: Props<T>) {
	const { provider, description, strategy, redirectUri, failureRedirectUri, getIdentifier, cssExtra } = props;

	const { user } = useUser();

	if (!user) throw new Error('User is not available');

	const connectedAccount = user.auth[strategy];

	const identifier = (() => {
		if (!connectedAccount) return undefined;
		if (getIdentifier) return getIdentifier(connectedAccount);
		return hasEmail(connectedAccount) ? connectedAccount.email : undefined;
	})();

	return (
		<AccountActionContainer
			cssExtra={cssExtra}
			description={description}
			label={`Connect to ${getProviderString(provider)}`}
		>
			{identifier ? (
				<div
					className={'connected'}
					css={css`
						margin-right: 0.25em;
						text-align: end;
					`}
				>
					<div>
						<span>{identifier}</span>
					</div>
					<StyledButton
						css={css`
							font-size: 0.85em;

							&:hover {
								color: var(--accent-color-r);
							}
						`}
						styleType={Style.TEXT}
						onClick={() => {
							/* TODO */
						}}
					>
						(disconnect)
					</StyledButton>
				</div>
			) : (
				<AuthButtonAlt
					cssExtra={css`
						min-width: 200px;
						max-width: 240px;
					`}
					href={(() => {
						const params = new URLSearchParams({
							mode: 'connect',
							redirect_uri: new URL(redirectUri, window.location.href).href,
							failure_redirect_uri: new URL(failureRedirectUri, window.location.href).href,
						});

						return `/api/auth/${strategy}?${params.toString()}`;
					})()}
					prefix={'Connect to'}
					provider={provider}
				/>
			)}
		</AccountActionContainer>
	);
}
