import { css, keyframes } from '@emotion/react';

import type { SerializedStyles } from '@emotion/react';

interface Props {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const moveLeftKeyframes = keyframes`
    25% { 
	    transform: translateX(calc(-1 * var(--animation-h-movement))); 
    }
    50% {
	    transform: translateX(0); 
    }
    75% {
        transform: translateX(calc(-1 * var(--animation-h-movement)));
	    border-radius: 50%; 
    }
`;

const moveRightKeyframes = keyframes`
    25% { 
	    transform: translateX(var(--animation-h-movement)); 
    }
    50% {
	    transform: translateX(0); 
    }
    75% {
        transform: translateX(var(--animation-h-movement));
	    border-radius: 50%; 
    }
`;

const toCircleKeyframes = keyframes`
    50% {
	    border-radius: 0%;
	}
`;

const rotateKeyframes = keyframes`
    50% {
	    transform: rotate(-270deg);
	}
`;

export default function DancingCirclesSpinner(props: Props) {
	const { cssExtra } = props;

	return (
		<div
			css={css`
				--animation-size: 3em;
				--animation-child-radius: 0.3125em;
				--animation-h-movement: calc(var(--animation-size) - 2 * var(--animation-child-radius));

				width: var(--animation-size);
				height: var(--animation-size);

				${cssExtra};
			`}
		>
			<div
				css={css`
					position: relative;
					display: inline-block;
					width: var(--animation-size);
					height: var(--animation-size);
					animation: ${rotateKeyframes} 4.5s ease infinite;

					& div {
						position: absolute;
						top: 40%;
						box-sizing: border-box;
						width: 0;
						height: 0;
						background-color: currentColor;
						border: var(--animation-child-radius) solid currentColor;

						&:nth-of-type(1) {
							left: 0;
							color: var(--accent-color-g);
							animation: ${moveRightKeyframes} 3s ease infinite;
						}

						&:nth-of-type(2) {
							right: 0;
							left: 0;
							margin: auto;
							color: var(--accent-color-b);
							border-radius: 50%;
							animation: ${toCircleKeyframes} 3s ease infinite;
						}

						&:nth-of-type(3) {
							right: 0;
							color: var(--accent-color-r);
							animation: ${moveLeftKeyframes} 3s ease infinite;
						}
					}
				`}
			>
				<div />
				<div />
				<div />
			</div>
		</div>
	);
}
