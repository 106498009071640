import { css } from '@emotion/react';

import { useNotification } from '../../../hooks/notification';
import CloseButton from '../../buttons/CloseButton';

import type { ReactElement } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
	disableClose?: boolean;
	displayEmpty?: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
	substitute?: ReactElement;
}

export default function BoxNotification(props: Props) {
	const { disableClose = false, displayEmpty = true, cssExtra, substitute, ...delegatedProps } = props;

	const { notification, removeNotification } = useNotification();

	if (!displayEmpty && !notification) return null;

	return (
		<div
			css={css`
				--close-size: 10px;
				align-self: stretch;

				${cssExtra};
			`}
			{...delegatedProps}
			className={(() => {
				const classes = delegatedProps.className ? [delegatedProps.className] : [];
				if (!notification && !substitute) classes.push('empty');
				return classes.join(' ');
			})()}
		>
			{(() => {
				if (!notification) {
					if (!substitute) return null;
					return substitute;
				}

				return (
					<div
						className={`notification--${notification.type}`}
						css={css`
							--notification-color: var(--accent-color-b-alt-3);
							--notification-text-color: var(--primary-color);

							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 1em calc(1em + var(--close-size)) 1em 1em;
							font-weight: 700;
							color: var(--notification-text-color);
							background-color: var(--notification-color);
							border-radius: var(--border-radius-1);

							&.notification--success {
								--notification-color: var(--accent-color-g);
								--notification-text-color: var(--muted-color-6);
							}

							&.notification--warn {
								--notification-color: var(--accent-color-y);
								--notification-text-color: var(--muted-color-6);
							}

							&.notification--error {
								--notification-color: var(--accent-color-r);
							}
						`}
					>
						<p>{notification.message}</p>
						{disableClose ? undefined : (
							<button css={css``} type={'button'}>
								<CloseButton
									css={css`
										position: absolute;
										top: calc(1em / 2);
										right: calc(1em / 2);

										& > svg {
											width: var(--close-size);
											height: var(--close-size);
										}

										.notification--error > &:hover {
											color: var(--primary-color);
										}
									`}
									onClick={() => {
										removeNotification(notification.id);
									}}
								/>
							</button>
						)}
					</div>
				);
			})()}
		</div>
	);
}
