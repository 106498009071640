import { useEffect, useRef } from 'react';

import { RenderData } from '@daresay/api-client';

export default function useRenderDataFromParams() {
	const renderData = useRef<RenderData | null>(null);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);

		try {
			renderData.current = RenderData.fromParams(searchParams);
		} catch (error) {
			/* TODO: handle error */
		}
	}, []);

	return renderData.current;
}
