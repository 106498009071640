import { css } from '@emotion/react';

import Wordmark from '../../../../../public/images/brand/wordmark.svg';
import WordmarkFull from '../../../../../public/images/brand/wordmark-full.svg';
import Lettermark from '../../../../../public/images/brand/lettermark.svg';

import type { SerializedStyles } from '@emotion/react';

export interface Props {
	type: DaresayLogoType;
	height: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export enum DaresayLogoType {
	WORDMARK,
	WORDMARK_FULL,
	LETTERMARK,
}

// TODO: would be nice to track usage of the non-blank vectors (static property) - should warn in development if reused
//  (can cause animation conflicts)
export default function DaresayLogo(props: Props) {
	const { type, height, cssExtra } = props;

	const Graphic = (() => {
		switch (type) {
			case DaresayLogoType.WORDMARK:
				return Wordmark;
			case DaresayLogoType.WORDMARK_FULL:
				return WordmarkFull;
			case DaresayLogoType.LETTERMARK:
				return Lettermark;
			default: {
				const exhaustiveCheck: never = type;
				throw new Error(exhaustiveCheck);
			}
		}
	})();

	return (
		<Graphic
			css={css`
				height: ${height};

				${cssExtra};
			`}
		/>
	);
}
