import { css } from '@emotion/react';

import CloseButton from '../../buttons/CloseButton';

import type { NotificationMessage } from '../../../hooks/notification';
import type { SerializedStyles } from '@emotion/react';

interface Props {
	notification: NotificationMessage;
	removeNotification: (id: string) => void;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function Notification(props: Props) {
	const { notification, removeNotification, cssExtra } = props;

	return (
		<div
			className={`notification--${notification.type}`}
			css={css`
				--notification-color: var(--primary-color);

				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				min-height: 40px;
				padding: 3px var(--padding-h-main) 2px;
				font-size: 0.9em;
				font-weight: 700;
				color: var(--primary-color);
				background: rgba(51, 51, 51, 0.85);
				border-bottom: 2px solid var(--notification-color);
				backdrop-filter: blur(5px);

				&:last-child {
					border-radius: 0 0 var(--border-radius-1) var(--border-radius-1);
				}

				&.notification--success {
					--notification-color: var(--accent-color-g);
				}

				&.notification--warn {
					--notification-color: var(--accent-color-y);
				}

				&.notification--error {
					--notification-color: var(--accent-color-r);
				}

				${cssExtra};
			`}
		>
			<p>{notification.message}</p>
			<CloseButton
				cssExtra={css`
					color: var(--primary-color);
				`}
				onClick={() => {
					removeNotification(notification.id);
				}}
			/>
		</div>
	);
}
