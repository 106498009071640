import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';

import { getProviderString, getProviderStyle } from './AuthButton';
import { boxShadow } from '../styles';
import SocialLogo from '../graphics/vectors/social/SocialLogo';

import type { SocialLogoType } from '../graphics/vectors/social/SocialLogo';
import type { Props as AuthButtonProps } from './AuthButton';

interface Props extends AuthButtonProps {
	to: string;
}

export default function AuthNavLink(props: Props) {
	const { prefix, provider, cssExtra, to, ...delegatedAttributes } = props;

	const providerStr = getProviderString(provider);

	return (
		<NavLink
			css={css`
				display: flex;
				align-items: center;
				justify-content: center;

				&:active {
					transform: scale(0.95);
				}

				${boxShadow(2)}
				${getProviderStyle(provider)}
				${cssExtra}
			`}
			title={`${prefix} ${providerStr}`}
			to={to}
			{...delegatedAttributes}
		>
			<SocialLogo type={provider as unknown as typeof SocialLogoType[keyof typeof SocialLogoType]} />
		</NavLink>
	);
}
