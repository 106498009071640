import { css } from '@emotion/react';

import StyledAnchor, { Style } from '../../buttons/core/StyledAnchor';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'section'> {
	header: string;
	id: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export const contentClass = 'article-section';

export default function ArticleSection(props: Props) {
	const { header, className, cssExtra, children, ...delegatedAttributes } = props;
	return (
		<section
			className={className ? `${className} ${contentClass}` : contentClass}
			css={css`
				padding: 3em 0 6.5em;
				margin: 3em 0;
				scroll-margin-top: var(--header-height);
				counter-reset: article-h3;

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			<StyledAnchor
				cssExtra={css`
					white-space: normal;

					&:hover {
						color: var(--muted-color-4);
					}
				`}
				href={`#${delegatedAttributes.id}`}
				styleType={Style.TEXT}
			>
				<h2
					css={css`
						width: fit-content;
						margin-bottom: 2em;
						font-size: 1.75em;
						text-transform: uppercase;

						&::before {
							font-size: 0.8em;
							color: var(--accent-color-b);
							content: counter(article-h2) '.\\2000';
							counter-increment: article-h2;
						}

						@media (max-width: 600px) {
							&::before {
								font-size: 1.5em;
								white-space: pre;
								content: counter(article-h2) '\\A';
							}

							& > span {
								display: block;
								margin-top: 1em;
							}
						}
					`}
				>
					<span>{header}</span>
				</h2>
			</StyledAnchor>
			{children}
		</section>
	);
}
