import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';

import Dropdown from '../../../buttons/Dropdown';
import StyledButton, { Style } from '../../../buttons/core/StyledButton';
import StyledNavLink from '../../../buttons/core/StyledNavLink';
import { useUser } from '../../../../hooks/user';

import type { SerializedStyles } from '@emotion/react';

export interface Props {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const textLinkStyle = css`
	font-weight: 400;

	&:hover {
		color: var(--accent-color-b-alt-0);
	}
`;

export default function HeaderAccountDropdown(props: Props) {
	const { cssExtra } = props;

	const { logout } = useUser();

	return (
		<Dropdown
			aria-label={'Profile menu'}
			cssExtra={css`
				& > button {
					background: linear-gradient(135deg, var(--muted-color-5), var(--muted-color-6));
					border-radius: 99rem;
				}

				${cssExtra}
			`}
			menuChildren={
				<>
					<StyledNavLink cssExtra={textLinkStyle} styleType={Style.TEXT} to={'/account'}>
						Account
					</StyledNavLink>
					<StyledButton
						cssExtra={css`
							${textLinkStyle};

							&:hover {
								color: var(--accent-color-r);
							}
						`}
						styleType={Style.TEXT}
						onClick={() => {
							logout()
								.then(() => {
									window.location.href = '/';
								})
								.catch(error => {
									// TODO: display error
									Sentry.captureException(error);
									window.location.href = '/';
								});
						}}
					>
						Log out
					</StyledButton>
				</>
			}
			menuStyle={css`
				min-width: 140px;
				font-size: 0.9em;
				background: linear-gradient(135deg, var(--muted-color-5), var(--muted-color-6));
			`}
			title={'Profile menu'}
		>
			<div
				css={css`
					--avatar-diameter: 28px;
					width: var(--avatar-diameter);
					height: var(--avatar-diameter);
					background: linear-gradient(135deg, var(--accent-color-b), var(--accent-color-g));
					border-radius: 99rem;
				`}
			/>
		</Dropdown>
	);
}
