import { css } from '@emotion/react';

import { useScrollOnMount } from '@daresay/react-hooks';

// eslint-disable-next-line import/order
import { contentClass as tocClass } from './ArticleTableOfContents';

/* eslint-disable import/no-unresolved,@typescript-eslint/ban-ts-comment */
// @ts-ignore
import DistressedUnderlineSliceData from '../../../../../public/images/ui/underline-distressed-slice.svg?data';
/* eslint-enable import/no-unresolved,@typescript-eslint/ban-ts-comment */

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	header: string;
	revisal?: Date;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const formatDate = (date: Date) => {
	const formatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
	return formatter.format(date);
};

export default function Article(props: Props) {
	const { header, revisal, cssExtra, children, ...delegatedAttributes } = props;

	useScrollOnMount();

	return (
		<article
			css={css`
				position: relative;
				z-index: 0;
				max-width: 800px;
				padding: 9em min(28px, 8vw);
				margin: 0 auto;

				& > section:not(:last-child),
				& > .${tocClass} {
					position: relative;

					&::before {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: -1;
						content: '';

						border-bottom: 4px solid;
						border-image-source: url('${DistressedUnderlineSliceData}');
						border-image-slice: 33% 0;
					}
				}

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			<h1
				css={css`
					margin-bottom: 0.5em;
					font-size: 2em;
					text-transform: uppercase;

					counter-reset: article-h2;
				`}
			>
				{header}
			</h1>
			{revisal ? (
				<>
					<span>Last updated: </span>
					<time dateTime={revisal.toISOString().split('T')[0]}>{formatDate(revisal)}</time>
				</>
			) : null}

			{children}
		</article>
	);
}
