import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

type Props = (
	| (ComponentPropsWithoutRef<'ul'> & { ordered?: false })
	| (ComponentPropsWithoutRef<'ol'> & { ordered: true })
) & {
	cssExtra?: SerializedStyles | SerializedStyles[];
};

export default function ArticleList(props: Props) {
	const { cssExtra, ordered, children, ...delegatedAttributes } = props;

	const ListTag = ordered ? 'ol' : 'ul';

	return (
		<ListTag
			css={css`
				padding-left: 2em;
				margin: 1.4em 0 1.4em 1.4em;

				&:last-child {
					margin-bottom: 0;
				}

				& > li {
					padding-left: 0.7em;
					margin: 1.15em 0;
				}

				& > li:last-child {
					margin-bottom: 0;
				}

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			{children}
		</ListTag>
	);
}
