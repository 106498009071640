import { Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { SentinelError } from '@daresay/api-client';

import AuthErrorCard, {
	AuthErrorContentWrapper,
	noteTextStyle,
	parseErrorAuthData,
} from '../../../components/layout/card/AuthErrorCard';
import StyledNavLink, { Style } from '../../../components/buttons/core/StyledNavLink';
import { rectButtonStyle, textButtonStyle } from '../../../components/layout/card/Card';
import StyledAnchor from '../../../components/buttons/core/StyledAnchor';
import CardFooterNavLink from '../../../components/layout/card/CardFooterNavLink';

import type { RenderData } from '@daresay/api-client';

const render = (data: RenderData) => {
	const { error } = data;

	if (error?.name && error.name.startsWith('SentinelError.AuthError.LoginFailure')) {
		return <Navigate to={`/login/error${window.location.search}`} />;
	}

	switch (error?.name) {
		case 'SentinelError.AuthError.CreateFailure.IdentityConflict': {
			const email = (() => {
				const auth = parseErrorAuthData(error);
				if (auth) {
					return typeof auth.email === 'string' ? auth.email : undefined;
				}

				return undefined;
			})();

			return (
				<>
					<p>
						Another Daresay account is already associated with{' '}
						{email ? (
							<StyledAnchor cssExtra={textButtonStyle} href={`mailto:${email}`} styleType={Style.TEXT}>
								{email}
							</StyledAnchor>
						) : (
							'this email'
						)}
						.
					</p>
					<p css={noteTextStyle}>Please log in using a different method to manage your connected accounts.</p>
					<StyledNavLink cssExtra={rectButtonStyle} styleType={Style.RECT} to={'/login'}>
						Log in
					</StyledNavLink>
				</>
			);
		}
		default:
			Sentry.captureException(SentinelError.parse(error));
			return (
				<>
					<p>An unexpected error has occurred.</p>
					<StyledNavLink cssExtra={rectButtonStyle} styleType={Style.RECT} to={'/signup'}>
						Back to sign up
					</StyledNavLink>
				</>
			);
	}
};

export default function SignupErrorCard() {
	return (
		<AuthErrorCard
			render={render}
			renderWrapper={children => (
				<AuthErrorContentWrapper
					footerChildren={
						<>
							<CardFooterNavLink to={'/signup'}>Back to sign up</CardFooterNavLink>
							<CardFooterNavLink emphasis={'Log in'} to={'/login'}>
								Have an account?
							</CardFooterNavLink>
						</>
					}
					title={'Signup failed'}
				>
					{children}
				</AuthErrorContentWrapper>
			)}
		/>
	);
}
