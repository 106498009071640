import { css } from '@emotion/react';

/* eslint-disable import/no-unresolved,import/order,@typescript-eslint/ban-ts-comment */
// @ts-ignore
import DistressedUnderlineData from '../../../../public/images/ui/underline-distressed.svg?data';
// @ts-ignore
import DistressedUnderlineColorData from '../../../../public/images/ui/underline-distressed-color.svg?data';
/* eslint-enable import/no-unresolved,import/order,@typescript-eslint/ban-ts-comment */

import { isHrefExternal } from '../../../common/helpers';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'a'> {
	children: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export const underlineStyle = (color = false, xPos: number | null = null) => css`
	background-image: url('${color ? DistressedUnderlineColorData : DistressedUnderlineData}');
	background-repeat: repeat-x, no-repeat;
	background-position: ${xPos ?? Math.floor(Math.random() * 11) * 10}% 200%;
	background-size: 36em 0.7em, 0 0;
`;

export const aStyle = () => css`
	&:is(:link, :visited, :active, :hover) {
		color: inherit;
	}

	&:hover {
		background-image: url('${DistressedUnderlineColorData}');
	}

	${underlineStyle()}
`;

export default function DistressedUnderlineAnchor(props: Props) {
	const { children, cssExtra, href, ...delegatedAttributes } = props;
	return (
		<a
			css={css`
				${aStyle()}
				${cssExtra};
			`}
			href={href}
			rel={'noopener noreferrer'}
			role={'link'}
			target={isHrefExternal(href) ? '_blank' : undefined}
			{...delegatedAttributes}
		>
			{children}
		</a>
	);
}
