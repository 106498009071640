import { css } from '@emotion/react';

import SocialLogo, { SocialLogoType } from '../graphics/vectors/social/SocialLogo';
import { boxShadow } from '../styles';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

export enum AuthProvider {
	DISCORD = SocialLogoType.DISCORD,
	GOOGLE = SocialLogoType.GOOGLE,
	REDDIT = SocialLogoType.REDDIT,
	TWITCH = SocialLogoType.TWITCH,
	STEAM = SocialLogoType.STEAM,
	APPLE = SocialLogoType.APPLE,
	FACEBOOK = SocialLogoType.FACEBOOK,
}

export interface Props extends ComponentPropsWithoutRef<'a'> {
	provider: AuthProvider;
	prefix: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export const getProviderString = (provider: AuthProvider) => {
	const providerKey = AuthProvider[provider];
	return `${providerKey.charAt(0).toUpperCase()}${providerKey.substring(1).toLowerCase()}`;
};

export const getProviderStyle = (provider: AuthProvider) => {
	switch (provider) {
		case AuthProvider.DISCORD:
			return css`
				background: #5865f2;

				& svg {
					height: 70%;

					& [fill]:not([fill^='url']) {
						fill: #ffffff;
					}
				}
			`;
		case AuthProvider.GOOGLE:
			return css`
				background: #ffffff;

				& svg {
					height: 70%;
				}
			`;
		case AuthProvider.REDDIT:
			return css`
				background: #ff4500;

				& svg {
					height: 70%;

					& [fill]:not([fill^='url']) {
						fill: #ffffff;
					}
				}
			`;
		case AuthProvider.TWITCH:
			return css`
				background: #9146ff;

				& svg {
					position: relative;
					top: 4%;
					left: 2%;
					height: 68%;

					& [fill]:not([fill^='url']) {
						fill: #ffffff;
					}
				}
			`;
		case AuthProvider.STEAM:
			return css`
				background: linear-gradient(45deg, #1384b6, #101c2f);

				& svg {
					height: 70%;

					& [fill^='url'] {
						fill: #ffffff;
					}
				}
			`;
		case AuthProvider.APPLE:
			return css`
				background: #000000;

				& svg {
					position: relative;
					bottom: 1%;
					height: 70%;
				}
			`;
		case AuthProvider.FACEBOOK:
			return css`
				background: #1877f2;

				& svg {
					height: 100%;

					& [opacity='0'] {
						opacity: 1;
					}

					& *:not([opacity='0']) {
						opacity: 0;
					}
				}
			`;
		default: {
			return '';
		}
	}
};

export default function AuthButton(props: Props) {
	const { prefix, provider, cssExtra, ...delegatedAttributes } = props;

	const providerStr = getProviderString(provider);

	return (
		<a
			css={css`
				display: flex;
				align-items: center;
				justify-content: center;

				&:active {
					transform: scale(0.95);
				}

				${boxShadow(2)}
				${getProviderStyle(provider)}
				${cssExtra}
			`}
			title={`${prefix} ${providerStr}`}
			{...delegatedAttributes}
		>
			<SocialLogo type={provider as unknown as typeof SocialLogoType[keyof typeof SocialLogoType]} />
		</a>
	);
}
