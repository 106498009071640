import { css, keyframes } from '@emotion/react';

export default function PongSpinner() {
	const pongStyle = css`
		width: 6px;
		height: 40px;
		color: var(--primary-color);

		.light-mode & {
			color: var(--muted-color-6);
		}

		animation: ${keyframes`
			0% {
				box-shadow: -25px -10px, 25px 10px;
			}
			50% {
				box-shadow: -25px 8px, 25px -10px;
			}
			100% {
				box-shadow: -25px -10px, 25px 10px;
			}
		`} 0.75s ease-out infinite;

		&:before {
			position: absolute;
			top: 15px;
			right: 0;
			left: 0;
			width: 12px;
			height: 12px;
			margin: 0 auto;
			content: '';
			background-color: var(--accent-color-r);
			border-radius: 50%;
			animation: ${keyframes`
				0% {
					transform: translateX(-20px) scale(1, 1.2);
				}
				25% {
					transform: scale(1.2, 1);
				}
				50% {
					transform: translateX(15px) scale(1, 1.2);
				}
				75% {
					transform: scale(1.2, 1);
				}
				100% {
					transform: translateX(-20px);
				}
			`} 0.6s ease-out infinite;
		}
	`;

	return (
		<div
			css={css`
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				transform: scale(0.5);

				${pongStyle};
			`}
		/>
	);
}
