import { forwardRef, useId, useState } from 'react';

import { css, useTheme } from '@emotion/react';

import { inputStyle } from './TextInput';
import StyledLabel from './StyledLabel';
import ValidationMessageWrapper from '../ValidationMessageWrapper';

import type { FieldError } from 'react-hook-form';
import type { SerializedStyles } from '@emotion/react';
import type { ComponentPropsWithoutRef, ReactElement } from 'react';

export interface Props {
	inputProps?: ComponentPropsWithoutRef<'textarea'>;
	labelProps?: ComponentPropsWithoutRef<'label'>;
	cssExtra?: SerializedStyles | SerializedStyles[];
	children?: ReactElement | string | (ReactElement | string)[];
	indicator?: ReactElement | string | (ReactElement | string)[];
	fieldError?: FieldError;
}

const TextAreaInput = forwardRef<HTMLTextAreaElement, Props>(function TextAreaInput(props, ref) {
	const { inputProps, labelProps, cssExtra, children, indicator, fieldError } = props;

	const [characterCount, setCharacterCount] = useState(
		(typeof inputProps?.value === 'string' && inputProps.value.length) ||
			(typeof inputProps?.defaultValue === 'string' && inputProps.defaultValue.length) ||
			0,
	);

	const [focus, setFocus] = useState(!!inputProps?.autoFocus);

	let inputId = useId();
	if (inputProps?.id) inputId = inputProps.id;

	const theme = useTheme();

	const invalid = !!fieldError?.message;
	const populated = !!characterCount;
	const inputClasses = [];
	if (inputProps?.className) inputClasses.push(inputProps.className);
	if (populated) inputClasses.push('populated');
	if (invalid) inputClasses.push('invalid');

	return (
		<div
			css={css`
				text-rendering: optimizeLegibility;
			`}
		>
			<div
				className={invalid ? 'invalid-parent' : undefined}
				css={css`
					position: relative;
					height: 8em;
					min-height: 2.75em;
					max-height: 20em;
					overflow: hidden;
					resize: vertical;
					border: 1px solid ${theme.inputs.colors.border};
					border-radius: var(--border-radius-1);

					&.invalid-parent {
						border-bottom: 2px solid ${theme.inputs.colors.error};
					}

					${cssExtra};
				`}
			>
				<textarea
					ref={ref}
					css={css`
						${inputStyle(theme)};

						padding-top: 1.3em;
						padding-bottom: 0.5em;
						resize: none;
						border: none !important;
					`}
					{...inputProps}
					className={inputClasses.join(' ')}
					id={inputId}
					onBlur={e => {
						inputProps?.onBlur?.(e);
						setFocus(false);
					}}
					onChange={e => {
						inputProps?.onChange?.(e);
						setCharacterCount(e.currentTarget.value.length);
					}}
					onFocus={e => {
						inputProps?.onFocus?.(e);
						setFocus(true);
					}}
				/>
				<StyledLabel
					active={focus || populated}
					invalid={invalid}
					readonly={inputProps?.readOnly}
					{...labelProps}
					cssExtra={css`
						margin: 0;
						margin-top: 0.615em;
						left: calc(0.85em - 1px);
					`}
					htmlFor={inputId}
				/>
				{children}
			</div>
			{indicator}
			<div
				css={css`
					display: flex;
					justify-content: space-between;
				`}
			>
				{invalid ? (
					<>
						<ValidationMessageWrapper>{fieldError.message}</ValidationMessageWrapper>
						{fieldError.type === 'minLength' || fieldError.type === 'maxLength' ? (
							<span
								css={css`
									margin: 0.5em 0.85em 0;
									margin-left: auto;
									font-size: 0.85em;
									line-height: 1.1;
									color: ${theme.inputs.colors.error};
								`}
							>
								<span
									css={css`
										font-size: 0.85em;
									`}
								>
									{characterCount} chars
								</span>
							</span>
						) : undefined}
					</>
				) : undefined}
			</div>
		</div>
	);
});

export default TextAreaInput;
