import { lazy, Suspense } from 'react';

import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';

import View from '../components/layout/view/View';
import Fallback from '../components/layout/Fallback';
import ErrorView from '../components/layout/error/ErrorView';
import ErrorContent from '../components/layout/error/ErrorContent';
import WavesBackgroundScaled from '../components/layout/WavesBackgroundScaled';
import ContentSkeleton from '../components/graphics/ContentSkeleton';

import type { ReactElement } from 'react';
import type { Props } from '../components/layout/Fallback';

const Home = lazy(() => import('../pages'));
const Contact = lazy(() => import('../pages/contact'));
const Login = lazy(() => import('../pages/login'));
const LoginSuccess = lazy(() => import('../pages/login/success'));
const LoginError = lazy(() => import('../pages/login/error'));
const LoginHelp = lazy(() => import('../pages/login/help'));
const LoginResetPassword = lazy(() => import('../pages/login/reset-password'));
const Signup = lazy(() => import('../pages/signup'));
const SignupReddit = lazy(() => import('../pages/signup/reddit'));
const SignupSuccess = lazy(() => import('../pages/signup/success'));
const SignupError = lazy(() => import('../pages/signup/error'));
const Privacy = lazy(() => import('../pages/privacy'));
const Terms = lazy(() => import('../pages/terms'));
const NotFound = lazy(() => import('../pages/error/NotFound'));

const Account = lazy(() => import('../pages/account'));
const AccountOverview = lazy(() => import('../pages/account/overview'));
const AccountNotificationSettings = lazy(() => import('../pages/account/notifications'));
const AccountConnectedAccounts = lazy(() => import('../pages/account/connected-accounts'));
const AccountRedeemCode = lazy(() => import('../pages/account/redeem'));

function Layout() {
	return (
		<View>
			<Outlet />
		</View>
	);
}

function LazyView(props: { element: ReactElement; fallbackProps?: Props }) {
	const { element, fallbackProps } = props;

	return (
		<Sentry.ErrorBoundary
			fallback={
				<WavesBackgroundScaled
					colors={['var(--accent-color-b)', null, null, null, 'var(--accent-color-b)', null]}
				>
					<div
						css={css`
							position: absolute;
							top: 0;
							left: 0;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100%;
							height: 100%;
						`}
					>
						<ErrorView errorMessage={'Something went wrong'} errorStatus={'#$%!'} />
					</div>
				</WavesBackgroundScaled>
			}
		>
			<Suspense fallback={<Fallback {...fallbackProps} />}>{element}</Suspense>
		</Sentry.ErrorBoundary>
	);
}

function LazyAccountContent(props: { element: ReactElement }) {
	const { element } = props;

	return (
		<Sentry.ErrorBoundary fallback={<ErrorContent />}>
			<Suspense fallback={<ContentSkeleton />}>{element}</Suspense>
		</Sentry.ErrorBoundary>
	);
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function Router() {
	return (
		<BrowserRouter>
			<SentryRoutes>
				<Route element={<Layout />} path={'/'}>
					<Route index element={<LazyView element={<Home />} />} />
					<Route element={<LazyView element={<Contact />} />} path={'contact'} />
					<Route element={<LazyView element={<Login />} />} path={'login'} />
					<Route element={<LazyView element={<LoginSuccess />} />} path={'login/success'} />
					<Route element={<LazyView element={<LoginError />} />} path={'login/error'} />
					<Route element={<LazyView element={<LoginHelp />} />} path={'login/help'} />
					<Route element={<LazyView element={<LoginResetPassword />} />} path={'login/reset-password'} />
					<Route element={<LazyView element={<Signup />} />} path={'signup'} />{' '}
					<Route element={<LazyView element={<SignupReddit />} />} path={'signup/reddit'} />
					<Route element={<LazyView element={<SignupSuccess />} />} path={'signup/success'} />
					<Route element={<LazyView element={<SignupError />} />} path={'signup/error'} />
					<Route
						element={
							<LazyView
								element={
									<Account
										menuItems={[
											{ title: 'Account overview', to: '/account/overview' },
											{ title: 'Connected accounts', to: '/account/connected-accounts' },
											{ title: 'Notifications', to: '/account/notifications' },
											{ title: 'Redeem code', to: '/account/redeem' },
										]}
									/>
								}
							/>
						}
						path={'account'}
					>
						<Route index element={<Navigate replace to={'overview'} />} />
						<Route element={<LazyAccountContent element={<AccountOverview />} />} path={'overview'} />
						<Route
							element={<LazyAccountContent element={<AccountNotificationSettings />} />}
							path={'notifications'}
						/>
						<Route
							element={<LazyAccountContent element={<AccountConnectedAccounts />} />}
							path={'connected-accounts'}
						/>
						<Route element={<LazyAccountContent element={<AccountRedeemCode />} />} path={'redeem'} />
						<Route element={<ErrorContent />} path={'error'} />
						<Route element={<LazyAccountContent element={<ContentSkeleton />} />} path={'skeleton'} />
					</Route>
					<Route
						element={<LazyView element={<Privacy />} fallbackProps={{ lightMode: true }} />}
						path={'privacy'}
					/>
					<Route
						element={<LazyView element={<Terms />} fallbackProps={{ lightMode: true }} />}
						path={'terms'}
					/>
					<Route element={<LazyView element={<NotFound />} />} path={'*'} />
				</Route>
			</SentryRoutes>
		</BrowserRouter>
	);
}

export default Sentry.withProfiler(Router);
