import { css } from '@emotion/react';

import StyledButton, { Style } from '../../components/buttons/core/StyledButton';

import type { Props as StyledButtonProps } from '../../components/buttons/core/StyledButton';

interface Props {
	open: boolean;
	openButtonProps: Omit<StyledButtonProps, 'children'> & { children?: StyledButtonProps['children'] };
	cancelButtonProps: Omit<StyledButtonProps, 'children'> & { children?: StyledButtonProps['children'] };
}

export default function AccountActionContainerButtonSet(props: Props) {
	const { open, openButtonProps, cancelButtonProps } = props;

	return open ? (
		<StyledButton
			key={'cancel'}
			css={css`
				align-self: flex-start;
				margin-left: auto;
				color: var(--muted-color-1);

				&:hover {
					color: var(--accent-color-r);
				}
			`}
			styleType={Style.TEXT}
			type={'reset'}
			{...cancelButtonProps}
		>
			{cancelButtonProps.children ?? 'Cancel'}
		</StyledButton>
	) : (
		<StyledButton
			key={'change'}
			css={css`
				min-width: 128px;
			`}
			styleType={Style.RECT}
			{...openButtonProps}
		>
			{openButtonProps.children ?? 'Change'}
		</StyledButton>
	);
}
