import { forwardRef } from 'react';

import { css, useTheme } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props {
	inputProps?: ComponentPropsWithoutRef<'input'>;
	labelProps?: ComponentPropsWithoutRef<'label'>;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const SwitchBox = forwardRef<HTMLInputElement, Props>(function SwitchBox(props, ref) {
	const { inputProps, labelProps, cssExtra } = props;

	const theme = useTheme();

	return (
		<label
			css={css`
				position: relative;
				display: flex;
				column-gap: 6px;
				align-items: center;
				color: ${theme.inputs.colors.primaryAlt};
				cursor: pointer;
				text-rendering: optimizeLegibility;
				transition: color var(--ease-time) var(--ease-fn);

				&:hover {
					color: ${theme.inputs.colors.active};
				}

				${cssExtra};
			`}
			{...labelProps}
		>
			<input
				ref={ref}
				type={'checkbox'}
				{...inputProps}
				css={css`
					position: absolute;
					width: 100%;
					height: 100%;
					appearance: none;
					cursor: pointer;

					&:checked {
						& ~ .switchbox {
							background-color: ${theme.inputs.colors.activeStrong};

							&::after {
								left: 1.1em;
							}
						}
					}

					&:not(:checked) {
						& ~ .switchbox {
							background-color: ${theme.inputs.colors.background};
						}
					}
				`}
			/>
			<div
				className={'switchbox'}
				css={css`
					position: relative;
					display: flex;
					align-items: center;
					width: 2em;
					height: 1em;
					cursor: pointer;
					border: 0.1em solid ${theme.inputs.colors.border};
					border-radius: 99rem;
					transition: all var(--ease-time) ease;

					&::after {
						position: absolute;
						left: 0.1em;
						display: inline-block;
						width: 0.6em;
						height: 0.6em;
						content: '';
						background-color: ${theme.inputs.colors.border};
						border-radius: 99rem;
						transition: all var(--ease-time) ease;
					}
				`}
			/>
			{labelProps?.children}
		</label>
	);
});

export default SwitchBox;
