import { useState } from 'react';

import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';

import { SentinelError } from '@daresay/api-client';

import Card, { rectButtonStyle } from '../../components/layout/card/Card';
import { NotificationContext, useNotificationDispatch } from '../../hooks/notification';
import BoxNotification from '../../components/layout/notification/BoxNotification';
import ContactForm from '../../components/input/forms/ContactForm';
import PolicyLinkSet from '../../components/layout/PolicyLinkSet';
import CardDaresayLogo from '../../components/layout/card/CardDaresayLogo';
import StyledAnchor, { Style } from '../../components/buttons/core/StyledAnchor';
import CardHeading from '../../components/layout/card/CardHeading';
import CardDancingCirclesSpinner from '../../components/layout/card/CardDancingCirclesSpinner';
import CardFooter from '../../components/layout/card/CardFooter';
import CardPanel from '../../components/layout/card/CardPanel';
import CardMain from '../../components/layout/card/CardMain';
import CardMainSection from '../../components/layout/card/CardMainSection';

const config = [
	{ name: 'Support', email: 'support@daresaygames.com' },
	{ name: 'General', email: 'contact@daresaygames.com' },
];

const parseError = (error: Error): string | undefined => {
	if (!(error instanceof SentinelError)) {
		Sentry.captureException(error);
		return undefined;
	}

	switch (error.name) {
		default:
			Sentry.captureException(error);
			return undefined;
	}
};

export default function ContactCard() {
	const notificationDispatch = useNotificationDispatch();
	const { setNotification, removeNotification } = notificationDispatch;

	const [inProgress, setInProgress] = useState(false);

	return (
		<NotificationContext.Provider value={notificationDispatch}>
			<Card
				cssExtra={css`
					display: flex;
					flex-grow: 0.15;
				`}
			>
				<CardPanel
					cssExtra={css`
						width: min(600px, 90vw);
						padding: 60px min(60px, 8vw);
					`}
				>
					<CardDaresayLogo />

					<BoxNotification
						cssExtra={css`
							--close-size: 7px;

							margin-top: -6px;
							margin-bottom: -10px;

							&.empty {
								margin-top: -12px;
								margin-bottom: -20px;
							}
						`}
					/>

					<CardMain>
						<CardHeading>Get in touch</CardHeading>

						<CardMainSection description={"Send us a message, and we'll respond swiftly."}>
							<ContactForm
								cssExtra={css`
									& + button[type='submit'] {
										${rectButtonStyle};
									}
								`}
								onSubmitError={error => {
									setInProgress(false);
									setNotification({
										message: parseError(error) ?? 'An unexpected error has occurred',
										type: 'error',
									});
								}}
								onSubmitStart={() => {
									setInProgress(true);
									removeNotification();
								}}
								onSubmitSuccess={data => {
									setInProgress(false);
									setNotification({
										type: 'success',
										message: `We received your message! Our team will get back to you at ${data.email}.`,
									});
								}}
							/>

							<CardDancingCirclesSpinner show={inProgress} />
						</CardMainSection>

						<div
							css={css`
								align-self: stretch;
								margin-top: 20px;
							`}
						>
							<div
								css={css`
									margin-top: 1em;
								`}
							>
								{config.map(item => {
									return (
										<div
											key={item.name}
											css={css`
												display: flex;
												flex-direction: column;
												align-items: center;
												font-size: 0.9em;

												&:not(:last-of-type) {
													margin-bottom: 1em;
												}
											`}
										>
											<span
												css={css`
													margin-bottom: 0.2em;
													font-weight: 700;
												`}
											>
												{item.name}
											</span>
											<StyledAnchor
												cssExtra={css`
													&:is(:link, :visited, :active) {
														color: var(--muted-color-5);
													}

													&:hover {
														color: var(--accent-color-b);
													}

													display: block;
													font-weight: 400;
												`}
												href={`mailto:${item.email}`}
												styleType={Style.TEXT}
											>
												{item.email}
											</StyledAnchor>
										</div>
									);
								})}
							</div>
						</div>
					</CardMain>
					<CardFooter>
						<PolicyLinkSet />
					</CardFooter>
				</CardPanel>
			</Card>
		</NotificationContext.Provider>
	);
}
