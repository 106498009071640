import { useEffect, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';

import HamburgerButton from '../../../buttons/HamburgerButton';
import SideMenu from '../../SideMenu';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
	children?: undefined;
}

const uncheckedButtonStyle = css`
	& path {
		stroke: var(--primary-color);
	}

	&:hover {
		& path {
			stroke: var(--accent-color-b-alt-1);
		}
	}
`;

const checkedButtonStyle = css`
	& path {
		stroke: var(--muted-color-6);
	}

	&:hover {
		& path {
			stroke: var(--accent-color-r);
		}
	}
`;

function HeaderNavMenu(props: Props) {
	const { cssExtra, ...delegatedAttributes } = props;

	const buttonRef = useRef<HTMLButtonElement>(null);

	const [checked, setChecked] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setChecked(false);
	}, [location]);

	return (
		<div
			css={css`
				display: flex;
				column-gap: 28px;
				align-items: center;

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			<HamburgerButton
				ref={buttonRef}
				checked={checked}
				cssExtra={css`
					z-index: 11;
					padding: 3px 0 3px 3px;

					& g {
						fill: var(--muted-color-2);
					}

					&:hover {
						& g {
							fill: var(--primary-color);
						}
					}

					${checked ? checkedButtonStyle : uncheckedButtonStyle};
				`}
				height={'18px'}
				width={'21px'}
				onClick={() => setChecked(!checked)}
			/>
			<SideMenu
				checked={checked}
				onClickOutside={(event: MouseEvent) => {
					if (buttonRef.current?.contains(event.target as Node)) return;
					setChecked(false);
				}}
			/>
		</div>
	);
}

export default HeaderNavMenu;
