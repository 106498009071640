import { forwardRef, useEffect, useId, useState } from 'react';

import { css, useTheme } from '@emotion/react';

import ValidationMessageWrapper from '../ValidationMessageWrapper';
import StyledLabel from './StyledLabel';

import type { ComponentPropsWithoutRef, ReactElement } from 'react';
import type { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';
import type { SerializedStyles, Theme } from '@emotion/react';

export interface Props {
	inputProps?: ComponentPropsWithoutRef<'input'>;
	labelProps?: ComponentPropsWithoutRef<'label'>;
	cssExtra?: SerializedStyles | SerializedStyles[];
	children?: ReactElement | string | (ReactElement | string)[];
	indicator?: ReactElement | string | (ReactElement | string)[];
	errorMessage?: string;
}

export const transformRegisterRules = <
	TFieldValues extends FieldValues = FieldValues,
	TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
	label: string,
	options:
		| RegisterOptions<TFieldValues, TFieldName>
		| Omit<RegisterOptions<TFieldValues, TFieldName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>,
) => {
	const overrides: Partial<typeof options> = {};

	if (typeof options.required === 'boolean') {
		overrides.required = {
			value: options.required,
			message: `${label} is required`,
		};
	}

	if (typeof options.minLength === 'number') {
		overrides.minLength = {
			value: options.minLength,
			message: `${label} should be at least ${options.minLength} characters`,
		};
	}

	if (typeof options.maxLength === 'number') {
		overrides.maxLength = {
			value: options.maxLength,
			message: `${label} should be no longer than ${options.maxLength} characters`,
		};
	}

	if (typeof options.min === 'number') {
		overrides.min = {
			value: options.min,
			message: `${label} must be more than ${options.min}`,
		};
	}

	if (typeof options.max === 'number') {
		overrides.max = {
			value: options.max,
			message: `${label} must be less than ${options.max}`,
		};
	}

	if (options.pattern instanceof RegExp) {
		overrides.pattern = {
			value: options.pattern,
			message: `${label} is not valid`,
		};
	}

	return { ...options, ...overrides };
};

export const inputStyle = (theme: Theme) => css`
	width: 100%;
	height: 100%;
	padding: 1em 0.8em 0;
	line-height: 1;
	color: ${theme.inputs.colors.primary};
	background-color: ${theme.inputs.colors.background};
	border: 1px solid ${theme.inputs.colors.border};
	border-radius: var(--border-radius-1);

	&.invalid {
		border-bottom: 2px solid ${theme.inputs.colors.error};
	}

	&:read-only {
		color: ${theme.inputs.colors.readonly};
		cursor: default;
		background-color: ${theme.inputs.colors.readonlyBackground};
	}

	&:-webkit-autofill {
		box-shadow: 0 0 0 99rem ${theme.inputs.colors.background} inset;
		caret-color: ${theme.inputs.colors.primary} !important;
		-webkit-text-fill-color: ${theme.inputs.colors.primary};
	}
`;

const TextInput = forwardRef<HTMLInputElement, Props>(function TextInput(props, ref) {
	const { inputProps, labelProps, cssExtra, children, indicator, errorMessage } = props;

	const [populated, setPopulated] = useState(!!inputProps?.defaultValue || !!inputProps?.value);

	const [focus, setFocus] = useState(!!inputProps?.autoFocus);

	let inputId = useId();
	if (inputProps?.id) inputId = inputProps.id;

	const theme = useTheme();

	const invalid = !!errorMessage;
	const inputClasses = [];
	if (inputProps?.className) inputClasses.push(inputProps.className);
	if (populated) inputClasses.push('populated');
	if (invalid) inputClasses.push('invalid');

	useEffect(() => {
		if (inputProps?.value) {
			setPopulated(true);
		}
	}, [inputProps]);

	return (
		<div
			css={css`
				text-rendering: optimizeLegibility;
			`}
		>
			<div
				css={css`
					position: relative;
					height: 2.75em;

					${cssExtra};
				`}
			>
				<input
					ref={ref}
					css={inputStyle(theme)}
					{...inputProps}
					className={inputClasses.join(' ')}
					id={inputId}
					onBlur={e => {
						inputProps?.onBlur?.(e);
						setFocus(false);
					}}
					onChange={e => {
						inputProps?.onChange?.(e);
						setPopulated(!!e.currentTarget.value.length);
					}}
					onFocus={e => {
						inputProps?.onFocus?.(e);
						setFocus(true);
					}}
				/>
				<StyledLabel
					active={focus || populated}
					invalid={invalid}
					readonly={inputProps?.readOnly}
					{...labelProps}
					htmlFor={inputId}
				/>
				{children}
			</div>
			{indicator}
			{invalid ? <ValidationMessageWrapper>{errorMessage}</ValidationMessageWrapper> : undefined}
		</div>
	);
});

export default TextInput;
