import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'section'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function ArticleIntroSection(props: Props) {
	const { cssExtra, children, ...delegatedAttributes } = props;
	return (
		<section
			css={css`
				padding: 3em 0 4.5em;
				margin: 3em 0;
				font-size: 1.1em;

				&:last-child {
					margin-bottom: 0;
				}

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			{children}
		</section>
	);
}
