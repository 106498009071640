import { css } from '@emotion/react';

import type { ReactNode } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props {
	tag: keyof JSX.IntrinsicElements;
	children: ReactNode;
	marginSpace: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function LineThrough(props: Props) {
	const { children, marginSpace, cssExtra } = props;

	return (
		<props.tag
			css={css`
				display: flex;
				flex-direction: row;
				cursor: default;

				&::before,
				&::after {
					flex: 1 1;
					margin: auto;
					content: '';
					border-bottom: 1px solid currentColor;
				}

				&::before {
					margin-right: ${marginSpace};
				}

				&::after {
					margin-left: ${marginSpace};
				}

				${cssExtra};
			`}
		>
			{children}
		</props.tag>
	);
}
