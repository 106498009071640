import { css } from '@emotion/react';

import AspectRatioBox from '../AspectRatioBox';

import type { ReactElement } from 'react';
import type { SerializedStyles } from '@emotion/react';
import type GameCard from './GameCard';

interface Props {
	children: ReactElement<typeof GameCard> | ReactElement<typeof GameCard>[];
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function GameCardsSet(props: Props) {
	let { children } = props;
	const { cssExtra } = props;

	children = Array.isArray(children) ? children : [children];

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				row-gap: 80px;
				justify-content: space-between;

				${cssExtra};
			`}
		>
			{children.map((child, index) => {
				return (
					<AspectRatioBox key={index} ratio={0.4}>
						{child}
					</AspectRatioBox>
				);
			})}
		</div>
	);
}
