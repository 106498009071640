import { css, ThemeProvider, useTheme } from '@emotion/react';

import { boxShadow } from '../../styles';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import type { SerializedStyles } from '@emotion/react';

export interface Props extends ComponentPropsWithoutRef<'div'> {
	gradientBorder?: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
	children: ReactNode;
}

export const textButtonStyle = css`
	font-weight: 700;
	line-height: 1.25;
	text-align: center;
	white-space: break-spaces;

	&:is(:link, :visited, :active) {
		color: var(--accent-color-b-alt-5);
	}

	&:hover {
		color: var(--accent-color-b);
	}
`;

export const rectButtonStyle = css`
	color: var(--primary-color);
	background-color: var(--muted-color-6);

	&:is(:link, :visited, :hover, :active) {
		color: var(--primary-color);
	}

	&:hover {
		background-color: var(--muted-color-5);
	}
`;

export default function Card(props: Props) {
	const { gradientBorder = true, cssExtra, children, ...delegatedProps } = props;

	const parentTheme = useTheme();
	const theme = {
		...parentTheme,
		inputs: {
			colors: {
				background: 'var(--muted-color-0)',
				primary: 'var(--background-color)',
				primaryAlt: 'var(--accent-color-b-alt-5)',
				border: 'var(--muted-color-6)',
				inactive: 'var(--muted-color-5)',
				active: 'var(--accent-color-b)', //
				activeStrong: 'var(--accent-color-b-alt-2)', //
				activeStronger: 'var(--accent-color-b-alt-3)',
				error: 'var(--accent-color-o-alt-0)',
				readonly: 'var(--primary-color)',
				readonlyBackground: 'var(--muted-color-4)',
			},
		},
	};

	return (
		<ThemeProvider theme={theme}>
			<div
				css={css`
					position: relative;
					overflow: hidden;
					color: var(--muted-color-6);
					background: var(--primary-color);
					border: 2px solid var(--primary-color);
					border-radius: var(--border-radius-0);

					${boxShadow(4)};

					&.gradient-border {
						overflow: visible;
						border: none;
						box-shadow: none;

						&:not(.modal-card-enter, .modal-card-appear)::before {
							--gradient-angle: 45deg;
						}

						&::before {
							--card-border-width: 4px;

							${boxShadow(4)};
							position: absolute;
							top: calc(-1 * var(--card-border-width));
							left: calc(-1 * var(--card-border-width));
							z-index: -1;
							width: calc(100% + 2 * var(--card-border-width));
							height: calc(100% + 2 * var(--card-border-width));
							content: '';
							background-image: linear-gradient(
								var(--gradient-angle),
								var(--accent-color-g),
								var(--accent-color-b)
							);
							border-radius: calc(var(--border-radius-0) + (var(--card-border-width) / 2));
						}
					}

					${cssExtra};
				`}
				{...delegatedProps}
				className={(() => {
					const classes = [delegatedProps.className ?? ''];
					if (gradientBorder) classes.push('gradient-border');
					return classes.join(' ');
				})()}
			>
				{children}
			</div>
		</ThemeProvider>
	);
}
