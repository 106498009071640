import { css } from '@emotion/react';

import { NotificationContext, useNotificationDispatch } from '../../../hooks/notification';

import type { ReactElement } from 'react';
import type Main from './Main';

interface Props {
	children: ReactElement<typeof Main>;
}

export default function MainContainer(props: Props) {
	const { children } = props;

	const notificationDispatch = useNotificationDispatch();

	return (
		<NotificationContext.Provider value={notificationDispatch}>
			<div
				css={css`
					position: relative;
					width: 100%;
					min-height: calc(100vh - var(--header-height) - var(--footer-height));
					margin-top: var(--header-height);
					background: var(--muted-color-6);
				`}
			>
				{children}
			</div>
		</NotificationContext.Provider>
	);
}
