import { useEffect, useRef } from 'react';

import { CSSTransition } from 'react-transition-group';
import { CacheProvider, css, ThemeProvider } from '@emotion/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

import Footer from './footer/Footer';
import Header from './header/Header';
import { UserContext, useUserDispatch } from '../../../hooks/user';
import MainContainer from './MainContainer';
import { useMeta } from '../../../hooks/dom';

import type { ReactElement } from 'react';
import type Main from './Main';

export interface Props {
	children: ReactElement<typeof Main>;
}

const theme = {
	inputs: {
		colors: {
			background: 'var(--muted-color-5)',
			primary: 'var(--primary-color)',
			primaryAlt: 'var(--primary-color)',
			border: 'var(--muted-color-0)',
			inactive: 'var(--muted-color-1)',
			active: 'var(--accent-color-b-alt-0)',
			activeStrong: 'var(--accent-color-b-alt-3)',
			activeStronger: 'var(--accent-color-b-alt-2)',
			error: 'var(--accent-color-o)',
			readonly: 'var(--muted-color-3)',
			readonlyBackground: 'var(--muted-color-5)',
		},
	},
};

function View(props: Props) {
	const fadeIn = useRef<boolean>(document.referrer.split('/')[2] !== window.location.hostname);

	const { children } = props;

	const userDispatch = useUserDispatch();
	const { fetchUser } = userDispatch;

	const meta = useMeta();

	const cacheRef = useRef(
		createCache({
			key: 'css',
			nonce: meta.current['daresay:style:key'] as string,
			stylisPlugins: [prefixer],
		}),
	);

	useEffect(() => {
		fadeIn.current = false;
	}, []);

	useEffect(() => {
		fetchUser().catch(() => {
			/* TODO: handle error */
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const content = (
		<div
			className={'slow'}
			css={css`
				display: flex;
				flex-direction: column;
				align-items: center;
				min-height: 100vh;
			`}
		>
			<Header />
			<MainContainer>{children}</MainContainer>
			<Footer />
		</div>
	);

	return (
		<GoogleReCaptchaProvider
			useEnterprise
			reCaptchaKey={meta.current['daresay:recaptcha:sitekey'] as string}
			scriptProps={{ async: true, defer: true }}
		>
			<UserContext.Provider value={userDispatch}>
				<CacheProvider value={cacheRef.current}>
					<ThemeProvider theme={theme}>
						<CSSTransition
							in
							addEndListener={(node, done) => {
								node.addEventListener('transitionend', done, false);
							}}
							appear={fadeIn.current}
							classNames={'fade'}
						>
							{content}
						</CSSTransition>
					</ThemeProvider>
				</CacheProvider>
			</UserContext.Provider>
		</GoogleReCaptchaProvider>
	);
}

export default View;
