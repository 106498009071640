import * as Sentry from '@sentry/react';

import { SentinelError } from '@daresay/api-client';

import AuthErrorCard, {
	AuthErrorContentWrapper,
	minorNoteTextStyle,
	noteTextStyle,
	parseErrorAuthData,
} from '../../../components/layout/card/AuthErrorCard';
import StyledNavLink, { Style } from '../../../components/buttons/core/StyledNavLink';
import { rectButtonStyle } from '../../../components/layout/card/Card';
import CardFooterNavLink from '../../../components/layout/card/CardFooterNavLink';

import type { RenderData } from '@daresay/api-client';

const render = (data: RenderData) => {
	const { error } = data;

	const mode = (() => {
		const auth = error ? parseErrorAuthData(error) : null;
		if (auth) {
			return auth.mode === 'login' || auth.mode === 'signup' || auth.mode === 'connect' ? auth.mode : undefined;
		}

		return undefined;
	})();

	const modeDisclaimer =
		mode === 'signup' ? (
			<p css={minorNoteTextStyle}>Attempt was switched from signup to login because we found your account</p>
		) : null;

	switch (error?.name) {
		case 'SentinelError.AuthError.LoginFailure.UserNotFound':
			return (
				<>
					<p>You don’t have an account with us yet.</p>
					<StyledNavLink cssExtra={rectButtonStyle} styleType={Style.RECT} to={'/signup'}>
						Sign up
					</StyledNavLink>
					{modeDisclaimer}
				</>
			);
		case 'SentinelError.AuthError.LoginFailure.InvalidCredentials':
			return (
				<>
					<p>Wrong password.</p>
					<StyledNavLink cssExtra={rectButtonStyle} styleType={Style.RECT} to={'/login'}>
						Back to login
					</StyledNavLink>
					{modeDisclaimer}
				</>
			);
		case 'SentinelError.AuthError.LoginFailure.InvalidCredentials.NotConfigured':
			return (
				<>
					<p>Your password hasn’t been set up yet.</p>
					<p css={noteTextStyle}>Please use another method to log in to your account.</p>
					<StyledNavLink cssExtra={rectButtonStyle} styleType={Style.RECT} to={'/login'}>
						Back to login
					</StyledNavLink>
					{modeDisclaimer}
				</>
			);
		default:
			Sentry.captureException(SentinelError.parse(error));
			return (
				<>
					<p>An unexpected error has occurred.</p>
					<StyledNavLink cssExtra={rectButtonStyle} styleType={Style.RECT} to={'/login'}>
						Back to login
					</StyledNavLink>
					{modeDisclaimer}
				</>
			);
	}
};

export default function LoginErrorCard() {
	return (
		<AuthErrorCard
			render={render}
			renderWrapper={children => (
				<AuthErrorContentWrapper
					footerChildren={
						<>
							<CardFooterNavLink to={'/login'}>Back to login</CardFooterNavLink>
							<CardFooterNavLink emphasis={'Sign up'} to={'/signup'}>
								Don’t have an account?
							</CardFooterNavLink>
						</>
					}
					title={'Login failed'}
				>
					{children}
				</AuthErrorContentWrapper>
			)}
		/>
	);
}
