import { forwardRef } from 'react';

import { useForwardedRef } from '@daresay/react-hooks';

import SwitchBox from './core/SwitchBox';

import type { ComponentPropsWithoutRef, ForwardedRef, ReactElement } from 'react';
import type { FieldPath, FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import type { SerializedStyles } from '@emotion/react';

interface Props<
	TFieldValues extends FieldValues = FieldValues,
	TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
	register: UseFormRegister<TFieldValues>;
	registerName: FieldPath<TFieldValues>;
	registerOptions?: RegisterOptions<TFieldValues, TFieldName>;
	inputProps?: ComponentPropsWithoutRef<'input'>;
	labelProps?: ComponentPropsWithoutRef<'label'>;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const RememberMe = forwardRef(function RememberMe<TFieldValues extends FieldValues = FieldValues>(
	props: Props<TFieldValues>,
	ref: ForwardedRef<HTMLInputElement>,
) {
	const { register, registerName, registerOptions, inputProps: _inputProps, labelProps, cssExtra } = props;

	const { ref: callbackRef, ...registerInputProps } = register(registerName, registerOptions);
	const inputProps = { ..._inputProps, ...registerInputProps };

	const innerRef = useForwardedRef([ref, callbackRef]);

	return (
		<SwitchBox
			ref={innerRef}
			cssExtra={cssExtra}
			inputProps={inputProps}
			labelProps={{ children: 'Remember me', ...labelProps }}
		/>
	);
}) as <TFieldValues extends FieldValues>(p: Props<TFieldValues>, ref?: ForwardedRef<HTMLInputElement>) => ReactElement;

export default RememberMe;
