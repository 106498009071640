import { css } from '@emotion/react';

import type { ReactElement } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props {
	header: string;
	children: ReactElement | ReactElement[];
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function AccountSection(props: Props) {
	const { header, children, cssExtra } = props;

	return (
		<div
			css={css`
				min-width: 200px;

				${cssExtra};
			`}
		>
			<h1
				css={css`
					margin-bottom: 1.5em;
				`}
			>
				{header}
			</h1>
			{children}
		</div>
	);
}
