import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';

import { getStyle } from './StyledButton';

import type { ReactNode } from 'react';
import type { NavLinkProps } from 'react-router-dom';
import type { SerializedStyles } from '@emotion/react';
import type { Style } from './StyledButton';

export { Style, getStyle } from './StyledButton';

export interface Props extends NavLinkProps {
	children: ReactNode;
	styleType?: Style;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function StyledNavLink(props: Props) {
	const { children, styleType, cssExtra, ...delegatedProps } = props;

	return (
		<NavLink
			css={css`
				${getStyle(styleType)};
				${cssExtra};
			`}
			{...delegatedProps}
		>
			{children}
		</NavLink>
	);
}
