import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

import SetMeta from '../../../components/utils/SetMeta';
import Main from '../../../components/layout/view/Main';
import CardContainer from '../../../components/layout/card/CardContainer';
import SignupErrorCard from './SignupErrorCard';

export default function SignupErrorPage() {
	return (
		<>
			<SetMeta nofollow noindex nosnippet title={'Sign up'} />
			<Main
				cssExtra={css`
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				`}
			>
				<CardContainer
					cssExtra={css`
						flex-grow: 1;
					`}
				>
					<CSSTransition
						appear
						in
						unmountOnExit
						addEndListener={(node, done) => {
							node.addEventListener('transitionend', done, false);
						}}
						classNames={'modal-card'}
					>
						<SignupErrorCard />
					</CSSTransition>
				</CardContainer>
			</Main>
		</>
	);
}
