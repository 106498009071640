import { useState } from 'react';

import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';

import { SentinelError } from '@daresay/api-client';

import AccountSection from '../AccountSection';
import AccountActionContainer from '../AccountActionContainer';
import AccountActionContainerButtonSet from '../AccountActionContainerButtonSet';
import ChangeEmailForm from '../../../components/input/forms/ChangeEmailForm';
import ChangePasswordForm from '../../../components/input/forms/ChangePasswordForm';
import AccountEmailDescription from '../AccountEmailDescription';
import CreatePasswordForm from '../../../components/input/forms/CreatePasswordForm';
import { useUser } from '../../../hooks/user';
import { NotificationContext, useNotificationDispatch } from '../../../hooks/notification';
import BoxNotification from '../../../components/layout/notification/BoxNotification';
import AccountActionContainerSubmit from '../AccountActionContainerSubmit';

export enum OpenState {
	NONE,
	EMAIL,
	PASSWORD,
}

const formStyle = css`
	display: flex;
	flex-direction: column;
	row-gap: 1em;
	align-self: stretch;
	max-width: 320px;
	margin: 3em 0;
	font-size: 0.9em;
`;

const parseEmailUpdateError = (error: Error) => {
	if (!(error instanceof SentinelError)) {
		Sentry.captureException(error);
		return undefined;
	}

	switch (error.name) {
		case 'UserError.IdentityExists':
			return 'Email is already in use by another account';
		default:
			Sentry.captureException(error);
			return undefined;
	}
};

const parsePasswordUpdateError = (error: Error) => {
	if (!(error instanceof SentinelError)) {
		Sentry.captureException(error);
		return undefined;
	}

	switch (error.name) {
		case 'UserError.InvalidCredentials':
			return 'Wrong password';
		default:
			Sentry.captureException(error);
			return undefined;
	}
};

export default function AccountOverview() {
	const { user } = useUser();

	if (!user) throw new Error('User is not available');

	const { email, emailUpdate, verified } = user.auth.daresay;
	const { configured: passwordConfigured } = user.auth.daresay.password;
	const emailChange = !!emailUpdate.email && emailUpdate.email !== email;

	// eslint-disable-next-line react/hook-use-state
	const [openedState, _setOpenedState] = useState<OpenState>(OpenState.NONE);

	const notification = useNotificationDispatch();
	const emailNotification = useNotificationDispatch();
	const passwordNotification = useNotificationDispatch();

	const [emailInProgress, setEmailInProgress] = useState(false);
	const [passwordInProgress, setPasswordInProgress] = useState(false);

	const setOpenedState = (state: OpenState) => {
		switch (state) {
			case OpenState.NONE:
				if (openedState === OpenState.NONE) return;
				emailNotification.removeNotification();
				passwordNotification.removeNotification();
				break;
			case OpenState.EMAIL:
				if (openedState === OpenState.EMAIL) return;
				passwordNotification.removeNotification();
				break;
			case OpenState.PASSWORD:
				if (openedState === OpenState.PASSWORD) return;
				emailNotification.removeNotification();
				break;
			default: {
				const exhaustiveCheck: never = state;
				throw new Error(exhaustiveCheck);
			}
		}

		_setOpenedState(state);
	};

	return (
		<NotificationContext.Provider value={notification}>
			<BoxNotification
				cssExtra={css`
					margin-bottom: 60px;

					& > div:first-of-type {
						min-height: 60px;
					}
				`}
				displayEmpty={false}
			/>
			<AccountSection
				cssExtra={css`
					& > div:not(:last-child) {
						border-bottom: 1px solid var(--muted-color-5);
					}

					@media (max-width: 500px) {
						& > .action-container {
							flex-direction: column;
							column-gap: 1.5em;
							align-items: flex-start;

							& > div:first-of-type {
								align-self: stretch;
							}
						}
					}
				`}
				header={'Account Overview'}
			>
				<AccountActionContainer
					content={
						openedState === OpenState.EMAIL ? (
							<NotificationContext.Provider value={emailNotification}>
								<ChangeEmailForm
									cssExtra={formStyle}
									renderSubmitButton={button => (
										<AccountActionContainerSubmit inProgress={emailInProgress}>
											{button}
										</AccountActionContainerSubmit>
									)}
									onSubmitError={error => {
										setEmailInProgress(false);

										emailNotification.setNotification({
											message: parseEmailUpdateError(error) ?? 'An unexpected error has occurred',
											type: 'error',
										});
									}}
									onSubmitStart={() => {
										setEmailInProgress(true);
										notification.removeNotification();
										emailNotification.removeNotification();
									}}
									onSubmitSuccess={data => {
										setEmailInProgress(false);
										setOpenedState(OpenState.NONE);
										notification.setNotification({
											message: `We sent details to ${data.email} to confirm your updated email`,
											type: 'success',
										});
									}}
								/>
							</NotificationContext.Provider>
						) : undefined
					}
					description={
						openedState === OpenState.EMAIL ? (
							"Update your email (we'll send verification details to this new email)"
						) : (
							<>
								<AccountEmailDescription email={email} verified={emailChange ? undefined : verified} />
								{emailChange ? (
									<AccountEmailDescription
										email={emailUpdate.email}
										isCurrent={false}
										verified={false}
									/>
								) : null}
							</>
						)
					}
					label={'Email'}
				>
					<AccountActionContainerButtonSet
						cancelButtonProps={{
							onClick: () => {
								setOpenedState(OpenState.NONE);
								emailNotification.removeNotification();
							},
						}}
						open={openedState === OpenState.EMAIL}
						openButtonProps={{
							onClick: () => {
								setOpenedState(OpenState.EMAIL);
							},
						}}
					/>
				</AccountActionContainer>
				<AccountActionContainer
					content={(() => {
						if (openedState !== OpenState.PASSWORD) return undefined;

						return (
							<NotificationContext.Provider value={passwordNotification}>
								{passwordConfigured ? (
									<ChangePasswordForm
										cssExtra={formStyle}
										renderSubmitButton={button => (
											<AccountActionContainerSubmit inProgress={passwordInProgress}>
												{button}
											</AccountActionContainerSubmit>
										)}
										onSubmitError={error => {
											setPasswordInProgress(false);
											passwordNotification.setNotification({
												type: 'error',
												message:
													parsePasswordUpdateError(error) ??
													'An unexpected error has occurred',
											});
										}}
										onSubmitStart={() => {
											setPasswordInProgress(true);
											notification.removeNotification();
											passwordNotification.removeNotification();
										}}
										onSubmitSuccess={() => {
											setPasswordInProgress(false);
											setOpenedState(OpenState.NONE);
											notification.setNotification({
												type: 'success',
												message: 'Your password has been updated',
											});
										}}
									/>
								) : (
									<CreatePasswordForm
										cssExtra={formStyle}
										renderSubmitButton={button => (
											<AccountActionContainerSubmit inProgress={passwordInProgress}>
												{button}
											</AccountActionContainerSubmit>
										)}
										onSubmitError={error => {
											setPasswordInProgress(false);
											passwordNotification.setNotification({
												type: 'error',
												message:
													parsePasswordUpdateError(error) ??
													'An unexpected error has occurred',
											});
										}}
										onSubmitStart={() => {
											setPasswordInProgress(true);
											notification.removeNotification();
											passwordNotification.removeNotification();
										}}
										onSubmitSuccess={() => {
											setPasswordInProgress(false);
											setOpenedState(OpenState.NONE);
											notification.setNotification({
												type: 'success',
												message: 'Your password has been created',
											});
										}}
									/>
								)}
							</NotificationContext.Provider>
						);
					})()}
					description={passwordConfigured ? undefined : 'Create a password to secure your account'}
					label={'Password'}
				>
					<AccountActionContainerButtonSet
						cancelButtonProps={{
							onClick: () => {
								setOpenedState(OpenState.NONE);
								passwordNotification.removeNotification();
							},
						}}
						open={openedState === OpenState.PASSWORD}
						openButtonProps={{
							onClick: () => {
								setOpenedState(OpenState.PASSWORD);
							},
							children: passwordConfigured ? 'Change' : 'Create',
						}}
					/>
				</AccountActionContainer>
			</AccountSection>
		</NotificationContext.Provider>
	);
}
