import { css } from '@emotion/react';

import DaresayLogo, { DaresayLogoType } from '../../graphics/vectors/DaresayLogo';

import type { Props } from '../../graphics/vectors/DaresayLogo';

export default function CardDaresayLogo(props: Partial<Props>) {
	const { cssExtra, height, type, ...delegatedProps } = props;
	return (
		<DaresayLogo
			cssExtra={css`
				margin-bottom: 12px;

				& [fill]:not([fill^='url']) {
					fill: currentColor;
				}

				${cssExtra};
			`}
			height={height ?? '36px'}
			type={type ?? DaresayLogoType.WORDMARK_FULL}
			{...delegatedProps}
		/>
	);
}
