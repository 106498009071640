import { css } from '@emotion/react';

import GameOnGraphic from '../../../public/images/ui/game-on.svg';
import DotPattern1Graphic from '../../../public/images/ui/dot-pattern-1.svg';

export default function HeroBanner() {
	return (
		<div
			css={css`
				display: flex;
				column-gap: max(0px, 120px - 6vw);
				justify-content: space-between;
				height: min(100vh, 700px);
				padding: 0 var(--padding-h-alt);
				background-color: var(--accent-color-b);

				@media (max-width: 1500px) {
					flex-direction: column;
					row-gap: max(60px, 10vh);
				}
			`}
		>
			<div
				css={css`
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					padding-bottom: 40px;

					@media (max-width: 1500px) {
						padding-top: 120px;
						padding-bottom: 0;
					}
				`}
			>
				<span
					css={css`
						padding-bottom: 0.25em;
						font-family: 'Brandon Grotesque';
						font-size: clamp(88px, 8vw, 120px);
						font-weight: 700;
						line-height: 1;
						color: var(--accent-color-g);
						text-transform: uppercase;
						user-select: none;

						@media (max-width: 500px) {
							font-size: max(1.1em, 16vw);
						}
					`}
				>
					Game on
				</span>
				<span
					css={css`
						font-size: 1.1em;
						font-weight: 800;
					`}
				>
					Daresay is an independent game studio. Our focus is on creating fun multiplayer and
					community-oriented games.
				</span>
				<DotPattern1Graphic
					css={css`
						height: 16px;
						margin: 24px 0 0 2px;
						fill: var(--accent-color-g);
					`}
				/>
			</div>
			<GameOnGraphic
				css={css`
					align-self: flex-end;
					max-height: 360px;
					margin: 0 0 0 auto;
				`}
			/>
		</div>
	);
}
