import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'hr'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function CardHorizontalRule(props: Props) {
	const { cssExtra, ...delegatedAttributes } = props;
	return (
		<hr
			css={css`
				margin: 8px 0;
				border-bottom: 1px solid currentColor;

				${cssExtra};
			`}
			{...delegatedAttributes}
		/>
	);
}
