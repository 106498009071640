import { useRef, useState } from 'react';

import { css, keyframes } from '@emotion/react';

import { useIntersectionObserver } from '../../../hooks/dom';

import type { SerializedStyles } from '@emotion/react';

const fadeKeyframes = keyframes`
	from {
	    opacity: 0;
	}
  
  	to {
	  	opacity: 1;
	}
`;

const expandKeyframes = keyframes`
	to {
	  	height: calc(var(--animation-dot-size) / 2);
		width: calc(var(--animation-expand-ratio) * var(--animation-width));
	}
`;

const collapseKeyframes = keyframes`
	to {
		height: var(--animation-dot-size);
		width: var(--animation-dot-size);
	}
`;

const ltr0Keyframes = keyframes`
	to {
		transform: translateX(calc(var(--animation-move-ratio-0) * var(--animation-width)));
	}
`;

const ltr1Keyframes = keyframes`
	to {
		transform: translateX(calc(var(--animation-move-ratio-1) * var(--animation-width)));
	}
`;

const rtl0Keyframes = keyframes`
	to {
		transform: translateX(calc(-1 * var(--animation-move-ratio-0) * var(--animation-width)));
	}
`;

const rtl1Keyframes = keyframes`
	to {
		transform: translateX(calc(-1 * var(--animation-move-ratio-1) * var(--animation-width)));
	}
`;

const toXKeyframes = keyframes`
	to {
		clip-path: polygon(
			20% 0%,
			0% 20%,
			30% 50%,
			0% 80%,
			20% 100%,
			50% 70%,
			80% 100%,
			100% 80%,
			70% 50%,
			100% 20%,
			80% 0%,
			50% 30%
		);
		border-radius: 0;
`;

const toTriangleKeyframes = keyframes`
	to {
		clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
		border-radius: 0;
	}
`;

const toSquareKeyframes = keyframes`
	to {
		clip-path: polygon(5% 5%, 95% 5%, 95% 95%, 5% 95%);
		border-radius: 0;
	}
`;

const shapeKeyframePool = [toXKeyframes, toTriangleKeyframes, toSquareKeyframes];

const getRandomShapeKeyframes = (n = 2) => {
	const bucket = [...shapeKeyframePool];

	const getRandomFromBucket = () => {
		const i = Math.floor(Math.random() * bucket.length);
		return bucket.splice(i, 1)[0];
	};

	return Array.from({ length: n }, () => getRandomFromBucket());
};

interface Props {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function LDRAnimation(props: Props) {
	const { cssExtra } = props;

	const [visible, setVisible] = useState(false);

	const ref = useRef<HTMLDivElement>(null);
	const shapeKeyframesRef = useRef(getRandomShapeKeyframes());

	useIntersectionObserver<HTMLDivElement>(([entry]) => {
		if (entry.isIntersecting) {
			setVisible(true);
		}
	}, ref);

	return (
		<div
			ref={ref}
			className={visible ? 'visible' : undefined}
			css={[
				css`
					--animation-width: 80em;
					--animation-ease-fn: ease;
					--animation-move-duration-0: 1s;
					--animation-move-duration-1: 0.75s;
					--animation-move-ratio-0: 0.25;
					--animation-move-ratio-1: 0.5325;
					--animation-expand-ratio: 0.1;
					--animation-dot-size: 1em;
					position: relative;

					width: var(--animation-width);
					height: 100%;
					overflow: hidden;

					&.visible div {
						display: block;
					}

					& div {
						position: absolute;
						top: calc(50% - var(--animation-dot-size) / 2);
						display: none;
						width: var(--animation-dot-size);
						height: var(--animation-dot-size);
						background-color: #ffffff;

						&:nth-of-type(1),
						&:nth-of-type(3) {
							border-radius: calc(0.3 * var(--animation-dot-size));
						}

						&:nth-of-type(1) {
							left: 0;
							animation: ${fadeKeyframes} calc(4 * var(--ease-time)) var(--ease-fn) both,
								${expandKeyframes} var(--animation-move-duration-0) ease forwards,
								${ltr0Keyframes} var(--animation-move-duration-0) ease forwards,
								${ltr1Keyframes} var(--animation-move-duration-1) var(--animation-ease-fn) forwards
									calc(1.05 * var(--animation-move-duration-0)),
								${collapseKeyframes} var(--animation-move-duration-1) var(--animation-ease-fn) forwards
									calc(1.05 * var(--animation-move-duration-0)),
								${shapeKeyframesRef.current[0]} 0s forwards
									calc(
										1.05 * var(--animation-move-duration-0) + 0.55 *
											var(--animation-move-duration-1)
									);
						}

						&:nth-of-type(2) {
							right: 0;
							left: 0;
							margin-right: auto;
							margin-left: auto;
							border-radius: var(--animation-dot-size);
							animation: ${fadeKeyframes} calc(4 * var(--ease-time)) var(--ease-fn) both;
						}

						&:nth-of-type(3) {
							right: 0;
							animation: ${fadeKeyframes} calc(4 * var(--ease-time)) var(--ease-fn) both,
								${expandKeyframes} var(--animation-move-duration-0) var(--animation-ease-fn) forwards,
								${rtl0Keyframes} var(--animation-move-duration-0) var(--animation-ease-fn) forwards,
								${rtl1Keyframes} var(--animation-move-duration-1) var(--animation-ease-fn) forwards
									calc(1.05 * var(--animation-move-duration-0)),
								${collapseKeyframes} var(--animation-move-duration-1) var(--animation-ease-fn) forwards
									calc(1.05 * var(--animation-move-duration-0)),
								${shapeKeyframesRef.current[1]} 0s forwards
									calc(
										1.05 * var(--animation-move-duration-0) + 0.55 *
											var(--animation-move-duration-1)
									);
						}
					}
				`,
				cssExtra,
			]}
		>
			<div />
			<div />
			<div />
		</div>
	);
}
