import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';

import { aStyle } from './DistressedUnderlineAnchor';

import type { NavLinkProps } from 'react-router-dom';
import type { SerializedStyles } from '@emotion/react';

interface Props extends NavLinkProps {
	children: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function DistressedUnderlineLink(props: Props) {
	const { children, cssExtra, ...delegatedProps } = props;
	return (
		<NavLink
			css={css`
				${aStyle()}
				${cssExtra};
			`}
			rel={'noopener'}
			role={'link'}
			{...delegatedProps}
		>
			{children}
		</NavLink>
	);
}
