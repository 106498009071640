import { css } from '@emotion/react';

import Card from './Card';
import CardPanel from './CardPanel';

import type { ReactNode } from 'react';
import type { SerializedStyles } from '@emotion/react';
import type { Props as CardProps } from './Card';

interface Props {
	panel: ReactNode;
	children: ReactNode;
	cssExtra?: SerializedStyles | SerializedStyles[];
	cardProps?: Omit<CardProps, 'children' | 'cssExtra'>;
}

export default function DiptychCard(props: Props) {
	const { panel, cssExtra, children, cardProps } = props;

	return (
		<Card
			{...cardProps}
			cssExtra={css`
				display: flex;

				${cssExtra};
			`}
		>
			<div
				css={css`
					display: flex;
					overflow: hidden;
					border-radius: var(--border-radius-1) 0 0 var(--border-radius-1);
				`}
			>
				{panel}
			</div>
			<CardPanel>{children}</CardPanel>
		</Card>
	);
}
