import { css } from '@emotion/react';

import { isHrefExternal } from '../../../../common/helpers';
import { getStyle } from './StyledButton';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import type { Style } from './StyledButton';
import type { SerializedStyles } from '@emotion/react';

export { Style, getStyle } from './StyledButton';

export interface Props extends ComponentPropsWithoutRef<'a'> {
	children: ReactNode;
	styleType?: Style;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function StyledAnchor(props: Props) {
	const { children, styleType, cssExtra, href, ...delegatedAttributes } = props;

	return (
		<a
			css={css`
				${getStyle(styleType)};
				${cssExtra};
			`}
			href={href}
			rel={'noopener noreferrer'}
			role={'link'}
			target={isHrefExternal(href) ? '_blank' : undefined}
			{...delegatedAttributes}
		>
			{children}
		</a>
	);
}
