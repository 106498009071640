import { useId } from 'react';

import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { SentinelError } from '@daresay/api-client';

import StyledButton, { Style } from '../../buttons/core/StyledButton';
import TextEmailInput from '../TextEmailInput';
import { apiClient } from '../../../../common/api-client';

import type { ComponentPropsWithoutRef, BaseSyntheticEvent } from 'react';
import type { SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import type { SerializedStyles } from '@emotion/react';

interface FormValues {
	email: string;
}

interface Props extends Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
	onSubmitInvalid?: SubmitErrorHandler<FormValues>;
	onSubmitStart?: SubmitHandler<FormValues>;
	onSubmitError?: (error: Error, formData: FormValues, event: BaseSyntheticEvent | undefined) => void;
	onSubmitSuccess?: SubmitHandler<FormValues>;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function ResetPasswordRequestForm(props: Props) {
	const { onSubmitInvalid, onSubmitStart, onSubmitError, onSubmitSuccess, cssExtra, ...delegatedProps } = props;

	let id = useId();
	if (delegatedProps.id) id = delegatedProps.id;

	const { handleSubmit, control } = useForm<FormValues>();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const onSubmit: SubmitHandler<FormValues> = (formData, e) => {
		e?.preventDefault();

		onSubmitStart?.(formData, e);

		(async () => {
			if (!executeRecaptcha) throw new SentinelError('Unexpected error', '');
			const recaptchaToken = await executeRecaptcha('RESET_PASSWORD_REQUEST');

			return apiClient.post('/api/users/~/actions/send-reset-password', { ...formData, recaptchaToken });
		})()
			.then(() => {
				onSubmitSuccess?.(formData, e);
			})
			.catch((error: Error) => {
				onSubmitError?.(error, formData, e);
			});
	};

	return (
		<>
			<form
				noValidate
				css={css`
					display: flex;
					flex-direction: column;
					row-gap: inherit;
					align-items: stretch;
					justify-content: space-between;

					${cssExtra};
				`}
				{...delegatedProps}
				id={id}
				onSubmit={e => {
					handleSubmit(
						onSubmit,
						onSubmitInvalid,
					)(e).catch(() => {
						/* ignore */
					});
				}}
			>
				<TextEmailInput controllerProps={{ name: 'email', control, defaultValue: '' }} />
			</form>
			<StyledButton
				cssExtra={css`
					align-self: center;
					min-width: 128px;
					padding: 0.5em 2.25em 0.45em;
					margin-top: 10px;
					border-radius: 99rem;
				`}
				form={id}
				styleType={Style.RECT}
				type={'submit'}
			>
				Send
			</StyledButton>
		</>
	);
}
