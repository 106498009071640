import { css } from '@emotion/react';

import type { ReactElement } from 'react';

interface Props {
	header: string;
	children: ReactElement | ReactElement[];
}

export default function AccountSubsection(props: Props) {
	const { header, children } = props;

	return (
		<div
			css={css`
				margin: 1.5em 0;
			`}
		>
			<h2
				css={css`
					margin-bottom: 0.25em;
					font-size: 1.25em;
				`}
			>
				{header}
			</h2>
			{children}
		</div>
	);
}
