import { useId } from 'react';

import type { ComponentPropsWithoutRef } from 'react';

export default function SteamLogo(props: ComponentPropsWithoutRef<'svg'>) {
	const defsId = useId();

	return (
		<svg viewBox={'0 0 160 160'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
			<defs>
				<linearGradient gradientUnits={'userSpaceOnUse'} id={defsId} x1={'50%'} x2={'50%'} y2={'100%'}>
					<stop offset={'0'} stopColor={'#101c2f'} />
					<stop offset={'1'} stopColor={'#1384B6'} />
				</linearGradient>
			</defs>
			<path
				d={
					'M91.2,59.3c0-8.3,6.8-15.1,15.1-15.1c0,0,0,0,0,0c8.3,0,15.1,6.8,15.1,15.1s-6.8,15.1-15.1,15.1S91.2,67.7,91.2,59.3z M3.1,101.9c-1.4-5-2.4-10.1-2.8-15.5C0,83.2,0,80.3,0,80c0-2.4,0.1-4.5,0.3-6.4C14.5,79.5,28.8,85.4,43,91.3c3.8-2.6,8.2-3.9,12.7-3.9c0.4,0,0.8,0,1.3,0l19.1-27.6c0-0.1,0-0.3,0-0.4c0-16.7,13.5-30.2,30.2-30.2c16.7,0,30.2,13.5,30.2,30.2c0,16.7-13.5,30.2-30.2,30.2c-0.2,0-0.5,0-0.7,0l-27.2,19.4c0,0.4,0,0.7,0,1.1c0,10.8-7.6,20.1-18.2,22.2c-12.3,2.5-24.2-5.5-26.7-17.7L3.1,101.9z M86.2,59.4c0,11.1,9,20.1,20.1,20.1s20.1-9,20.1-20.1h0c0-11.1-9-20.1-20.1-20.1C95.2,39.3,86.2,48.3,86.2,59.4z M40.4,117.3c4.1,8.5,14.2,12,22.7,8c8.5-4.1,12-14.2,8-22.7c-3.8-8-13.1-11.7-21.3-8.5l10.2,4.2c6.4,2.6,9.4,10,6.8,16.3c-2.6,6.4-10,9.4-16.3,6.8c0,0-0.1,0-0.1,0L40.4,117.3z'
				}
				fill={'#ffffff'}
				opacity={'0'}
			/>
			<path
				d={
					'M126.4,59.4L126.4,59.4L126.4,59.4c0,11.1-9,20.1-20.1,20.1s-20.1-9-20.1-20.1c0-11.1,9-20.1,20.1-20.1C117.4,39.3,126.3,48.3,126.4,59.4z M121.4,59.4c0-8.3-6.8-15.1-15.1-15.1h0c0,0,0,0,0,0h0c-8.3,0-15.1,6.8-15.1,15.1c0,8.3,6.8,15.1,15.1,15.1h0l0,0C114.6,74.5,121.4,67.7,121.4,59.4z M40.4,117.3c3,6.3,9.5,9.9,16.1,9.6c2.2-0.1,4.4-0.6,6.5-1.7c8.5-4.1,12-14.2,8-22.7c-2.9-6-8.8-9.6-15.1-9.6c-2.1,0-4.2,0.3-6.3,1.1l10.2,4.2c6.4,2.6,9.4,10,6.8,16.3c-2,4.8-6.6,7.7-11.5,7.7c-1.6,0-3.3-0.3-4.9-0.9c0,0-0.1,0-0.1,0L40.4,117.3z M0.3,73.6L0.3,73.6L0.3,73.6L0.3,73.6L0.3,73.6z M80,0C38,0,3.5,32.4,0.3,73.6L43,91.3c3.8-2.6,8.2-3.9,12.7-3.9h0l0,0c0.4,0,0.8,0,1.3,0l19.1-27.6c0-0.1,0-0.3,0-0.4c0-16.7,13.5-30.2,30.2-30.2l0,0h0c16.7,0,30.2,13.5,30.2,30.2c0,16.7-13.5,30.2-30.2,30.2h0l0,0c-0.2,0-0.5,0-0.7,0l-27.2,19.4c0,0.4,0,0.7,0,1.1c0,10.8-7.6,20.1-18.2,22.2c-1.5,0.3-3.1,0.5-4.6,0.4c-10.5,0-19.9-7.5-22.1-18.2L3.1,101.9C12.6,135.4,43.4,160,80,160c44.2,0,80-35.8,80-80C160,35.8,124.2,0,80,0z'
				}
				fill={`url(#${defsId})`}
			/>
		</svg>
	);
}
