import { useScrollOnMount } from '@daresay/react-hooks';

import Main from '../components/layout/view/Main';
import SetMeta from '../components/utils/SetMeta';
import HeroBanner from './HeroBanner';
import GamesBanner from './GamesBanner';
import BannerDivider from './BannerDivider';

export default function HomePage() {
	useScrollOnMount();

	return (
		<>
			<SetMeta
				description={'Daresay is a gaming studio focused on creating fun multiplayer games.'}
				title={'Daresay Games'}
			/>
			<Main>
				<HeroBanner />
				<BannerDivider />
				<GamesBanner />
			</Main>
		</>
	);
}
