import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	description?: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function CardMainSection(props: Props) {
	const { description, children, cssExtra, ...delegatedProps } = props;

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				row-gap: 20px;
				align-self: stretch;
				margin: 10px 0;

				${cssExtra}
			`}
			{...delegatedProps}
		>
			{description ? (
				<span
					css={css`
						margin-bottom: 10px;
						text-align: center;
					`}
				>
					{description}
				</span>
			) : undefined}
			{children}
		</div>
	);
}
