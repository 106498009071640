import { useEffect, useState } from 'react';

import { css } from '@emotion/react';

import DiptychCard from './DiptychCard';
import CardDaresayLogo from './CardDaresayLogo';
import LineThrough from '../../graphics/LineThrough';
import AuthButtonSet from '../AuthButtonSet';
import DotPattern0Graphic from '../../../../../public/images/ui/dot-pattern-0.svg';
import GameOnGraphic from '../../../../../public/images/ui/game-on-mobile.svg';
import CardHeading from './CardHeading';
import CardFooter from './CardFooter';
import CardMain from './CardMain';
import CardMainSection from './CardMainSection';

import type CardFooterNavLink from './CardFooterNavLink';
import type { ReactElement } from 'react';

interface Props {
	panelConfig: { backgroundColor: string; accentColor: string; text: string };
	gradientColors?: string[];
	welcomeText: string;
	authMode: 'login' | 'signup';
	authButtonPrefix: string;
	alternativeLink: ReactElement<typeof CardFooterNavLink>;
	disclaimer: ReactElement;
	form: ReactElement;
	remember?: boolean;
}

const MINIFY_WIDTH = 900;

const shouldMinify = () => window.innerWidth < MINIFY_WIDTH;

export default function AuthCard(props: Props) {
	const {
		panelConfig,
		gradientColors,
		welcomeText,
		authMode,
		authButtonPrefix,
		alternativeLink,
		disclaimer,
		form,
		remember,
	} = props;

	const [minify, setMinify] = useState(shouldMinify());

	const onResize = () => {
		setMinify(shouldMinify());
	};

	useEffect(() => {
		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const authReturnUrl = (strategy: string) => {
		const params = new URLSearchParams(window.location.search);

		const redirectUri = params.get('redirect_uri');
		if (!redirectUri?.length) params.set('redirect_uri', '/');

		params.set('strategy', strategy);

		return `/${authMode}/success?${params.toString()}`;
	};

	const authFailureUrl = `/${authMode}/error`;

	const gradientStyle = gradientColors
		? css`
				&.minify.gradient-border {
					&::before {
						background-image: linear-gradient(var(--gradient-angle), ${gradientColors.join(',')});
					}
				}
		  `
		: undefined;

	return (
		<DiptychCard
			cardProps={{ gradientBorder: !!minify, className: minify ? 'minify' : undefined }}
			cssExtra={css`
				flex-grow: 0.15;

				${gradientStyle}
			`}
			panel={
				<div
					css={css`
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						width: 400px;
						color: var(--primary-color);
						background: ${panelConfig.backgroundColor};

						@media (max-width: ${MINIFY_WIDTH}px) {
							display: none;
						}
					`}
				>
					<div
						css={css`
							display: flex;
							column-gap: 20px;
							align-items: center;
							margin: 80px 0 0 60px;
						`}
					>
						<DotPattern0Graphic
							css={css`
								height: 40px;
								fill: ${panelConfig.accentColor};
							`}
						/>
						<span
							css={css`
								font-family: 'Brandon Grotesque';
								font-size: 4em;
								font-weight: 900;
								text-transform: uppercase;
								cursor: default;
								user-select: none;
							`}
						>
							{panelConfig.text}
						</span>
					</div>
					<GameOnGraphic
						css={css`
							margin: 0 60px;

							& .hand {
								fill: ${panelConfig.accentColor};
							}
						`}
					/>
				</div>
			}
		>
			<CardDaresayLogo />
			<CardMain>
				<CardHeading>{welcomeText}</CardHeading>
				<CardMainSection>{form}</CardMainSection>
				<LineThrough
					cssExtra={css`
						align-self: stretch;

						&::before,
						&::after {
							color: var(--muted-color-4);
						}
					`}
					marginSpace={'20px'}
					tag={'div'}
				>
					or
				</LineThrough>
				<AuthButtonSet
					cssExtra={css`
						align-self: stretch;
						margin-bottom: 20px;
					`}
					failureRedirectUri={authFailureUrl}
					mode={authMode}
					prefix={authButtonPrefix}
					redirectUri={authReturnUrl}
					remember={remember}
				/>
			</CardMain>
			<CardFooter>
				{alternativeLink}
				{disclaimer}
			</CardFooter>
		</DiptychCard>
	);
}
