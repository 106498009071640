import { useEffect } from 'react';

import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import useRenderDataFromParams from '../../../hooks/render-data';
import Card from './Card';
import CardDaresayLogo from './CardDaresayLogo';
import PolicyLinkSet from '../PolicyLinkSet';
import ErrorGraphic from '../../../../../public/images/ui/error.svg';
import CardFooter from './CardFooter';
import CardFooterSection from './CardFooterSection';
import CardPanel from './CardPanel';
import CardMain from './CardMain';

import type { ReactElement, ReactNode } from 'react';
import type { RenderData } from '@daresay/api-client';
import type { ParseableSentinelError } from '@daresay/api-client/build/errors/sentinel-error';

interface Props {
	render: (data: RenderData) => ReactNode;
	renderWrapper?: (children: ReactNode) => ReactElement;
	returnTo?: string;
}

export const parseErrorAuthData = (error: ParseableSentinelError) => {
	if (error.data?.auth === undefined) {
		return {};
	}

	if (typeof error.data.auth === 'object' && !Array.isArray(error.data.auth) && error.data.auth !== null) {
		return error.data.auth as Record<string, unknown>;
	}

	return null;
};

export const noteTextStyle = css`
	font-size: 0.95em;
	font-weight: 400;
	color: var(--muted-color-4);
`;

export const minorNoteTextStyle = css`
	${noteTextStyle};

	margin-top: 0.5em;
	font-size: 0.85em;
	font-style: italic;

	&::before {
		content: '*';
	}
`;

export function AuthErrorContentWrapper(props: { title: string; children: ReactNode; footerChildren: ReactNode }) {
	const { title, children, footerChildren } = props;

	return (
		<CardPanel>
			<CardDaresayLogo />
			<CardMain>
				<div
					css={css`
						display: flex;
						flex-direction: column;
						row-gap: 12px;
						align-items: center;
					`}
				>
					<span
						css={css`
							font-size: 1.2em;
							font-weight: 700;
							text-align: center;
							text-transform: capitalize;
							cursor: default;
						`}
					>
						{title}
					</span>
					<ErrorGraphic
						css={css`
							height: 1.5em;
							color: var(--accent-color-r);
						`}
					/>
				</div>
				<div
					css={css`
						display: flex;
						flex-direction: column;
						row-gap: 16px;
						align-items: center;
						align-self: stretch;
						justify-content: center;
						min-height: 60px;
						padding: 2.25em 0.5em;
						margin: 20px 0 40px;
						font-weight: 700;
						text-align: center;
						border-top: 1px solid currentColor;
						border-bottom: 1px solid currentColor;
					`}
				>
					{children}
				</div>
			</CardMain>

			<CardFooter>
				<CardFooterSection>{footerChildren}</CardFooterSection>
				<PolicyLinkSet />
			</CardFooter>
		</CardPanel>
	);
}

export default function AuthErrorCard(props: Props) {
	const { render, renderWrapper = children => <div>{children}</div>, returnTo = '..' } = props;

	const renderData = useRenderDataFromParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (renderData !== null) {
			const { error } = renderData;
			if (!error) navigate(returnTo, { replace: true });
		}
	}, [renderData, navigate, returnTo]);

	return (
		<Card
			cssExtra={css`
				display: flex;
				flex-grow: 0.15;
			`}
		>
			{renderData ? renderWrapper(render(renderData)) : null}
		</Card>
	);
}
