import { css } from '@emotion/react';

import InstagramIcon from './InstagramLogo';
import DiscordIcon from './DiscordLogo';
import SteamIcon from './SteamLogo';
import TwitterIcon from '../../../../../../public/images/social/twitter.svg';
import GoogleIcon from '../../../../../../public/images/social/google.svg';
import RedditIcon from '../../../../../../public/images/social/reddit.svg';
import TwitchIcon from '../../../../../../public/images/social/twitch.svg';
import AppleIcon from '../../../../../../public/images/social/apple.svg';
import FacebookIcon from '../../../../../../public/images/social/facebook.svg';

import type { SerializedStyles } from '@emotion/react';

export interface Props {
	type: SocialLogoType;
	height?: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export enum SocialLogoType {
	INSTAGRAM,
	TWITTER,
	DISCORD,
	GOOGLE,
	REDDIT,
	TWITCH,
	STEAM,
	APPLE,
	FACEBOOK,
}

export default function SocialLogo(props: Props) {
	const { type, height, cssExtra } = props;

	const style = [
		css`
			height: ${height ?? 'auto'};
		`,
		cssExtra,
	];

	switch (type) {
		case SocialLogoType.INSTAGRAM:
			return <InstagramIcon css={style} />;
		case SocialLogoType.TWITTER:
			return <TwitterIcon css={style} />;
		case SocialLogoType.DISCORD:
			return <DiscordIcon css={style} />;
		case SocialLogoType.GOOGLE:
			return <GoogleIcon css={style} />;
		case SocialLogoType.REDDIT:
			return <RedditIcon css={style} />;
		case SocialLogoType.TWITCH:
			return <TwitchIcon css={style} />;
		case SocialLogoType.STEAM:
			return <SteamIcon css={style} />;
		case SocialLogoType.APPLE:
			return <AppleIcon css={style} />;
		case SocialLogoType.FACEBOOK:
			return <FacebookIcon css={style} />;
		default: {
			const exhaustiveCheck: never = type;
			throw new Error(exhaustiveCheck);
		}
	}
}
