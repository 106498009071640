import { css } from '@emotion/react';

import Graphic from '../../../../../public/images/ui/background-waves.svg';

import type { SVGAttributes } from 'react';
import type { SerializedStyles } from '@emotion/react';

export interface Props extends SVGAttributes<SVGElement> {
	colors: WavesBackgroundColors;
	zoomFit?: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

type WavesBackgroundColor = string | null;

type WavesBackgroundColors = [
	WavesBackgroundColor,
	WavesBackgroundColor,
	WavesBackgroundColor,
	WavesBackgroundColor,
	WavesBackgroundColor,
	WavesBackgroundColor,
];

const getSectionStyles = (colors: WavesBackgroundColors) => {
	return colors.map((value, i) => {
		const style = value ? `fill: ${value}` : 'display: none';

		return css`
			& .section-${i} {
				${style};
			}
		`;
	});
};

export default function WavesBackground(props: Props) {
	const { height, width, colors, cssExtra, ...delegatedAttributes } = props;
	return (
		<Graphic
			css={css`
				width: ${width};
				height: ${height};

				${getSectionStyles(colors)};

				${cssExtra};
			`}
			{...delegatedAttributes}
		/>
	);
}
