import { useEffect, useRef } from 'react';

import type { RefObject } from 'react';

export function useClickOutside<T extends HTMLElement = HTMLElement>(
	onClickOutside?: (event: MouseEvent) => void,
	ref?: RefObject<T>,
) {
	const _ref = useRef<T>(null);
	const handlerRef = ref ?? _ref;

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (!onClickOutside) return;
			if (!handlerRef.current?.contains(event.target as Node)) {
				onClickOutside(event);
			}
		};

		window.addEventListener('mousedown', handleClickOutside);
		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onClickOutside, handlerRef]);

	return handlerRef;
}

export function useResizeObserver<T extends HTMLElement = HTMLElement>(
	callback: ResizeObserverCallback,
	ref?: RefObject<T>,
) {
	const _ref = useRef<T>(null);
	const handlerRef = ref ?? _ref;

	const observerRef = useRef<ResizeObserver>();

	useEffect(() => {
		if (!handlerRef.current) return undefined;
		observerRef.current = new ResizeObserver(callback);

		observerRef.current.observe(handlerRef.current);
		return () => {
			observerRef.current?.disconnect();
		};
	}, [handlerRef, callback]);

	return [handlerRef, observerRef];
}

export function useIntersectionObserver<T extends HTMLElement = HTMLElement>(
	callback: IntersectionObserverCallback,
	ref?: RefObject<T>,
) {
	const _ref = useRef<T>(null);
	const handlerRef = ref ?? _ref;

	const observerRef = useRef<IntersectionObserver>();

	useEffect(() => {
		if (!handlerRef.current) return undefined;
		observerRef.current = new IntersectionObserver(callback);

		observerRef.current.observe(handlerRef.current);
		return () => {
			observerRef.current?.disconnect();
		};
	}, [handlerRef, callback]);

	return [handlerRef, observerRef];
}

export function getMeta() {
	const tags = document.head.querySelectorAll<HTMLMetaElement>('meta[property^="daresay:"]');

	return [...tags].reduce((acc, curr) => {
		const property = curr.getAttribute('property');
		if (property === null) return acc;
		acc[property] = curr.content;
		return acc;
	}, {} as Record<string, string | undefined>);
}

export function useMeta() {
	const ref = useRef<Record<string, string | undefined>>(getMeta());
	return ref;
}
