import { useId, useState } from 'react';

import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';

import Card, { rectButtonStyle } from '../../../components/layout/card/Card';
import CardDaresayLogo from '../../../components/layout/card/CardDaresayLogo';
import TextEmailInput from '../../../components/input/TextEmailInput';
import StyledButton, { Style } from '../../../components/buttons/core/StyledButton';
import RedditGraphic from '../../../../../public/images/social/reddit-alt.svg';
import CardHeading from '../../../components/layout/card/CardHeading';
import CardDancingCirclesSpinner from '../../../components/layout/card/CardDancingCirclesSpinner';
import CardFooter from '../../../components/layout/card/CardFooter';
import CardFooterNavLink from '../../../components/layout/card/CardFooterNavLink';
import CardFooterSection from '../../../components/layout/card/CardFooterSection';
import CardPanel from '../../../components/layout/card/CardPanel';
import CardMain from '../../../components/layout/card/CardMain';
import CardPolicyDisclaimer from '../../../components/layout/card/CardPolicyDisclaimer';
import CardMainSection from '../../../components/layout/card/CardMainSection';

import type { SubmitHandler } from 'react-hook-form';

interface FormValues {
	email: string;
}

export default function RedditSignupCard() {
	const [inProgress, setInProgress] = useState(false);

	const id = useId();

	const { handleSubmit, control } = useForm<FormValues>();

	const onSubmit: SubmitHandler<FormValues> = (formData, e) => {
		e?.preventDefault();

		setInProgress(true);

		const currentParams = new URLSearchParams(window.location.search);
		const redirectUri = currentParams.get('redirect_uri') ?? '/';
		const failureRedirectUri = currentParams.get('failure_redirect_uri') ?? '/signup/error';

		const params = new URLSearchParams({
			mode: 'signup',
			email: formData.email,
			redirect_uri: new URL(redirectUri, window.location.href).href,
			failure_redirect_uri: new URL(failureRedirectUri, window.location.href).href,
		});

		window.location.href = `/api/auth/reddit?${params.toString()}`;
	};

	const onSubmitInvalid = undefined;
	return (
		<Card
			cssExtra={css`
				display: flex;
				flex-grow: 0.15;

				&.gradient-border {
					&::before {
						background-image: linear-gradient(
							var(--gradient-angle),
							var(--accent-color-b),
							var(--accent-color-o)
						);
					}
				}
			`}
		>
			<CardPanel>
				<CardDaresayLogo />

				<RedditGraphic
					css={css`
						width: 2.5em;
						height: 2.5em;
						margin-bottom: -10px;
					`}
				/>

				<CardMain>
					<CardHeading>Sign up with Reddit</CardHeading>

					<CardMainSection>
						<form
							noValidate
							css={css`
								display: flex;
								flex-direction: column;
								row-gap: inherit;
								align-items: stretch;
								justify-content: space-between;
							`}
							id={id}
							onSubmit={e => {
								handleSubmit(
									onSubmit,
									onSubmitInvalid,
								)(e).catch(() => {
									/* ignore */
								});
							}}
						>
							<TextEmailInput controllerProps={{ name: 'email', control, defaultValue: '' }} />
						</form>
						<StyledButton
							cssExtra={css`
								align-self: center;
								min-width: 128px;
								padding: 0.5em 2.25em 0.45em;
								margin-top: 10px;
								border-radius: 99rem;

								${rectButtonStyle};
							`}
							form={id}
							styleType={Style.RECT}
							type={'submit'}
						>
							Continue
						</StyledButton>

						<CardDancingCirclesSpinner show={inProgress} />
					</CardMainSection>
				</CardMain>
				<CardFooter>
					<CardFooterSection>
						<CardFooterNavLink to={'/signup'}>Back to sign up</CardFooterNavLink>
						<CardFooterNavLink emphasis={'Log in'} to={'/login'}>
							Have an account?
						</CardFooterNavLink>
					</CardFooterSection>
					<CardPolicyDisclaimer />
				</CardFooter>
			</CardPanel>
		</Card>
	);
}
