import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	children: ReactNode;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function CardContainer(props: Props) {
	const { children, cssExtra, ...delegatedAttributes } = props;

	return (
		<div
			css={css`
				position: relative;
				z-index: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 80px;

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			{children}
		</div>
	);
}
