import { css } from '@emotion/react';

import { boxShadow } from '../styles';
import { useClickOutside } from '../../hooks/dom';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'nav'> {
	onClickOutside?: (event: MouseEvent) => void;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function DropdownMenu(props: Props) {
	const { onClickOutside, cssExtra, children, ...delegatedAttributes } = props;

	const ref = useClickOutside(onClickOutside);

	return (
		<nav
			ref={ref}
			css={css`
				position: absolute;
				top: 40px;
				right: 0;
				display: flex;
				flex-direction: column;
				row-gap: 0.25em;
				align-items: end;
				padding: 12px;
				border-radius: var(--border-radius-1);

				${boxShadow(1)};
				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			{children}
		</nav>
	);
}
