import { Controller } from 'react-hook-form';

import TextInput, { transformRegisterRules } from './core/TextInput';

import type { FieldPath } from 'react-hook-form/dist/types';
import type { ComponentPropsWithoutRef } from 'react';
import type { FieldValues, ControllerProps } from 'react-hook-form';
import type { SerializedStyles } from '@emotion/react';
import type { Require } from '@daresay/types';

interface Props<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
	controllerProps: Omit<Require<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'defaultValue'>, 'render'>;
	inputProps?: ComponentPropsWithoutRef<'input'>;
	labelProps?: ComponentPropsWithoutRef<'label'>;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function TextEmailInput<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
	const { controllerProps, inputProps, labelProps, cssExtra } = props;

	const rules = transformRegisterRules('Email', {
		required: true,
		minLength: 5,
		maxLength: 64,
		pattern: /^\S+@\S+\.\S+$/,
		...controllerProps.rules,
	});

	return (
		<Controller
			{...controllerProps}
			render={({ field, fieldState }) => {
				return (
					<TextInput
						cssExtra={cssExtra}
						errorMessage={fieldState.error?.message}
						inputProps={{
							type: 'email',
							spellCheck: false,
							autoComplete: 'email',
							...inputProps,
							...field,
						}}
						labelProps={{ children: 'Email', ...labelProps }}
					/>
				);
			}}
			rules={rules}
		/>
	);
}
