import { css } from '@emotion/react';

import Main from '../../components/layout/view/Main';
import Article from '../../components/layout/article/Article';
import ArticleSection from '../../components/layout/article/ArticleSection';
import ArticleLabeledText from '../../components/layout/article/ArticleLabeledText';
import ArticleSubsection from '../../components/layout/article/ArticleSubsection';
import ArticleParagraph from '../../components/layout/article/ArticleParagraph';
import ArticleList from '../../components/layout/article/ArticleList';
import ArticleContent from '../../components/layout/article/ArticleContent';
import DistressedUnderlineAnchor from '../../components/buttons/DistressedUnderlineAnchor';
import DistressedUnderlineLink from '../../components/buttons/DistressedUnderlineLink';
import WavesBackgroundScaled from '../../components/layout/WavesBackgroundScaled';
import ArticleScrollToTop from '../../components/buttons/ArticleScrollToTop';
import SetMeta from '../../components/utils/SetMeta';

const REVISAL = new Date(2022, 6, 22);

export default function TermsPage() {
	return (
		<>
			<SetMeta description={''} title={'Terms of Use'} />
			<Main
				cssExtra={css`
					color: var(--background-color);
					background: var(--muted-color-e);
				`}
			>
				<WavesBackgroundScaled
					colors={[null, null, null, null, null, 'var(--accent-color-r)']}
					cssExtra={css`
						height: auto;
						transform: scale(0.6);
						transform-origin: top right;
					`}
				/>
				<WavesBackgroundScaled
					colors={['var(--accent-color-g)', null, null, null, null, null]}
					cssExtra={css`
						bottom: 0;
						height: auto;
						transform: scale(0.5);
						transform-origin: bottom left;
					`}
				/>
				<Article header={'Terms of Use'} revisal={REVISAL}>
					<ArticleContent>
						<ArticleSection header={'Agreement to Terms'} id={'agreement-to-terms'}>
							<ArticleParagraph>
								The following Terms of Use constitute a legally binding agreement made between you,
								whether personally or on behalf of an entity (“you”) and Daresay Studio, LLC, doing
								business as Daresay and Daresay Games (“Daresay”, “we”, “us”, or “our”), concerning your
								use of the websites of Daresay, including any content, applications, functionality,
								products, and services offered on or through such websites (collectively, the “Site”).
								These Terms of Use also apply to your use of other Daresay services that display,
								include, or connect to these terms (“Additional Services”). In these terms, the Site and
								Additional Services are collectively referred to as the “Services”. You agree that by
								accessing or using the Services, you have read, understood, and agreed to be bound by
								these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, YOU MUST NOT USE
								THE SERVICES.
							</ArticleParagraph>
							<ArticleParagraph>
								Supplemental terms and conditions or documents posted on the Site from time to time are
								expressly incorporated herein by reference. In our sole discretion, we reserve the right
								to change or modify these Terms of Use from time to time. We will indicate any changes
								by updating the “Last updated” date of these Terms of Use, and you waive any right to
								receive specific notice of each such change. You will be subject to and deemed to have
								been made aware of and to have accepted the changes in any revised Terms of Use by your
								continued use of the Services after the date such revised Terms of Use are posted.
							</ArticleParagraph>
							<ArticleParagraph>
								The information provided in the Services is not intended for distribution to or use by
								any person or entity in any jurisdiction or country where such distribution or use would
								be contrary to law or regulation or subject us to any registration requirement within
								such jurisdiction or country. Accordingly, those persons who choose to access the
								Services from other locations do so on their own initiative and are solely responsible
								for compliance with local laws, if and to the extent local laws are applicable.
							</ArticleParagraph>
							<ArticleParagraph>
								The Services are not tailored to comply with industry-specific regulations (Health
								Insurance Portability and Accountability Act (HIPAA), Federal Information Security
								Management Act (FISMA), etc.), so if your interactions would be subjected to such laws,
								you may not use Daresay Services. You may not use the Services in a way that would
								violate the Gramm-Leach-Bliley Act (GLBA).
							</ArticleParagraph>
							<ArticleParagraph>
								The Services are directed to general audiences and are intended for users who are at
								least 13 years of age. All users who are minors in the jurisdiction in which they reside
								(generally under the age of 18) must have the permission of, and be directly supervised
								by, their parent or guardian to use the Services. If you are a minor, you must have your
								parent or guardian read and agree to these Terms of Use before using the Services.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Intellectual Property Rights'} id={'intellectual-property-rights'}>
							<ArticleParagraph>
								The Services are our proprietary property and all source code, databases, functionality,
								software, website designs, audio, video, text, photographs, and graphics in the Services
								(collectively, the “Content”) and the trademarks, service marks, and logos contained
								therein (the “Marks”) that are owned or controlled by us or licensed to us, and are
								protected by copyright and trademark laws and various other intellectual property rights
								and unfair competition laws of the United States, international copyright laws, and
								international conventions. The Content and the Marks are provided in the Services “AS
								IS” for your information and personal use only. Except as expressly provided in these
								Terms of Use, no part of the Services, including Content and Marks, may be copied,
								reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded,
								translated, transmitted, distributed, sold, licensed, or otherwise exploited for any
								commercial purpose whatsoever, without our express prior written permission.
							</ArticleParagraph>
							<ArticleParagraph>
								Provided that you are eligible to use the Services, you are granted a limited license to
								access and use the Services and to download or print a copy of any portion of the
								Content to which you have properly gained access solely for your personal,
								non-commercial use. We reserve all rights not expressly granted to you in and to the
								Services, the Content, and the Marks.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'User Representations'} id={'user-representations'}>
							<ArticleParagraph>
								By using the Services, you represent and warrant that:{' '}
								<ArticleLabeledText>(i)</ArticleLabeledText> all registration information you submit
								will be true, accurate, current, and complete;{' '}
								<ArticleLabeledText>(ii)</ArticleLabeledText> you will maintain the accuracy of such
								information and promptly update such registration information as necessary;{' '}
								<ArticleLabeledText>(iii)</ArticleLabeledText> you have the legal capacity, and you
								agree to comply with these Terms of Use; <ArticleLabeledText>(iv)</ArticleLabeledText>{' '}
								you are not under the age of 13; <ArticleLabeledText>(v)</ArticleLabeledText> you are
								not a minor in the jurisdiction in which you reside, or if a minor, you have received
								parental permission to use the Services; <ArticleLabeledText>(vi)</ArticleLabeledText>{' '}
								you will not access the Services through automated or non-human means, whether through a
								bot, script, or otherwise; <ArticleLabeledText>(vii)</ArticleLabeledText> you will not
								use the Services for any illegal or unauthorized purpose; and{' '}
								<ArticleLabeledText>(viii)</ArticleLabeledText> your use of the Services will not
								violate any applicable law or regulation.
							</ArticleParagraph>
							<ArticleParagraph>
								If you provide any information that is untrue, inaccurate, not current, or incomplete,
								we have the right to suspend or terminate your account and refuse any current or future
								use of the Services (or any portion thereof).
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'User Registration'} id={'user-registration'}>
							<ArticleParagraph>
								You may be required to register with the Services. You agree to keep your account
								credentials confidential and will be responsible for all use of your account. We reserve
								the right to remove, reclaim, or change a username you select if we determine, in our
								sole discretion, that such username is inappropriate, obscene, or otherwise
								objectionable.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Prohibited Activities'} id={'prohibited-activities'}>
							<ArticleParagraph>
								You may not access or use the Services for any purpose other than the reasons for which
								we make the Services available. The Services may not be used in connection with any
								commercial endeavors except those specifically endorsed or approved by us.
							</ArticleParagraph>
							<ArticleParagraph>As a user of the Services, you agree not to:</ArticleParagraph>
							<ArticleList>
								<li>
									Systematically retrieve data or other content from the Services to create or
									compile, directly or indirectly, a collection, compilation, database, or directory
									without written permission from us.
								</li>
								<li>
									Trick, defraud, or mislead other users and us, especially in any attempt to learn
									sensitive account information such as user passwords.
								</li>
								<li>
									Circumvent, disable, or otherwise interfere with security-related features of the
									Services, including features that prevent or restrict the use or copying of any
									Content or enforce limitations on the use of the Services and/or the Content
									contained therein.
								</li>
								<li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
								<li>Use any information from the Services to harass, abuse, or harm another person.</li>
								<li>
									Make improper use of our support services or submit false reports of abuse or
									misconduct.
								</li>
								<li>
									Use the Services in a manner inconsistent with any applicable laws or regulations.
								</li>
								<li>Engage in unauthorized framing of or linking to the Services.</li>
								<li>
									Upload or transmit (or attempt to upload or to transmit) viruses, malware, or other
									material, including excessive use of capital letters and spamming (continuous
									posting of repetitive text), that interferes with any party’s uninterrupted use and
									enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with
									the use, features, functions, operation, or maintenance of the Services.
								</li>
								<li>
									Engage in any automated use of the system, such as using scripts to send comments or
									messages or using any data mining, robots, or similar data gathering and extraction
									tools.
								</li>
								<li>Delete the copyright or other proprietary rights notice from any Content.</li>
								<li>
									Attempt to impersonate another user or person or use the username of another user.
								</li>
								<li>
									Upload or transmit (or attempt to upload or to transmit) any material that acts as a
									passive or active information collection or transmission mechanism, including
									without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web
									bugs, cookies, or other similar devices (sometimes referred to as “spyware” or
									“passive collection mechanisms”).
								</li>
								<li>
									Interfere with, disrupt, or create an undue burden on the Services or the networks
									or services connected to the Services.
								</li>
								<li>
									Harass, annoy, intimidate, or threaten any of our agents, partners, or employees
									engaged in providing any portion of the Services to you.
								</li>
								<li>
									Attempt to bypass any measures designed to prevent or restrict access to the
									Services or any portion of the Services.
								</li>
								<li>
									Copy or adapt the Services’ software, including but not limited to HTML, JavaScript,
									WebAssembly, or other code.
								</li>
								<li>
									Except as permitted by applicable law, decipher, decompile, disassemble, or reverse
									engineer any of the software comprising or in any way making up a part of the
									Services.
								</li>
								<li>
									Except as may be the result of standard search engine or Internet browser usage,
									use, launch, develop, or distribute any automated system, including without
									limitation, any spider, robot, cheat utility, scraper, or offline reader that
									accesses the Services, or using or launching any unauthorized script or other
									software.
								</li>
								<li>Use a buying agent or purchasing agent to make purchases in the Services.</li>
								<li>
									Make any unauthorized use of the Services, including collecting users’ email
									addresses by electronic or other means to send unsolicited email or create user
									accounts by automated means or under false pretenses.
								</li>
								<li>
									Use the Services as part of any effort to compete with us or otherwise use the
									Services and/or the Content for any revenue-generating endeavor or commercial
									enterprise.
								</li>
								<li>Use the Services to advertise or offer to sell goods and services.</li>
								<li>Sell or otherwise transfer your profile.</li>
							</ArticleList>
						</ArticleSection>
						<ArticleSection header={'User-Generated Contributions'} id={'user-generated-contributions'}>
							<ArticleParagraph>
								The Services may invite you to chat, contribute to, or participate in blogs, message
								boards, online forums, and other functionality. Furthermore, the Services may provide
								you with the opportunity to create, submit, post, display, transmit, perform, publish,
								distribute, or broadcast content and materials to us or in the Services, including but
								not limited to text, writings, video, audio, photographs, graphics, comments,
								suggestions, or personal information or other material (collectively, “Contributions”).
								Contributions may be viewable by other users of the Services and through third-party
								websites. Any Contributions you transmit may be treated as non-confidential and
								non-proprietary. When you create or make available any Contributions, you thereby
								represent and warrant that:
							</ArticleParagraph>
							<ArticleList>
								<li>
									The creation, distribution, transmission, public display, or performance, and the
									accessing, downloading, or copying of your Contributions do not and will not
									infringe the proprietary rights, including but not limited to the copyright, patent,
									trademark, trade secret, or moral rights of any third party.
								</li>
								<li>
									You are the creator and owner of or have the necessary licenses, rights, consents,
									releases, and permissions to use and to authorize us, the Services, and other users
									of the Services to use your Contributions in any manner contemplated by the Services
									and these Terms of Use.
								</li>
								<li>
									You have the written consent, release, and/or permission of every identifiable
									person in your Contributions to use their name or likeness and to enable inclusion
									and use of your Contributions in any manner contemplated by the Services and these
									Terms of Use.
								</li>
								<li>Your Contributions are not false, inaccurate, or misleading.</li>
								<li>
									Your Contributions are not unsolicited or unauthorized advertising, promotional
									materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of
									solicitation.
								</li>
								<li>
									Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing,
									libelous, slanderous, or otherwise objectionable (as determined by us).
								</li>
								<li>
									Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
								</li>
								<li>
									Your Contributions are not used to harass or threaten (in the legal sense of those
									terms) any other person and to promote violence against a specific person or class
									of people.
								</li>
								<li>Your Contributions do not violate any applicable law, regulation, or rule.</li>
								<li>
									Your Contributions do not violate any third party’s privacy or publicity rights.
								</li>
								<li>
									Your Contributions do not violate any applicable law concerning child pornography or
									otherwise intended to protect the health or well-being of minors.
								</li>
								<li>
									Your Contributions do not include any offensive comments or implications involving
									race, national origin, gender, sexual preference, or physical handicap.
								</li>
								<li>
									Your Contributions do not otherwise violate or link to material that infringes any
									provision of these Terms of Use or any applicable law or regulation.
								</li>
							</ArticleList>
							<ArticleParagraph>
								Any use of the Services in violation of the preceding conditions violates these Terms of
								Use and may result in, among other things, termination or suspension of your rights to
								use the Services.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Contribution License'} id={'contribution-license'}>
							<ArticleParagraph>
								By posting your Contributions to any part of the Services or making Contributions
								accessible to the Services by linking your account from the Services to any of your
								social networking accounts, you automatically grant, as well as represent and warrant
								that you have the right to grant to us an unrestricted, unlimited, irrevocable,
								perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and
								license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast,
								retitle, archive, store, cache, publicly perform, publicly display, reformat, translate,
								transmit, excerpt (in whole or in part), and distribute such Contributions (including,
								without limitation, your image and voice) for any purpose, commercial, advertising, or
								otherwise, and to prepare derivative works of, or incorporate into other works, such
								Contributions, and grant and authorize sublicenses of the foregoing. The use and
								distribution may occur in any media format and through any media channel.
							</ArticleParagraph>

							<ArticleParagraph>
								This license will apply to any form, media, or technology now known or hereafter
								developed, and includes our use of your name, company name, and franchise name, as
								applicable, and any of the trademarks, service marks, trade names, logos, and personal
								and commercial images you provide. You waive all moral rights in your Contributions, and
								you warrant that moral rights have not otherwise been asserted in your Contributions.
							</ArticleParagraph>
							<ArticleParagraph>
								We do not assert any ownership over your Contributions. You retain full ownership of all
								your Contributions and any intellectual property or other proprietary rights associated
								with your Contributions. We are not liable for any statements or representations in your
								Contributions in any part of the Services. You are solely responsible for your
								Contributions to the Services, and you expressly agree to exonerate us from all
								responsibility and to refrain from any legal action against us regarding your
								Contributions.
							</ArticleParagraph>
							<ArticleParagraph>
								We have the right, in our sole and absolute discretion,{' '}
								<ArticleLabeledText>(i)</ArticleLabeledText> to edit, redact, or otherwise change any
								Contributions; <ArticleLabeledText>(ii)</ArticleLabeledText> to re-categorize any
								Contributions to place them in more appropriate locations in the Services; and{' '}
								<ArticleLabeledText>(iii)</ArticleLabeledText> to pre-screen or delete any Contributions
								at any time and for any reason, without notice. We have no obligation to monitor your
								Contributions.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Guidelines for Reviews'} id={'guidelines-for-reviews'}>
							<ArticleParagraph>
								We may provide you the means to leave reviews or ratings in the Services. When posting a
								review, you must comply with the following criteria:{' '}
								<ArticleLabeledText>(i)</ArticleLabeledText> you should have firsthand experience with
								the person/entity being reviewed; <ArticleLabeledText>(ii)</ArticleLabeledText> your
								reviews should not contain offensive profanity, or abusive, racist, offensive, or hate
								language; <ArticleLabeledText>(iii)</ArticleLabeledText> your reviews should not contain
								discriminatory references based on religion, race, gender, national origin, age, marital
								status, sexual orientation, or disability; <ArticleLabeledText>(iv)</ArticleLabeledText>{' '}
								your reviews should not contain references to illegal activity;{' '}
								<ArticleLabeledText>(v)</ArticleLabeledText> you should not be affiliated with
								competitors if posting negative reviews; <ArticleLabeledText>(vi)</ArticleLabeledText>{' '}
								you should not make any conclusions as to the legality of conduct;{' '}
								<ArticleLabeledText>(vii)</ArticleLabeledText> you may not post any false or misleading
								statements; and <ArticleLabeledText>(viii)</ArticleLabeledText> you may not organize a
								campaign encouraging others to post reviews, whether positive or negative.
							</ArticleParagraph>
							<ArticleParagraph>
								We may accept, reject, or remove reviews at our sole discretion. We have absolutely no
								obligation to screen or delete reviews, even if anyone considers reviews objectionable
								or inaccurate. Reviews are not endorsed by us and do not necessarily represent our
								opinions or the views of any of our affiliates or partners. We do not assume liability
								for any review or claims, liabilities, or losses resulting from any review. By posting a
								review, you grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
								assignable, and sublicensable right and license to reproduce, modify, translate,
								transmit by any means, display, perform, and/or distribute all content relating to
								reviews.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Application License'} id={'application-license'}>
							<ArticleParagraph>
								The following terms apply when you use the Services via an application or game installed
								from the Site or a third-party distribution service (an “App Distributor”) like Google
								Play, Apple Store, Microsoft Store, or Steam.
							</ArticleParagraph>
							<ArticleSubsection header={'Use License'} id={'use-license'}>
								<ArticleParagraph>
									If you access the Services using installable applications or games, then we grant
									you a revocable, non-exclusive, non-transferable, limited right to install and use
									the application on electronic devices owned or controlled by you and to access and
									use the application on such devices strictly in accordance with the terms and
									conditions of this application license contained in these Terms of Use. You shall
									not: <ArticleLabeledText>(i)</ArticleLabeledText> except as permitted by applicable
									law, decompile, reverse engineer, disassemble, attempt to derive the source code of,
									or decrypt the application; <ArticleLabeledText>(ii)</ArticleLabeledText> make any
									modification, adaptation, improvement, enhancement, translation, or derivative work
									from the application; <ArticleLabeledText>(iii)</ArticleLabeledText> violate any
									applicable laws, rules, or regulations in connection with your access or use of the
									application; <ArticleLabeledText>(iv)</ArticleLabeledText> remove, alter, or obscure
									any proprietary notice (including any notice of copyright or trademark) posted by us
									or the licensors of the application; <ArticleLabeledText>(v)</ArticleLabeledText>{' '}
									use the application for any revenue generating endeavor, commercial enterprise, or
									other purpose for which it is not designed or intended;{' '}
									<ArticleLabeledText>(vi)</ArticleLabeledText> make the application available over a
									network or other environment permitting access or use by multiple devices or users
									at the same time; <ArticleLabeledText>(vii)</ArticleLabeledText> use the application
									for creating a product, service, or software that is, directly or indirectly,
									competitive with or in any way a substitute for the application;{' '}
									<ArticleLabeledText>(viii)</ArticleLabeledText> use the application to send
									automated queries to any website or to send any unsolicited commercial email; or{' '}
									<ArticleLabeledText>(ix)</ArticleLabeledText> use any proprietary information or any
									of our interfaces or our other intellectual property in the design, development,
									manufacture, licensing, or distribution of any applications, accessories, or devices
									for use with the application.
								</ArticleParagraph>
							</ArticleSubsection>
							<ArticleSubsection header={'Distributor Applications'} id={'distributor-applications'}>
								<ArticleParagraph>
									When you use the Services via installed games or applications from an App
									Distributor to access the Services, the following terms apply:{' '}
									<ArticleLabeledText>(i)</ArticleLabeledText> the license granted to you for our
									application is limited to a non-transferable license to use the application on the
									device or platform, as applicable, and in accordance with the usage rules set forth
									in the applicable App Distributor’s terms of service;{' '}
									<ArticleLabeledText>(ii)</ArticleLabeledText> we are responsible for providing any
									maintenance and support services with respect to the application as specified in the
									terms and conditions of this application license contained in these Terms of Use or
									as otherwise required under applicable law, and you acknowledge that each App
									Distributor has no obligation whatsoever to furnish any maintenance and support
									services with respect to the application;{' '}
									<ArticleLabeledText>(iii)</ArticleLabeledText> in the event of any failure of the
									application to conform to any applicable warranty, you may notify the applicable App
									Distributor, and the App Distributor, in accordance with its terms and policies, may
									refund the purchase price, if any, paid for the application, and to the maximum
									extent permitted by applicable law, the App Distributor will have no other warranty
									obligation whatsoever with respect to the application;{' '}
									<ArticleLabeledText>(iv)</ArticleLabeledText> you represent and warrant that{' '}
									<ArticleLabeledText>(a)</ArticleLabeledText> you are not located in a country that
									is subject to a United States government embargo, or that has been designated by the
									United States government as a “terrorist supporting” country and{' '}
									<ArticleLabeledText>(b)</ArticleLabeledText> you are not listed on any United States
									government list of prohibited or restricted parties;{' '}
									<ArticleLabeledText>(v)</ArticleLabeledText> you must comply with applicable
									third-party terms of agreement when using the application, e.g., if you have a VoIP
									application, then you must not be in violation of their wireless data service
									agreement when using the application; and{' '}
									<ArticleLabeledText>(vi)</ArticleLabeledText> you acknowledge and agree that the App
									Distributors are third-party beneficiaries of the terms and conditions in this
									application license contained in these Terms of Use, and that each App Distributor
									will have the right (and will be deemed to have accepted the right) to enforce the
									terms and conditions in this application license contained in these Terms of Use
									against you as a third-party beneficiary thereof.
								</ArticleParagraph>
							</ArticleSubsection>
						</ArticleSection>
						<ArticleSection header={'Social Media'} id={'social-media'}>
							<ArticleParagraph>
								As part of the functionality of the Site and Additional Services, you may link your
								account with online accounts you have with third-party service providers (each such
								account, a “Third-Party Account”) by either:{' '}
								<ArticleLabeledText>(i)</ArticleLabeledText> providing your Third-Party Account login
								information through the Site; or <ArticleLabeledText>(ii)</ArticleLabeledText> allowing
								us to access your Third-Party Account, as is permitted under the applicable terms and
								conditions that govern your use of each Third-Party Account. You represent and warrant
								that you are entitled to disclose your Third-Party Account login information to us
								and/or grant us access to your Third-Party Account without breach by you of any of the
								terms and conditions that govern your use of the applicable Third-Party Account and
								without obligating us to pay any fees or making us subject to any usage limitations
								imposed by the third-party service provider of the Third-Party Account. By granting us
								access to any Third-Party Accounts, you understand that{' '}
								<ArticleLabeledText>(i)</ArticleLabeledText> we may access, make available, and store
								(if applicable) any content that you have provided to and stored in your Third-Party
								Account (the “Social Network Content”) so that it is available in and through the
								Services via your account, including without limitation any friend lists and{' '}
								<ArticleLabeledText>(ii)</ArticleLabeledText> we may submit to and receive from your
								Third-Party Account additional information to the extent you are notified when you link
								your account with the Third-Party Account. Depending on the Third-Party Accounts you
								choose and subject to the privacy settings you have set in such Third-Party Accounts,
								personally identifiable information that you post to your Third-Party Accounts may be
								available on and through your account in the Services. Please note that if a Third-Party
								Account or associated service becomes unavailable or our access to such Third Party
								Account is terminated by the third-party service provider, then Social Network Content
								may no longer be available on and through the Services. You can disable the connection
								between your account in the Services and your Third-Party Accounts at any time. PLEASE
								NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR
								THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY
								SERVICE PROVIDERS. We make no effort to review any Social Network Content for any
								purpose, including but not limited to accuracy, legality, or non-infringement, and we
								are not responsible for any Social Network Content. You acknowledge and agree that we
								may access your social connections from a Third-Party Account and/or contacts list
								stored on your device to identify and inform you of those contacts who have also
								registered to use the Services. You can deactivate the connection between the Services
								and your Third-Party Account by contacting us using the contact information below or
								through your account settings. We will attempt to delete any information stored on our
								servers that was obtained through such Third-Party Account, except information required
								to maintain your account.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Submissions'} id={'Submissions'}>
							<ArticleParagraph>
								You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or
								other information regarding the Services (“Submissions”) provided by you to us are
								non-confidential and shall become our sole property. We shall own exclusive rights,
								including all intellectual property rights, and shall be entitled to the unrestricted
								use and dissemination of these Submissions for any lawful purpose, commercial or
								otherwise, without acknowledgment or compensation to you. You hereby waive all moral
								rights to any such Submissions and warrant that any such Submissions are original from
								you or that you have the right to submit such Submissions. You agree there shall be no
								recourse against us for any alleged or actual infringement or misappropriation of any
								proprietary right in your Submissions.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Third-Party Website & Content'} id={'third-party-website-and-content'}>
							<ArticleParagraph>
								The Services may contain (or you may be sent via the Services) links to other websites
								(“Third-Party Websites”) as well as articles, photographs, text, graphics, pictures,
								designs, music, sound, video, information, applications, software, and other content or
								items belonging to or originating from third parties (“Third-Party Content”). Such
								Third-Party Websites and Third-Party Content are not investigated, monitored, or checked
								for accuracy, appropriateness, or completeness by us, and we are not responsible for any
								Third-Party Websites accessed through the Services or any Third-Party Content posted on,
								available through, or installed from the Services, including the content, accuracy,
								offensiveness, opinions, reliability, privacy practices, or other policies of or
								contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking
								to, or permitting the use or installation of any Third-Party Websites or any Third-Party
								Content does not imply our approval or endorsement. If you decide to leave the Services
								and access the Third-Party Websites or to use or install any Third-Party Content, you do
								so at your own risk, and you should be aware that these Terms of Use no longer govern.
								You should review the applicable terms and policies, including privacy and data
								gathering practices, of any website to which you navigate from the Services or relating
								to any applications you use or install from the Services. Any purchases you make through
								Third-Party Websites will be through other websites and from other companies, and we
								take no responsibility whatsoever concerning such purchases, which are exclusively
								between you and the applicable third party. You agree and acknowledge that we do not
								endorse the products or services offered on Third-Party Websites, and you shall hold us
								harmless from any harm caused by your purchase of such products or services.
								Additionally, you shall hold us harmless from any losses sustained by you or harm caused
								to you relating to or resulting in any way from any Third-Party Content or any contact
								with Third-Party Websites.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Advertisers'} id={'advertisers'}>
							<ArticleParagraph>
								We allow advertisers to display their advertisements and other information in certain
								areas of the Services, such as sidebar or banner advertisements. If you are an
								advertiser, you shall take full responsibility for any advertisements you place in the
								Services and products sold through those advertisements. Further, as an advertiser, you
								warrant and represent that you possess all rights and authority to place advertisements
								in the Services, including, but not limited to, intellectual property rights, publicity
								rights, and contractual rights. As an advertiser, you agree that such advertisements are
								subject to our Digital Millennium Copyright Act (“DMCA”) Notice and Policy provisions as
								described below, and you understand and agree there will be no refund or other
								compensation for DMCA takedown-related issues. We provide the space to place such
								advertisements, and we have no other relationship with advertisers.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Management'} id={'management'}>
							<ArticleParagraph>
								We reserve the right, but not the obligation, to:{' '}
								<ArticleLabeledText>(i)</ArticleLabeledText> monitor the Services for violations of
								these Terms of Use; <ArticleLabeledText>(ii)</ArticleLabeledText> take appropriate legal
								action against anyone who, in our sole discretion, violates the law or these Terms of
								Use, including without limitation, reporting such user to law enforcement authorities;{' '}
								<ArticleLabeledText>(iii)</ArticleLabeledText> in our sole discretion and without
								limitation, refuse, restrict access to, limit the availability of, or disable (to the
								extent technologically feasible) any of your Contributions or any portion thereof;{' '}
								<ArticleLabeledText>(iv)</ArticleLabeledText> in our sole discretion and without
								limitation, notice, or liability, to remove from the Services or otherwise disable all
								files and content that are excessive in size or are in any way burdensome to our
								systems; and <ArticleLabeledText>(v)</ArticleLabeledText> otherwise manage the Services
								in a manner designed to protect our rights and property and to facilitate the proper
								functioning of the Services.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Privacy Policy'} id={'privacy-policy'}>
							<ArticleParagraph>
								We care about data privacy and security. By using the Services, you agree to be bound by
								our <DistressedUnderlineLink to={'/privacy'}>Privacy Notice</DistressedUnderlineLink>,
								which is incorporated into these Terms of Use. We may host the Services and process data
								in locations different from the region in which you reside. Further, we do not knowingly
								accept, request, or solicit information from children or knowingly market to children.
								Therefore, under the U.S. Children’s Online Privacy Protection Act, if we receive actual
								knowledge that anyone under the age of 13 has provided personal information to us
								without the requisite and verifiable parental consent, we will delete that information
								from the Services as quickly as is reasonably practical.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'DMCA Notice'} id={'dmca'}>
							<ArticleSubsection header={'Notifications'} id={'notifications'}>
								<ArticleParagraph>
									We respect the intellectual property rights of others. If you believe that any
									material available in or through the Services infringes upon any copyright you own
									or control, please immediately notify our Designated Copyright Agent using the
									contact information provided below (a “Notification”). A copy of your Notification
									will be sent to the person who posted or stored the material addressed in the
									Notification. Please be advised that according to federal law, you may be liable for
									damages if you make material misrepresentations in a Notification. Thus, if you are
									not sure that material located in or linked to by the Services infringes your
									copyright, you should consider first contacting an attorney.
								</ArticleParagraph>
								<ArticleParagraph>
									All Notifications should meet the requirements of DMCA 17 U.S.C. § 512(c)(3) and
									include the following information: <ArticleLabeledText>(i)</ArticleLabeledText> A
									physical or electronic signature of a person authorized to act on behalf of the
									owner of an exclusive right that is allegedly infringed;{' '}
									<ArticleLabeledText>(ii)</ArticleLabeledText> identification of the copyrighted work
									claimed to have been infringed, or, if multiple copyrighted works in the Services
									are covered by the Notification, a representative list of such works in the
									Services; <ArticleLabeledText>(iii)</ArticleLabeledText> identification of the
									material that is claimed to be infringing or to be the subject of infringing
									activity and that is to be removed or access to which is to be disabled, and
									information reasonably sufficient to permit us to locate the material;{' '}
									<ArticleLabeledText>(iv)</ArticleLabeledText> information reasonably sufficient to
									permit us to contact the complaining party, such as an address, telephone number,
									and, if available, an email address at which the complaining party may be contacted;{' '}
									<ArticleLabeledText>(v)</ArticleLabeledText> a statement that the complaining party
									has a good faith belief that use of the material in the manner complained of is not
									authorized by the copyright owner, its agent, or the law; and{' '}
									<ArticleLabeledText>(vi)</ArticleLabeledText> a statement that the information in
									the Notification is accurate, and under penalty of perjury, that the complaining
									party is authorized to act on behalf of the owner of an exclusive right that is
									allegedly infringed upon.
								</ArticleParagraph>
							</ArticleSubsection>
							<ArticleSubsection header={'Counter-Notification'} id={'counter-notification'}>
								<ArticleParagraph>
									If you believe your own copyrighted material has been removed from the Services as a
									result of a mistake or misidentification, you may submit a written
									counter-notification to our Designated Copyright Agent using the contact information
									provided below (a “Counter-Notification”). To be an effective Counter-Notification
									under the DMCA, your Counter-Notification must include substantially the following:{' '}
									<ArticleLabeledText>(i)</ArticleLabeledText> identification of the material that has
									been removed or disabled and the location at which the material appeared before it
									was removed or disabled; <ArticleLabeledText>(ii)</ArticleLabeledText> a statement
									that you consent to the jurisdiction of the Federal District Court in which your
									address is located, or if your address is outside the United States, for any
									judicial district in which we are located;{' '}
									<ArticleLabeledText>(iii)</ArticleLabeledText> a statement that you will accept
									service of process from the party that filed the Notification or the party’s agent;{' '}
									<ArticleLabeledText>(iv)</ArticleLabeledText> your name, address, and telephone
									number; <ArticleLabeledText>(v)</ArticleLabeledText> a statement under penalty of
									perjury that you have a good faith belief that the material in question was removed
									or disabled as a result of a mistake or misidentification of the material to be
									removed or disabled; and <ArticleLabeledText>(vi)</ArticleLabeledText> your physical
									or electronic signature.
								</ArticleParagraph>
								<ArticleParagraph>
									If you send us a valid Counter-Notification meeting the requirements described
									above, we will restore your removed or disabled material unless we first receive
									notice from the party filing the Notification informing us that such party has filed
									a court action to restrain you from engaging in infringing activity related to the
									material in question. Please note that if you materially misrepresent that the
									disabled or removed content was removed by mistake or misidentification, you may be
									liable for damages, including costs and attorney’s fees. Filing a false
									Counter-Notification constitutes perjury. You can send your Counter-Notification by
									email to{' '}
									<DistressedUnderlineAnchor href={'mailto:legal@daresaygames.com'}>
										legal@daresaygames.com
									</DistressedUnderlineAnchor>
									.
								</ArticleParagraph>
							</ArticleSubsection>
						</ArticleSection>
						<ArticleSection header={'Term & Termination'} id={'term-and-termination'}>
							<ArticleParagraph>
								These Terms of Use shall remain in full force and effect while you use the Services.
								WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN
								OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
								SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR NO
								REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
								COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
								TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY
								CONTENT OR INFORMATION YOU POSTED AT ANY TIME, WITHOUT WARNING, AT OUR SOLE DISCRETION.
							</ArticleParagraph>
							<ArticleParagraph>
								If we terminate or suspend your account for any reason, you are prohibited from
								registering and creating a new account under your name, a fake or borrowed name, or the
								name of any third party, even if you may be acting on behalf of the third party. In
								addition to terminating or suspending your account, we reserve the right to take
								appropriate legal action, including, without limitation, pursuing civil, criminal, and
								injunctive redress.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Modifications & Interruptions'} id={'modifications-and-interruptions'}>
							<ArticleParagraph>
								We reserve the right to change, modify, or remove the contents of the Services at any
								time or for any reason at our sole discretion without notice. However, we have no
								obligation to update any information in our Services. We also reserve the right to
								modify or discontinue all or part of the Services without notice at any time. We will
								not be liable to you or any third party for any modification, price change, suspension,
								or discontinuance of the Services.
							</ArticleParagraph>
							<ArticleParagraph>
								We cannot guarantee that the Services will be available at all times. We may experience
								hardware, software, or other problems or need to perform maintenance related to the
								Services, resulting in interruptions, delays, or errors. We reserve the right to change,
								revise, update, suspend, discontinue, or otherwise modify the Services at any time or
								for any reason without notice to you. You agree that we have no liability whatsoever for
								any loss, damage, or inconvenience caused by your inability to access or use the
								Services during any downtime or discontinuance of the Services. Nothing in these Terms
								of Use will be construed to obligate us to maintain and support the Services or to
								supply any corrections, updates, or releases in connection therewith.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Governing Law'} id={'governing-law'}>
							<ArticleParagraph>
								These Terms of Use and your use of the Services are governed by and construed in
								accordance with the laws of the Commonwealth of Virginia applicable to agreements made
								and to be entirely performed within the Commonwealth of Virginia, without regard to its
								conflict of law principles.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Dispute Resolution'} id={'dispute-resolution'}>
							<ArticleSubsection header={'Informal Negotiations'} id={'informal-negotiations'}>
								<ArticleParagraph>
									To expedite resolution and control the cost of any dispute, controversy, or claim
									related to these Terms of Use (each “Dispute” and collectively, the “Disputes”)
									brought by either you or us (individually, a “Party” and collectively, the
									“Parties”), the Parties agree first to attempt to negotiate any Dispute (except
									those Disputes expressly provided below) informally for at least thirty (30) days
									before initiating arbitration. Such informal negotiations commence upon written
									notice from one Party to the other Party.
								</ArticleParagraph>
							</ArticleSubsection>
							<ArticleSubsection header={'Binding Arbitration'} id={'binding-arbitration'}>
								<ArticleParagraph>
									If the Parties are unable to resolve a Dispute through informal negotiations, the
									Dispute (except those Disputes expressly excluded below) will be finally and
									exclusively resolved through binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS
									PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
									arbitration shall be commenced and conducted under the Commercial Arbitration Rules
									of the American Arbitration Association (“AAA”) and, where appropriate, the AAA’s
									Supplementary Procedures for Consumer Related Disputes (“AAA Consumer Rules”), both
									of which are available at the AAA website: www.adr.org. Your arbitration fees and
									share of arbitrator compensation shall be governed by the AAA Consumer Rules and,
									where appropriate, limited by the AAA Consumer Rules. If the arbitrator determines
									such costs to be excessive, we will pay all arbitration fees and expenses. The
									arbitration may be conducted in person, through the submission of documents, by
									phone, or online. The arbitrator will make a decision in writing but need not
									provide a statement of reasons unless requested by either Party. The arbitrator must
									follow applicable law, and any award may be challenged if the arbitrator fails to do
									so. The arbitration will take place in Virginia unless otherwise required by the
									applicable AAA rules or law. Except as otherwise provided herein, the Parties may
									litigate in court to compel arbitration, stay proceedings pending arbitration, or
									confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.
								</ArticleParagraph>
								<ArticleParagraph>
									If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute
									shall be commenced or prosecuted in the state and federal courts located in
									Virginia, and the Parties hereby consent to and waive all defenses of lack of
									personal jurisdiction and{' '}
									<span
										css={css`
											font-style: italic;
										`}
									>
										forum non conveniens
									</span>{' '}
									with respect to venue and jurisdiction in such state and federal courts. Application
									of the United Nations Convention on Contracts for the International Sale of Goods
									and the Uniform Computer Information Transaction Act (UCITA) is excluded from these
									Terms of Use.
								</ArticleParagraph>
								<ArticleParagraph>
									In no event shall any Dispute brought by either Party related in any way to the
									Services be commenced more than one (1) year after the cause of action arose. If
									this provision is found to be illegal or unenforceable, then neither Party will
									elect to arbitrate any Dispute falling within that portion of this provision found
									to be unlawful or unenforceable, and a court of competent jurisdiction shall decide
									such Dispute within the courts listed for jurisdiction above, and the Parties agree
									to submit to the personal jurisdiction of that court.
								</ArticleParagraph>
							</ArticleSubsection>
							<ArticleSubsection header={'Restrictions'} id={'restrictions'}>
								<ArticleParagraph>
									The Parties agree that any arbitration shall be limited to the Dispute between the
									Parties individually. To the full extent permitted by law,{' '}
									<ArticleLabeledText>(i)</ArticleLabeledText> no arbitration shall be joined with any
									other proceeding; <ArticleLabeledText>(ii)</ArticleLabeledText> there is no right or
									authority for any Dispute to be arbitrated on a class-action basis or to utilize
									class action procedures; and <ArticleLabeledText>(iii)</ArticleLabeledText> there is
									no right or authority for any Dispute to be brought in a purported representative
									capacity on behalf of the general public or any other persons.
								</ArticleParagraph>
							</ArticleSubsection>
							<ArticleSubsection header={'Exceptions'} id={'exceptions'}>
								<ArticleParagraph>
									The Parties agree that the following Disputes are not subject to the above
									provisions concerning informal negotiations and binding arbitration:{' '}
									<ArticleLabeledText>(i)</ArticleLabeledText> any Disputes seeking to enforce or
									protect, or concerning the validity of, any of the intellectual property rights of a
									Party; <ArticleLabeledText>(ii)</ArticleLabeledText> any Dispute related to, or
									arising from, allegations of theft, piracy, invasion of privacy, or unauthorized
									use; and <ArticleLabeledText>(iii)</ArticleLabeledText> any claim for injunctive
									relief. If this provision is found to be illegal or unenforceable, then neither
									Party will elect to arbitrate any Dispute falling within that portion of this
									provision found to be unlawful or unenforceable, and a court of competent
									jurisdiction shall decide such Dispute within the courts listed for jurisdiction
									above, and the Parties agree to submit to the personal jurisdiction of that court.
								</ArticleParagraph>
							</ArticleSubsection>
						</ArticleSection>
						<ArticleSection header={'Corrections'} id={'corrections'}>
							<ArticleParagraph>
								There may be information in the Services that contains typographical errors,
								inaccuracies, or omissions, including descriptions, pricing, availability, and various
								other details. We reserve the right to correct any errors, inaccuracies, or omissions
								and to change or update the information in the Services at any time, without prior
								notice.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Disclaimer'} id={'disclaimer'}>
							<ArticleParagraph>
								THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF
								THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
								DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR
								USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
								FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
								REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES’ CONTENT OR THE
								CONTENT OF ANY WEBSITES LINKED TO THE SERVICES, AND WE WILL ASSUME NO LIABILITY OR
								RESPONSIBILITY FOR ANY <ArticleLabeledText>(i)</ArticleLabeledText> ERRORS, MISTAKES, OR
								INACCURACIES OF CONTENT AND MATERIALS, <ArticleLabeledText>(ii)</ArticleLabeledText>{' '}
								PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS
								TO AND USE OF THE SERVICES, <ArticleLabeledText>(iii)</ArticleLabeledText> ANY
								UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
								INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,{' '}
								<ArticleLabeledText>(iv)</ArticleLabeledText> ANY INTERRUPTION OR CESSATION OF
								TRANSMISSION TO OR FROM THE SERVICES, <ArticleLabeledText>(v)</ArticleLabeledText> ANY
								BUGS, VIRUSES, MALWARE, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES
								BY ANY THIRD PARTY, AND/OR <ArticleLabeledText>(vi)</ArticleLabeledText> ANY ERRORS OR
								OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS
								A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
								THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
								PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
								HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
								OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
								MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
								SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
								ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Limitations of Liability'} id={'limitations-of-liability'}>
							<ArticleParagraph>
								IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD
								PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
								PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
								ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
								OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
								LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL
								AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX{' '}
								<ArticleLabeledText>(vi)</ArticleLabeledText> MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
								ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
								IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
								APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU,
								AND YOU MAY HAVE ADDITIONAL RIGHTS.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Indemnification'} id={'indemnification'}>
							<ArticleParagraph>
								You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
								affiliates, and all of our respective officers, agents, partners, and employees, from
								and against any loss, damage, liability, claim, or demand, including reasonable
								attorneys’ fees and expenses, made by any third party due to or arising out of:{' '}
								<ArticleLabeledText>(i)</ArticleLabeledText> your Contributions;{' '}
								<ArticleLabeledText>(ii)</ArticleLabeledText> use of the Services;{' '}
								<ArticleLabeledText>(iii)</ArticleLabeledText> breach of these Terms of Use;{' '}
								<ArticleLabeledText>(iv)</ArticleLabeledText> any breach of your representations and
								warranties outlined in these Terms of Use; <ArticleLabeledText>(v)</ArticleLabeledText>{' '}
								your violation of the rights of a third party, including but not limited to intellectual
								property rights; or <ArticleLabeledText>(vi)</ArticleLabeledText> any overt harmful act
								toward any other user of the Services with whom you connected via the Services.
								Notwithstanding the foregoing, we reserve the right, at your expense, to assume the
								exclusive defense and control of any matter for which you are required to indemnify us,
								and you agree to cooperate, at your expense, with our defense of such claims. We will
								use reasonable efforts to notify you of any such claim, action, or proceeding subject to
								this indemnification upon becoming aware of it.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'User Data'} id={'user-data'}>
							<ArticleParagraph>
								We will maintain data that you transmit to the Services to manage the performance of the
								Services, as well as data relating to your use of the Services. Although we perform
								routine backups of data, you are solely responsible for all data you transmit or related
								to any activity you have undertaken using the Services. You agree that we shall have no
								liability to you for any loss or corruption of any such data, and you hereby waive any
								right of action against us arising from any such loss or corruption of such data.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Electronic Communications'} id={'electronic-communications'}>
							<ArticleParagraph>
								Visiting the Services, sending us emails, and completing online forms constitute
								electronic communications. You consent to receive electronic communications and agree
								that all agreements, notices, disclosures, and other communications we provide to you
								electronically, via email and in the Services, satisfy any legal requirement that such
								communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
								CONTRACTS, ORDERS, AND OTHER RECORDS AND TO THE ELECTRONIC DELIVERY OF NOTICES,
								POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES.
								You hereby waive any rights or requirements under any statutes, regulations, rules,
								ordinances, or other laws in any jurisdiction which require an original signature or
								delivery or retention of non-electronic records, or to payments or the granting of
								credits by any means other than electronic means.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Residents of California'} id={'residents-of-california'}>
							<ArticleParagraph>
								If any complaint with us is not satisfactorily resolved, you can contact the Complaint
								Assistance Unit of the Division of Consumer Services of the California Department of
								Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento,
								California 95834 or by telephone at{' '}
								<DistressedUnderlineAnchor href={'tel:+18009525210'}>
									800-952-5210
								</DistressedUnderlineAnchor>{' '}
								or{' '}
								<DistressedUnderlineAnchor href={'tel:+19164451254'}>
									916-445-1254
								</DistressedUnderlineAnchor>
								.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Miscellaneous'} id={'miscellaneous'}>
							<ArticleParagraph>
								These Terms of Use and any policies or operating rules posted by us in the Services or
								in respect to the Services constitute the entire agreement and understanding between you
								and us. Our failure to exercise or enforce any right or provision of these Terms of Use
								shall not operate as a waiver of such right or provision. These Terms of Use operate to
								the fullest extent permissible by law. We may assign any or all of our rights and
								obligations to others at any time. We shall not be responsible or liable for any loss,
								damage, delay, or failure to act caused by any cause beyond our reasonable control. If
								any provision or part of a provision of these Terms of Use is determined to be unlawful,
								void, or unenforceable, that provision or part of the provision is deemed severable from
								these Terms of Use and does not affect the validity and enforceability of any remaining
								provisions. There is no joint venture, partnership, employment, or agency relationship
								created between you and us as a result of these Terms of Use or use of the Services. You
								agree that these Terms of Use will not be construed against us by virtue of having
								drafted them. You hereby waive any and all defenses you may have based on the electronic
								form of these Terms of Use and the lack of signing by the parties hereto to execute
								these Terms of Use.
							</ArticleParagraph>
						</ArticleSection>
						<ArticleSection header={'Contact Us'} id={'contact-us'}>
							<ArticleParagraph>
								To resolve a complaint regarding the Services or to receive further information
								regarding the use of the Services, please get in touch with us by email at{' '}
								<DistressedUnderlineAnchor href={'mailto:support@daresaygames.com'}>
									support@daresaygames.com
								</DistressedUnderlineAnchor>
								.
							</ArticleParagraph>
						</ArticleSection>
					</ArticleContent>
				</Article>
				<ArticleScrollToTop />
			</Main>
		</>
	);
}
