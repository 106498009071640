import { css, keyframes } from '@emotion/react';

export default function GradientBar() {
	return (
		<div
			css={css`
				position: absolute;
				top: 0;
				width: 100%;
				height: 3px;
				background: linear-gradient(
					120deg,
					var(--accent-color-b),
					var(--accent-color-g),
					var(--accent-color-b)
				);
				background-size: 200% auto;
				animation: ${keyframes`
					from {
						background-position-x: 0;
					}
				  
					to {
						background-position-x: 200%;
					}
				`} 2s linear infinite;
			`}
		/>
	);
}
