import { useId } from 'react';

import { useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { SentinelError } from '@daresay/api-client';

import TextPasswordInput from '../TextPasswordInput';
import StyledButton, { Style } from '../../buttons/core/StyledButton';
import { apiClient } from '../../../../common/api-client';

import type { ComponentPropsWithoutRef, BaseSyntheticEvent } from 'react';
import type { SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import type { SerializedStyles } from '@emotion/react';

interface FormValues {
	password: string;
}

interface Props extends Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
	token: string;
	onSubmitInvalid?: SubmitErrorHandler<FormValues>;
	onSubmitStart?: SubmitHandler<FormValues>;
	onSubmitError?: (error: Error, formData: FormValues, event: BaseSyntheticEvent | undefined) => void;
	onSubmitSuccess?: SubmitHandler<FormValues>;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function ResetPasswordForm(props: Props) {
	const { token, onSubmitInvalid, onSubmitStart, onSubmitError, onSubmitSuccess, cssExtra, ...delegatedProps } =
		props;

	let id = useId();
	if (delegatedProps.id) id = delegatedProps.id;

	const { handleSubmit, control } = useForm<FormValues>({ mode: 'onTouched' });

	const { executeRecaptcha } = useGoogleReCaptcha();

	const onSubmit: SubmitHandler<FormValues> = (formData, e) => {
		e?.preventDefault();

		onSubmitStart?.(formData, e);

		(async () => {
			if (!executeRecaptcha) throw new SentinelError('Unexpected error', '');
			const recaptchaToken = await executeRecaptcha('RESET_PASSWORD');

			return apiClient.put('/api/users/~/actions/reset-password', {
				token,
				password: formData.password,
				recaptchaToken,
			});
		})()
			.then(() => {
				onSubmitSuccess?.(formData, e);
			})
			.catch((error: Error) => {
				onSubmitError?.(error, formData, e);
			});
	};

	return (
		<>
			<form
				noValidate
				css={cssExtra}
				{...delegatedProps}
				id={id}
				onSubmit={e => {
					handleSubmit(
						onSubmit,
						onSubmitInvalid,
					)(e).catch(() => {
						/* ignore */
					});
				}}
			>
				<TextPasswordInput
					isNew
					controllerProps={{ name: 'password', control, defaultValue: '' }}
					labelProps={{ children: 'New password' }}
				/>
			</form>
			<StyledButton
				cssExtra={css`
					align-self: center;
					min-width: 128px;
					padding: 0.5em 2.25em 0.45em;
					margin-top: 10px;
					border-radius: 99rem;
				`}
				form={id}
				styleType={Style.RECT}
				type={'submit'}
			>
				Save
			</StyledButton>
		</>
	);
}
