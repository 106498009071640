import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';

import AuthCard from '../../components/layout/card/AuthCard';
import PolicyLinkSet from '../../components/layout/PolicyLinkSet';
import LoginForm from '../../components/input/forms/LoginForm';
import { rectButtonStyle } from '../../components/layout/card/Card';
import CardFooterNavLink from '../../components/layout/card/CardFooterNavLink';

import type { FormValues } from '../../components/input/forms/LoginForm';

export default function LoginCard() {
	const { register, handleSubmit, control, getValues } = useForm<FormValues>();

	return (
		<AuthCard
			alternativeLink={
				<CardFooterNavLink emphasis={'Sign up'} to={'/signup'}>
					Don’t have an account?
				</CardFooterNavLink>
			}
			authButtonPrefix={'Sign in with'}
			authMode={'login'}
			disclaimer={<PolicyLinkSet />}
			form={
				<LoginForm
					control={control}
					cssExtra={css`
						& ~ button[type='submit'] {
							${rectButtonStyle};
						}
					`}
					handleSubmit={handleSubmit}
					register={register}
				/>
			}
			gradientColors={['var(--accent-color-r)', 'var(--accent-color-v) 40%', 'var(--accent-color-b-alt-2)']}
			panelConfig={{
				accentColor: 'var(--accent-color-b-alt-1)',
				backgroundColor: 'var(--accent-color-r)',
				text: 'Login',
			}}
			remember={getValues('remember')}
			welcomeText={'Welcome back'}
		/>
	);
}
