import { css } from '@emotion/react';

import { boxShadow } from '../../styles';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import type { SerializedStyles } from '@emotion/react';

export enum Style {
	TEXT,
	RECT,
	HOVER_RECT,
}

export interface Props extends ComponentPropsWithoutRef<'button'> {
	children: ReactNode;
	styleType?: Style;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export const getStyle = (style?: Style) => {
	switch (style) {
		case Style.TEXT:
			return css`
				font-weight: 700;
				color: var(--primary-color);
				white-space: nowrap;
				cursor: pointer;
				transition: color var(--ease-time) var(--ease-fn), background-color var(--ease-time) var(--ease-fn),
					text-shadow var(--ease-time) var(--ease-fn);

				&:is(:link, :visited, :active, :hover) {
					color: inherit;
				}
			`;
		case Style.RECT:
			return css`
				padding: 0.315em 1.5em 0.3em;
				font-family: 'Europa', sans-serif;
				font-weight: 700;
				color: var(--background-color);
				text-align: center;
				white-space: nowrap;
				background-color: var(--primary-color);
				border-radius: var(--border-radius-1);
				transition: background-color var(--ease-time) var(--ease-fn), transform var(--ease-time) var(--ease-fn);

				&:hover {
					background-color: var(--accent-color-b-alt-0);
				}

				&:active {
					transform: scale(0.95);
				}

				&:is(:link, :visited, :hover, :active) {
					color: var(--background-color);
				}

				${boxShadow(2)}
			`;
		case Style.HOVER_RECT:
			return css`
				padding: 0.5em 1em;
				font-family: 'Europa', sans-serif;
				font-weight: 700;
				color: var(--primary-color);
				background-color: rgba(255, 255, 255, 0);
				border-radius: var(--border-radius-1);
				transition: color var(--ease-time) var(--ease-fn), background-color var(--ease-time) var(--ease-fn);

				&:hover {
					background-color: rgba(255, 255, 255, 0.05);
				}

				&:is(:link, :visited, :hover, :active) {
					color: var(--primary-color);
				}
			`;
		default:
			return undefined;
	}
};

export default function StyledButton(props: Props) {
	const { children, styleType, cssExtra, ...delegatedAttributes } = props;

	return (
		<button
			css={css`
				${getStyle(styleType)};
				${cssExtra};
			`}
			type={'button'}
			{...delegatedAttributes}
		>
			{children}
		</button>
	);
}
