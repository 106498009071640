import { forwardRef } from 'react';

import { css } from '@emotion/react';

import StyledNavLink, { Style } from '../../components/buttons/core/StyledNavLink';

import type { ComponentPropsWithoutRef, ReactElement } from 'react';
import type { SerializedStyles } from '@emotion/react';
import type { To } from 'react-router-dom';

export interface MenuItem {
	title: string;
	to: To;
}

interface Props extends ComponentPropsWithoutRef<'nav'> {
	menuItems: MenuItem[];
	children?: ReactElement | (ReactElement | null | undefined)[];
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const menuItemStyle = css`
	&:is(:link, :visited, :hover, :active).active {
		color: var(--accent-color-b-alt-1);
	}
`;

const AccountMenu = forwardRef<HTMLElement, Props>(function AccountMenu(props, ref) {
	const { menuItems, cssExtra, children, ...delegatedProps } = props;

	return (
		<nav
			ref={ref}
			css={css`
				display: flex;
				flex-direction: column;
				width: clamp(160px, 15vw, 240px);

				${cssExtra};
			`}
			{...delegatedProps}
		>
			{children}
			{menuItems.map(el => {
				return (
					<StyledNavLink key={el.title} cssExtra={menuItemStyle} styleType={Style.HOVER_RECT} to={el.to}>
						{el.title}
					</StyledNavLink>
				);
			})}
		</nav>
	);
});

export default AccountMenu;
