import { useState } from 'react';

import { css } from '@emotion/react';
import { Controller } from 'react-hook-form';

import PasswordHider from './PasswordHider';
import TextInput, { transformRegisterRules } from './core/TextInput';
import PasswordStrengthIndicator, { PasswordStrength } from './PasswordStrengthIndicator';

import type { FieldValues, ControllerProps } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form/dist/types';
import type { SerializedStyles } from '@emotion/react';
import type { ChangeEvent, ComponentPropsWithoutRef } from 'react';
import type { Require } from '@daresay/types';

interface Props<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
	controllerProps: Omit<Require<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'defaultValue'>, 'render'>;
	inputProps?: ComponentPropsWithoutRef<'input'>;
	labelProps?: ComponentPropsWithoutRef<'label'>;
	validateStrength?: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
	isNew?: boolean;
}

const evaluatePasswordPools = (password: string) => {
	const pools = {
		lowercase: /[a-z]+/.test(password) ? 26 : 0,
		uppercase: /[A-Z]+/.test(password) ? 26 : 0,
		number: /\d+/.test(password) ? 10 : 0,
		symbol: /[^A-Za-z\d]+/.test(password) ? 95 : 0,
	};

	return pools;
};

export default function TextPasswordInput<TFieldValues extends FieldValues = FieldValues>(props: Props<TFieldValues>) {
	const { controllerProps, inputProps, labelProps, cssExtra, validateStrength = true, isNew } = props;

	const [hide, setHide] = useState(true);

	const [passwordStrength, setPasswordStrength] = useState(PasswordStrength.NONE);

	const evaluatePasswordEntropy = (password: string) => {
		const pools = evaluatePasswordPools(password);

		const poolSize = Object.values(pools).reduce((sum, size) => sum + size);

		const combinations = password.length ** poolSize;

		return Math.log2(combinations);
	};

	const evaluatePasswordStrength = (password: string) => {
		if (!password) return PasswordStrength.NONE;
		if (password.length < 4) return PasswordStrength.WEAK;
		if (new Set(password).size < 4) return PasswordStrength.WEAK;

		const entropy = evaluatePasswordEntropy(password);

		if (entropy > 160 && password.length >= 8) return PasswordStrength.STRONG;
		if (entropy > 120 && password.length >= 8) return PasswordStrength.GREAT;
		if (entropy > 80) return PasswordStrength.GOOD;
		return PasswordStrength.WEAK;
	};

	const rules = transformRegisterRules('Password', {
		required: true,
		minLength: 8,
		validate: validateStrength
			? () => {
					if (passwordStrength < PasswordStrength.GOOD) {
						return 'Password is not strong enough (mix letters, numbers, and punctuation)';
					}

					return undefined;
			  }
			: undefined,
		...controllerProps.rules,
	});

	return (
		<Controller
			{...controllerProps}
			render={({ field, fieldState }) => {
				return (
					<TextInput
						cssExtra={css`
							& > input:first-of-type {
								padding-right: 2.5em;
							}

							${cssExtra};
						`}
						errorMessage={fieldState.error?.message}
						indicator={
							validateStrength ? <PasswordStrengthIndicator strength={passwordStrength} /> : undefined
						}
						inputProps={{
							type: hide ? 'password' : 'text',
							spellCheck: false,
							autoComplete: isNew ? 'new-password' : 'current-password',
							autoCorrect: 'off',
							...inputProps,
							...field,
							onChange: (e: ChangeEvent<HTMLInputElement>) => {
								field.onChange(e);
								inputProps?.onChange?.(e);

								const strength = evaluatePasswordStrength(e.currentTarget.value);
								setPasswordStrength(strength);
							},
						}}
						labelProps={{ children: 'Password', ...labelProps }}
					>
						<PasswordHider
							inputProps={{
								onChange: e => {
									setHide(!e.currentTarget.checked);
								},
							}}
						/>
					</TextInput>
				);
			}}
			rules={rules}
		/>
	);
}
