import { css } from '@emotion/react';

import Main from './view/Main';
import PongSpinner from '../graphics/animations/PongSpinner';
import GradientBar from '../graphics/animations/GradientBar';

import type { SerializedStyles } from '@emotion/react';

export interface Props {
	disableSpinner?: boolean;
	lightMode?: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const style = css`
	position: relative;

	&.light-mode {
		background: var(--muted-color-e);
	}
`;

export default function Fallback(props: Props) {
	const { disableSpinner, lightMode, cssExtra } = props;

	const classes = [];
	if (lightMode) {
		classes.push('light-content');
		document.documentElement.classList.add('light-content');
	}

	return (
		<Main
			className={classes.join(' ')}
			cssExtra={css`
				${style};
				${cssExtra};
			`}
		>
			<GradientBar />
			{disableSpinner ? null : <PongSpinner />}
		</Main>
	);
}
