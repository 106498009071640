import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

import ScrollToTop from './ScrollToTop';
import { svgShadow } from '../styles';
import UpArrowGraphic from '../../../../public/images/ui/up-circle.svg';

const MIN_TOP = 800;

const shouldShow = () => window.scrollY > MIN_TOP;

export default function ArticleScrollToTop() {
	const [show, setShow] = useState(shouldShow());

	const onScroll = () => {
		setShow(shouldShow());
	};

	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	return (
		<CSSTransition
			unmountOnExit
			addEndListener={(node, done) => {
				node.addEventListener('transitionend', done, false);
			}}
			classNames={'fade'}
			in={show}
		>
			<ScrollToTop
				className={'slow'}
				css={css`
					bottom: 20px;
					display: flex;
					column-gap: 0.75em;
					align-items: center;
					margin: 0 clamp(24px, 4vw, 40px) 16px 0;
					font-weight: 400;
					color: var(--muted-color-4);

					@media (max-width: 1200px) {
						margin-bottom: 24px;
					}

					&:hover {
						color: var(--accent-color-b);
					}
				`}
			>
				<span
					css={css`
						position: relative;
						top: 0.025em;
						font-size: 0.95em;

						@media (max-width: 1200px) {
							display: none;
						}
					`}
				>
					Float to surface
				</span>
				<UpArrowGraphic
					css={[
						css`
							height: 1.5em;
							transition: color var(--ease-time) var(--ease-fn);

							& [opacity='0'] {
								opacity: 1;
								fill: var(--muted-color-e);
							}

							@media (max-width: 1200px) {
								font-size: 1.25em;
							}
						`,
						svgShadow(1),
					]}
				/>
			</ScrollToTop>
		</CSSTransition>
	);
}
