import { css } from '@emotion/react';

import { decimalToFraction } from '../../common/helpers';

import type { SerializedStyles } from '@emotion/react';

type LayerCount = 1 | 2 | 3 | 4;

const getShadowLayers = (layers: LayerCount) => {
	const boxShadows = [];
	for (let i = 0; i < layers; i += 1) {
		const x = 0.75 * 2 ** i;
		boxShadows.push(`${x}px ${2 * x}px ${2 * x}px hsl(0deg 0% 0% / 50%)`);
	}

	return boxShadows;
};

export const boxShadow = (layers: LayerCount) => {
	const shadowLayers = getShadowLayers(layers);

	return css`
		box-shadow: ${shadowLayers.join(', ')};
	`;
};

export const svgShadow = (layers: LayerCount) => {
	const shadowLayers = getShadowLayers(layers);

	return css`
		filter: ${shadowLayers.map(layer => `drop-shadow(${layer})`).join(' ')};
	`;
};

export const aspectRatioWithFallback = (ratio: number) => {
	const [w, h] = decimalToFraction(ratio);

	return css`
		aspect-ratio: 1/1;

		@supports not (aspect-ratio: auto) {
			&::before {
				float: left;
				padding-top: calc(100% * ${h} / ${w});
				content: '';
			}

			&::after {
				display: block;
				content: '';
				clear: both;
			}
		}
	`;
};

export const gridStyleWithCenteredRows = (columns: number) => {
	const span = 2;
	const rowCenter = (columns * span) / 2;

	const styles: Array<SerializedStyles> = [
		css`
			grid-column: span ${span};
		`,
	];

	for (let i = 1; i < columns; i += 1) {
		const rowStart = rowCenter - (i * span) / 2;
		for (let j = 1; j <= i; j += 1) {
			const k = i - j + 1;
			styles.push(css`
				&:nth-of-type(${columns}n + ${j})${k === 1 ? ':last-of-type' : `:nth-last-of-type(${k})`} {
					grid-column-end: ${1 + rowStart + span * j};
				}
			`);
		}
	}

	return [
		css`
			display: grid;
			grid-template-columns: repeat(${columns * span}, 1fr);
		`,
		css(styles),
	];
};
