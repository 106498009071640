import { css } from '@emotion/react';

import LDRAnimation from '../components/graphics/animations/LDRAnimation';

export default function BannerDivider() {
	return (
		<div
			css={css`
				height: 32px;
				overflow: hidden;
			`}
		>
			<LDRAnimation
				cssExtra={css`
					left: 50%;
					font-size: 6px;
					transform: translateX(-50%);
				`}
			/>
		</div>
	);
}
