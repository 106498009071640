import { css } from '@emotion/react';

import type { SerializedStyles } from '@emotion/react';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	children: ReactNode;
	ratio: number;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function AspectRatioBox(props: Props) {
	const { children, ratio, cssExtra, ...delegatedAttributes } = props;

	return (
		<div
			css={css`
				position: relative;
				padding-top: ${ratio * 100}%;

				> * {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}

				${cssExtra}
			`}
			{...delegatedAttributes}
		>
			{children}
		</div>
	);
}
