import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { isAuthKey } from '../../../../types';
import { useUserPromise } from '../../../hooks/user';

export default function SignupSuccessPage() {
	const currentParams = new URLSearchParams(window.location.search);
	const redirectUri = currentParams.get('redirect_uri') ?? '/';
	const strategy = currentParams.get('strategy');

	const [user, userError] = useUserPromise(5000);

	const navigate = useNavigate();

	useEffect(() => {
		if (userError) throw userError;
		if (user) {
			if (strategy && isAuthKey(strategy)) {
				window.dataLayer.push({ event: 'sign_up', method: strategy, user_id: user._id });
			}

			navigate(redirectUri);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, userError]);

	return null;
}
