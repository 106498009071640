import { css } from '@emotion/react';

import StyledButton, { Style } from '../../buttons/core/StyledButton';
import Dot from '../../graphics/shapes/Dot';

import type { SerializedStyles } from '@emotion/react';

interface Props {
	message?: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function ErrorContent(props: Props) {
	const { message = 'There was an unexpected error.', cssExtra } = props;

	return (
		<div css={cssExtra}>
			<div
				css={css`
					display: flex;
					align-items: center;
				`}
			>
				<Dot
					cssExtra={css`
						padding-bottom: 0.5em;
						margin-right: 1.5em;
						color: var(--accent-color-r);
					`}
				/>
				<span>{message}</span>
			</div>
			<StyledButton
				css={css`
					margin: 1em 0;
				`}
				styleType={Style.RECT}
				onClick={() => {
					window.location.reload();
				}}
			>
				Reload
			</StyledButton>
		</div>
	);
}
