import { useState } from 'react';

import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';

import Card, { rectButtonStyle } from '../../../components/layout/card/Card';
import CardDaresayLogo from '../../../components/layout/card/CardDaresayLogo';
import PolicyLinkSet from '../../../components/layout/PolicyLinkSet';
import ResetPasswordRequestForm from '../../../components/input/forms/ResetPasswordRequestForm';
import BoxNotification from '../../../components/layout/notification/BoxNotification';
import { NotificationContext, useNotificationDispatch } from '../../../hooks/notification';
import LockGraphic from '../../../../../public/images/ui/lock.svg';
import CardHeading from '../../../components/layout/card/CardHeading';
import CardDancingCirclesSpinner from '../../../components/layout/card/CardDancingCirclesSpinner';
import CardFooter from '../../../components/layout/card/CardFooter';
import CardFooterNavLink from '../../../components/layout/card/CardFooterNavLink';
import CardPanel from '../../../components/layout/card/CardPanel';
import CardMain from '../../../components/layout/card/CardMain';
import CardMainSection from '../../../components/layout/card/CardMainSection';

const parseLoginHelpError = (error: Error): string | undefined => {
	Sentry.captureException(error);
	return undefined;
};

export default function LoginHelpCard() {
	const notificationDispatch = useNotificationDispatch();
	const { setNotification, removeNotification } = notificationDispatch;

	const [inProgress, setInProgress] = useState(false);

	return (
		<NotificationContext.Provider value={notificationDispatch}>
			<Card
				cssExtra={css`
					display: flex;
					flex-grow: 0.15;
				`}
			>
				<CardPanel>
					<CardDaresayLogo />

					<BoxNotification
						cssExtra={css`
							--close-size: 7px;

							margin-top: -6px;
							margin-bottom: -10px;

							&.empty {
								margin-top: -12px;
								margin-bottom: -20px;
							}
						`}
						substitute={
							<LockGraphic
								css={css`
									width: 1.65em;
									height: 1.65em;
									margin: auto;
									color: var(--accent-color-b-alt-3);
								`}
							/>
						}
					/>

					<CardMain>
						<CardHeading>Forgot your password?</CardHeading>

						<CardMainSection
							description={
								"Enter the email address for your account, and we'll send you a password reset link."
							}
						>
							<ResetPasswordRequestForm
								cssExtra={css`
									& + button[type='submit'] {
										${rectButtonStyle};
									}
								`}
								onSubmitError={error => {
									setInProgress(false);
									setNotification({
										message: parseLoginHelpError(error) ?? 'An unexpected error has occurred',
										type: 'error',
									});
								}}
								onSubmitStart={() => {
									setInProgress(true);
									removeNotification();
								}}
								onSubmitSuccess={data => {
									setInProgress(false);
									setNotification({
										type: 'success',
										message: `We sent details to ${data.email} for resetting your password`,
									});
								}}
							/>

							<CardDancingCirclesSpinner show={inProgress} />
						</CardMainSection>
					</CardMain>
					<CardFooter>
						<CardFooterNavLink emphasis={'Return to login'} to={'/login'}>
							Figure it out?
						</CardFooterNavLink>
						<PolicyLinkSet />
					</CardFooter>
				</CardPanel>
			</Card>
		</NotificationContext.Provider>
	);
}
