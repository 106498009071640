import { css } from '@emotion/react';

import NotificationContainerMain from '../notification/NotificationContainerMain';

import type { ComponentPropsWithoutRef, ReactElement } from 'react';
import type { SerializedStyles } from '@emotion/react';

export interface Props extends ComponentPropsWithoutRef<'main'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
	children?: ReactElement | (ReactElement | null | undefined)[];
}

export default function Main(props: Props) {
	const { cssExtra, children, ...delegatedAttributes } = props;

	return (
		<main
			css={css`
				position: relative;
				width: 100%;
				min-height: calc(100vh - var(--header-height) - var(--footer-height));

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			<NotificationContainerMain />
			{children}
		</main>
	);
}
