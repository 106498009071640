import { css } from '@emotion/react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { useNotification } from '../../../hooks/notification';
import Notification from './Notification';
import { useLocationChange } from '../../../hooks/router';

import type { SerializedStyles } from '@emotion/react';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function NotificationContainerMain(props: Props) {
	const { cssExtra, ...delegatedProps } = props;

	const { notification, removeNotification } = useNotification();

	useLocationChange((location, previousLocation) => {
		// console.log('changed from', previousLocation, 'to', location);
		if (location === previousLocation) return;
		removeNotification();
	});

	if (!notification) return null;

	return (
		<div
			css={css`
				position: fixed;
				top: var(--header-height);
				display: flex;
				flex-direction: column;
				width: 100%;

				${cssExtra};
			`}
			{...delegatedProps}
		>
			<SwitchTransition mode={'out-in'}>
				<CSSTransition
					key={notification.id}
					unmountOnExit
					addEndListener={(node, done) => {
						node.addEventListener('transitionend', done, false);
					}}
					classNames={'notification'}
				>
					<Notification notification={notification} removeNotification={removeNotification} />
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
}
