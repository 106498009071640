import { css } from '@emotion/react';

import WavesBackground from '../graphics/vectors/WavesBackground';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';
import type { Props as WavesBackgroundProps } from '../graphics/vectors/WavesBackground';

export interface Props extends ComponentPropsWithoutRef<'div'> {
	colors: WavesBackgroundProps['colors'];
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function WavesBackgroundScaled(props: Props) {
	const { children, cssExtra, colors, ...delegatedAttributes } = props;
	return (
		<div
			css={css`
				position: absolute;
				width: 100%;
				height: 100%;
				overflow: hidden;

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			<WavesBackground colors={colors} height={'100%'} preserveAspectRatio={'xMidYMid slice'} width={'100%'} />
			{children}
		</div>
	);
}
