import { css } from '@emotion/react';

import DaresayLink from '../../../buttons/DaresayLink';
import { DaresayLogoType } from '../../../graphics/vectors/DaresayLogo';
import PolicyLinkSet from '../../PolicyLinkSet';
import SocialLinkSet from '../../SocialLinkSet';
import Dot from '../../../graphics/shapes/Dot';

export default function Footer() {
	return (
		<footer
			css={css`
				z-index: 99;
				display: flex;
				flex-wrap: nowrap;
				row-gap: 24px;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: var(--footer-height);
				padding: 0 var(--padding-h-main);
				text-rendering: optimizeLegibility;
				background-color: var(--background-color);

				& > {
					flex-shrink: 0;
				}

				@media (max-width: 225px) {
					flex-direction: column;
					justify-content: center;
				}
			`}
		>
			<DaresayLink
				invert
				cssExtra={css`
					@media (max-width: 225px) {
						display: none;
					}
				`}
				height={'22px'}
				type={DaresayLogoType.LETTERMARK}
			/>
			<DaresayLink
				invert
				cssExtra={css`
					display: none;

					@media (max-width: 225px) {
						display: block;
					}
				`}
				height={'22px'}
				type={DaresayLogoType.WORDMARK}
			/>
			<div
				css={css`
					display: flex;
					flex-wrap: nowrap;
					column-gap: 26px;
					align-items: center;
					justify-content: space-between;

					& > {
						flex-shrink: 0;
					}
				`}
			>
				<PolicyLinkSet
					cssExtra={css`
						& a:is(:link, :visited, :active) {
							color: var(--muted-color-1);
						}

						& a:hover {
							color: var(--primary-color);
						}

						@media (max-width: 500px) {
							display: none;
						}
					`}
				/>
				<Dot
					cssExtra={css`
						@media (max-width: 225px) {
							display: none;
						}
					`}
				/>
				<SocialLinkSet />
			</div>
		</footer>
	);
}
