import { css } from '@emotion/react';

import type { SerializedStyles } from '@emotion/react';
import type { ComponentPropsWithoutRef } from 'react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function CardFooterSection(props: Props) {
	const { cssExtra, ...delegatedProps } = props;
	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				row-gap: 10px;
				align-items: center;

				${cssExtra};
			`}
			{...delegatedProps}
		/>
	);
}
