import { useId } from 'react';

import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { SentinelError } from '@daresay/api-client';

import StyledButton, { Style } from '../../buttons/core/StyledButton';
import TextPasswordInput from '../TextPasswordInput';
import { useUser } from '../../../hooks/user';
import StyledNavLink from '../../buttons/core/StyledNavLink';

import type { ComponentPropsWithoutRef, BaseSyntheticEvent, ReactElement } from 'react';
import type { SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import type { SerializedStyles } from '@emotion/react';

interface FormValues {
	currentPassword: string;
	newPassword: string;
}

interface Props extends Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
	onSubmitInvalid?: SubmitErrorHandler<FormValues>;
	onSubmitStart?: SubmitHandler<FormValues>;
	onSubmitError?: (error: Error, formData: FormValues, event: BaseSyntheticEvent | undefined) => void;
	onSubmitSuccess?: SubmitHandler<FormValues>;
	renderSubmitButton?: (submitButton: ReactElement) => ReactElement | (ReactElement | undefined)[];
	cssExtra?: SerializedStyles | SerializedStyles[];
}
export default function ChangePasswordForm(props: Props) {
	const {
		onSubmitInvalid,
		onSubmitStart,
		onSubmitError,
		onSubmitSuccess,
		renderSubmitButton,
		cssExtra,
		...delegatedProps
	} = props;

	let id = useId();
	if (delegatedProps.id) id = delegatedProps.id;

	const { handleSubmit, control } = useForm<FormValues>({ mode: 'onTouched' });

	const { updateDaresayPassword } = useUser();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const onSubmit: SubmitHandler<FormValues> = (formData, e) => {
		e?.preventDefault();

		onSubmitStart?.(formData, e);

		(async () => {
			if (!executeRecaptcha) throw new SentinelError('Unexpected error', '');
			const recaptchaToken = await executeRecaptcha('UPDATE_PASSWORD');

			return updateDaresayPassword({
				password: formData.newPassword,
				currentPassword: formData.currentPassword,
				recaptchaToken,
			});
		})()
			.then(() => {
				onSubmitSuccess?.(formData, e);
			})
			.catch((error: Error) => {
				onSubmitError?.(error, formData, e);
			});
	};

	return (
		<>
			<form
				noValidate
				css={cssExtra}
				{...delegatedProps}
				id={id}
				onSubmit={e => {
					handleSubmit(
						onSubmit,
						onSubmitInvalid,
					)(e).catch(() => {
						/* ignore */
					});
				}}
			>
				<TextPasswordInput
					controllerProps={{ name: 'currentPassword', control, defaultValue: '' }}
					labelProps={{ children: 'Current password' }}
					validateStrength={false}
				/>
				<TextPasswordInput
					isNew
					controllerProps={{ name: 'newPassword', control, defaultValue: '' }}
					labelProps={{ children: 'New password' }}
				/>
				<StyledNavLink
					className={'login-help'}
					cssExtra={css`
						margin-left: auto;
						font-size: calc(0.85em / 0.9);
						font-weight: 400;

						&:is(:link, :visited, :active) {
							color: var(--primary-color);
						}

						&:hover {
							color: var(--accent-color-b-alt-0);
						}
					`}
					styleType={Style.TEXT}
					to={'/login/help'}
				>
					Forgot your password?
				</StyledNavLink>
			</form>

			{(() => {
				const button = (
					<StyledButton
						cssExtra={css`
							min-width: 96px;
							margin-top: 0.5em;
						`}
						form={id}
						styleType={Style.RECT}
						type={'submit'}
					>
						Save
					</StyledButton>
				);

				return renderSubmitButton ? renderSubmitButton(button) : button;
			})()}
		</>
	);
}
