import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { usePrevious } from './common';

import type { Location } from 'react-router-dom';

export const useLocationChange = (callback: (location: Location, previousLocation: Location | undefined) => void) => {
	const location = useLocation();
	const prevLocation = usePrevious(location);
	useEffect(() => {
		callback(location, prevLocation);
	}, [location]);
};
