import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

import Main from '../../components/layout/view/Main';
import SetMeta from '../../components/utils/SetMeta';
import CardContainer from '../../components/layout/card/CardContainer';
import ContactCard from './ContactCard';

export default function ContactPage() {
	return (
		<>
			<SetMeta
				description={'Want to get in touch with us? Contact our team for support and general inquiries.'}
				title={'Contact Us'}
			/>
			<Main
				cssExtra={css`
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				`}
			>
				<CardContainer
					cssExtra={css`
						flex-grow: 1;
					`}
				>
					<CSSTransition
						appear
						in
						unmountOnExit
						addEndListener={(node, done) => {
							node.addEventListener('transitionend', done, false);
						}}
						classNames={'modal-card'}
					>
						<ContactCard />
					</CSSTransition>
				</CardContainer>
			</Main>
		</>
	);
}
