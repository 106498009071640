import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

import DaresayLink from '../../../buttons/DaresayLink';
import { DaresayLogoType } from '../../../graphics/vectors/DaresayLogo';
import HeaderNav from './HeaderNav';
import StyledNavLink, { Style } from '../../../buttons/core/StyledNavLink';
import HeaderNavMenu from './HeaderNavMenu';
import HeaderAccountDropdown from './HeaderAccountDropdown';
import { useUser } from '../../../../hooks/user';

import type { ReactElement } from 'react';

function HeaderTextLink(props: { children: string | ReactElement; to: string }) {
	const { children, to } = props;

	return (
		<StyledNavLink
			cssExtra={css`
				&:hover {
					color: var(--accent-color-b-alt-0);
				}
			`}
			styleType={Style.TEXT}
			to={to}
		>
			{children}
		</StyledNavLink>
	);
}

function HeaderAuthButtonSet() {
	return (
		<HeaderNav>
			<HeaderTextLink to={'/signup'}>Sign up</HeaderTextLink>
			<StyledNavLink styleType={Style.RECT} to={'/login'}>
				Log in
			</StyledNavLink>
		</HeaderNav>
	);
}

const determineShowNav = () => window.innerWidth >= 450;
const determineShowNavFull = () => window.innerWidth >= 800;

export default function Header() {
	const [showNav, setShowNav] = useState(determineShowNav());
	const [showNavFull, setShowNavFull] = useState(determineShowNavFull());

	const { user } = useUser();

	useEffect(() => {
		const resizeHandler = () => {
			setShowNav(determineShowNav());
			setShowNavFull(determineShowNavFull());
		};

		window.addEventListener('resize', resizeHandler);
		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	}, []);

	return (
		<header
			css={css`
				position: fixed;
				top: 0;
				z-index: 199;
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: var(--header-height);
				padding: 0 var(--padding-h-main);
				text-rendering: optimizeLegibility;
				background-color: var(--background-color);
			`}
		>
			<DaresayLink
				invert
				aria-label={'home'}
				cssExtra={css`
					margin-right: 24px;
				`}
				height={'32px'}
				type={DaresayLogoType.WORDMARK_FULL}
			/>
			<CSSTransition
				appear
				unmountOnExit
				addEndListener={(node, done) => {
					node.addEventListener('transitionend', done, false);
				}}
				classNames={'slide-left'}
				in={user !== undefined}
			>
				<div
					css={css`
						display: flex;
						column-gap: 28px;
						align-items: center;
					`}
				>
					{showNav && (
						<HeaderNav>
							{showNavFull && (
								<HeaderNav>
									<HeaderTextLink to={'/contact'}>Contact</HeaderTextLink>
									<span
										css={css`
											cursor: default;
											user-select: none;
										`}
									>
										|
									</span>
								</HeaderNav>
							)}
							{(() => {
								if (user === false) return <HeaderAuthButtonSet />;
								if (user === undefined) return null;
								return <HeaderAccountDropdown />;
							})()}
						</HeaderNav>
					)}
					{!showNavFull && <HeaderNavMenu />}
				</div>
			</CSSTransition>
		</header>
	);
}
