import { useState } from 'react';

import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

import AuthButton, { AuthProvider, getProviderString } from '../buttons/AuthButton';
import { underlineStyle } from '../buttons/DistressedUnderlineAnchor';
import AuthNavLink from '../buttons/AuthNavLink';
import { gridStyleWithCenteredRows } from '../styles';

import type { SerializedStyles } from '@emotion/react';

interface Props {
	prefix: string;
	mode: 'login' | 'signup' | 'connect';
	redirectUri: string | ((strategy: string) => string);
	failureRedirectUri: string | ((strategy: string) => string);
	remember?: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export const config: { provider: AuthProvider; strategy: string; signupTo?: string }[] = [
	{ provider: AuthProvider.GOOGLE, strategy: 'google' },
	{ provider: AuthProvider.DISCORD, strategy: 'discord' },
	// { provider: AuthProvider.APPLE, strategy: 'apple' },
	// { provider: AuthProvider.STEAM, strategy: 'steam' },
	{ provider: AuthProvider.REDDIT, strategy: 'reddit', signupTo: '/signup/reddit' },
	{ provider: AuthProvider.TWITCH, strategy: 'twitch' },
	{ provider: AuthProvider.FACEBOOK, strategy: 'facebook' },
];

const [gridStyle5, gridChildStyle5] = gridStyleWithCenteredRows(5);
const [gridStyle3, gridChildStyle3] = gridStyleWithCenteredRows(3);

const buttonStyle = css`
	min-width: 28px;
	//max-width: 45px;
	min-height: 28px;
	border-radius: var(--border-radius-0);

	${gridChildStyle5};

	@media (max-width: 300px) {
		${gridChildStyle3};
	}
`;

export default function AuthButtonSet(props: Props) {
	const { prefix, mode, redirectUri, failureRedirectUri, remember, cssExtra, ...delegatedAttributes } = props;

	const [hovered, setHovered] = useState<AuthProvider | null>(null);
	const [hide, setHide] = useState(true);
	const [rand, setRand] = useState(0);

	const hoveredStr = hovered !== null ? getProviderString(hovered) : '';

	const OnMouseEnter = (provider: AuthProvider) => () => {
		setHovered(provider);
		setHide(false);
		setRand(Math.random());
	};

	const onMouseLeave = () => {
		setHide(true);
	};

	return (
		<div
			css={[
				css`
					display: flex;
					flex-direction: column;
					row-gap: 16px;
				`,
				cssExtra,
			]}
			{...delegatedAttributes}
		>
			<div
				css={css`
					white-space: nowrap;
					cursor: default;
				`}
			>
				<span>{prefix}</span>
				<CSSTransition
					unmountOnExit
					addEndListener={(node, done) => {
						node.addEventListener('transitionend', done, false);
					}}
					classNames={'fade'}
					in={!hide}
				>
					<span
						css={[
							css`
								padding: 0 0.15em;
								margin-left: 0.3em;
								color: var(--accent-color-b);
							`,
							underlineStyle(true, Math.floor(rand * 11) * 10),
						]}
					>
						{hoveredStr}
					</span>
				</CSSTransition>
			</div>
			<div
				// TODO: media query should reduce this gap (or calc)
				css={css`
					grid-auto-rows: 28px;
					row-gap: 18px;
					column-gap: min(6vw, 24px);

					${gridStyle5};

					@media (max-width: 300px) {
						column-gap: min(8vw, 24px);

						${gridStyle3};
					}
				`}
			>
				{(() => {
					return config.map(el => {
						const _redirectUri = typeof redirectUri === 'function' ? redirectUri(el.strategy) : redirectUri;
						const _failureRedirectUri =
							typeof failureRedirectUri === 'function'
								? failureRedirectUri(el.strategy)
								: failureRedirectUri;

						const params = new URLSearchParams({
							mode,
							redirect_uri: new URL(_redirectUri, window.location.href).href,
							failure_redirect_uri: new URL(_failureRedirectUri, window.location.href).href,
						});

						if (remember) params.set('remember', '');

						if (el.signupTo && mode === 'signup') {
							return (
								<AuthNavLink
									key={el.provider}
									cssExtra={buttonStyle}
									prefix={prefix}
									provider={el.provider}
									to={el.signupTo}
									onMouseEnter={OnMouseEnter(el.provider)}
									onMouseLeave={onMouseLeave}
								/>
							);
						}

						return (
							<AuthButton
								key={el.provider}
								cssExtra={buttonStyle}
								href={`/api/auth/${el.strategy}?${params.toString()}`}
								prefix={prefix}
								provider={el.provider}
								onMouseEnter={OnMouseEnter(el.provider)}
								onMouseLeave={onMouseLeave}
							/>
						);
					});
				})()}
			</div>
		</div>
	);
}
