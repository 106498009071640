import { useEffect, useId } from 'react';

import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { SentinelError } from '@daresay/api-client';

import TextInput, { transformRegisterRules } from '../core/TextInput';
import TextEmailInput from '../TextEmailInput';
import StyledButton, { Style } from '../../buttons/core/StyledButton';
import { apiClient } from '../../../../common/api-client';
import TextAreaInput from '../core/TextAreaInput';
import { useUser } from '../../../hooks/user';

import type { SerializedStyles } from '@emotion/react';
import type { BaseSyntheticEvent, ComponentPropsWithoutRef } from 'react';
import type { SubmitErrorHandler, SubmitHandler } from 'react-hook-form';

interface FormValues {
	email: string;
	subject: string;
	body: string;
}

interface Props extends Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
	onSubmitInvalid?: SubmitErrorHandler<FormValues>;
	onSubmitStart?: SubmitHandler<FormValues>;
	onSubmitError?: (error: Error, formData: FormValues, event: BaseSyntheticEvent | undefined) => void;
	onSubmitSuccess?: SubmitHandler<FormValues>;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function ContactForm(props: Props) {
	const { onSubmitInvalid, onSubmitStart, onSubmitError, onSubmitSuccess, cssExtra, ...delegatedProps } = props;

	let id = useId();
	if (delegatedProps.id) id = delegatedProps.id;

	const { user } = useUser();

	const { register, handleSubmit, formState, setValue, control } = useForm<FormValues>({
		defaultValues: { email: user ? user.auth.daresay.email : '', body: '' },
	});

	const { executeRecaptcha } = useGoogleReCaptcha();

	useEffect(() => {
		if (!user) return;

		setValue('email', user.auth.daresay.email);
	}, [setValue, user]);

	const onSubmit: SubmitHandler<FormValues> = (formData, e) => {
		e?.preventDefault();

		onSubmitStart?.(formData, e);

		(async () => {
			if (!executeRecaptcha) throw new SentinelError('Unexpected error', '');
			const recaptchaToken = await executeRecaptcha('CONTACT');

			return apiClient.post('/api/users/~/actions/contact', { ...formData, recaptchaToken });
		})()
			.then(() => {
				onSubmitSuccess?.(formData, e);
			})
			.catch((error: Error) => {
				onSubmitError?.(error, formData, e);
			});
	};

	return (
		<>
			<form
				noValidate
				css={css`
					display: flex;
					flex-direction: column;
					row-gap: inherit;
					align-items: stretch;
					justify-content: space-between;

					${cssExtra};
				`}
				{...delegatedProps}
				id={id}
				onSubmit={e => {
					handleSubmit(
						onSubmit,
						onSubmitInvalid,
					)(e).catch(() => {
						/* ignore */
					});
				}}
			>
				<TextEmailInput
					controllerProps={{ name: 'email', control, defaultValue: user ? user.auth.daresay.email : '' }}
					inputProps={user ? { readOnly: true } : undefined}
				/>
				<TextInput
					errorMessage={formState.errors.subject?.message}
					inputProps={register(
						'subject',
						transformRegisterRules('Subject', { required: true, maxLength: 120 }),
					)}
					labelProps={{ children: 'Subject' }}
				/>
				<TextAreaInput
					fieldError={formState.errors.body}
					inputProps={register(
						'body',
						transformRegisterRules('Message', { required: true, minLength: 60, maxLength: 1200 }),
					)}
					labelProps={{ children: 'Message' }}
				/>
			</form>
			<StyledButton
				cssExtra={css`
					align-self: center;
					min-width: 128px;
					padding: 0.5em 2.25em 0.45em;
					margin-top: 20px;
					border-radius: 99rem;
				`}
				form={id}
				styleType={Style.RECT}
				type={'submit'}
			>
				Send
			</StyledButton>
		</>
	);
}
