import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef, ReactElement } from 'react';
import type { SerializedStyles } from '@emotion/react';

export interface Props extends ComponentPropsWithoutRef<'div'> {
	label: string;
	description?: ReactElement | ReactElement[] | string;
	content?: ReactElement | ReactElement[];
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export const descriptionStyle = css`
	font-size: 0.9em;
	color: var(--muted-color-2);
`;

export default function AccountActionContainer(props: Props) {
	const { cssExtra, label, description, content, children, ...delegatedAttributes } = props;

	let descriptionElement: ReactElement | undefined;

	if (!description) descriptionElement = undefined;
	else if (typeof description === 'string') descriptionElement = <p css={descriptionStyle}>{description}</p>;
	else descriptionElement = <div css={descriptionStyle}>{description}</div>;

	return (
		<div
			className={'action-container'}
			css={css`
				display: flex;
				gap: 2em;
				align-items: center;
				justify-content: space-between;
				padding-bottom: 1.5em;
				margin: 1.5em 0;

				${cssExtra}
			`}
			{...delegatedAttributes}
		>
			<div
				css={css`
					flex-grow: 999;
				`}
			>
				<h4
					css={css`
						margin-bottom: 0.25em;
						font-size: 1em;
					`}
				>
					{label}
				</h4>
				{descriptionElement}
				{content}
			</div>
			{children}
		</div>
	);
}
