import { useRef } from 'react';

import { css, keyframes } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';
import * as Sentry from '@sentry/react';

import DaresayLink from '../buttons/DaresayLink';
import { DaresayLogoType } from '../graphics/vectors/DaresayLogo';
import SideMenuTextLink from '../buttons/SideMenuTextLink';
import CardHorizontalRule from './card/CardHorizontalRule';
import SocialLinkSet from './SocialLinkSet';
import PolicyLinkSet from './PolicyLinkSet';
import StaggerHandler from '../../../common/stagger-handler';
import { boxShadow } from '../styles';
import { useUser } from '../../hooks/user';
import SideMenuTextButton from '../buttons/SideMenuTextButton';
import { useClickOutside } from '../../hooks/dom';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'nav'> {
	checked: boolean;
	onClickOutside?: (event: MouseEvent) => void;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

const smallLinkStyle = css`
	font-size: 0.85em;
`;

export default function SideMenu(props: Props) {
	const { checked, onClickOutside, cssExtra, ...delegatedAttributes } = props;

	const { user, logout } = useUser();

	const ref = useClickOutside(onClickOutside);

	const staggerHandler = useRef(
		new StaggerHandler({
			initialDelay: 100,
			keyframes: keyframes`
				from {
					opacity: 0;
					transform: translateX(50px);
				}

				to {
					opacity: 1;
					transform: translateX(0);
				}
			`,
			stylesFn: (staggerDelay, animation) =>
				css`
					animation: ${animation} var(--ease-time) var(--ease-fn-slide) ${staggerDelay}ms both;
				`,
		}),
	);

	// useEffect(() => {
	// 	staggerHandler.current.reset();
	// }, [user]);

	if (!checked) staggerHandler.current.reset();

	return (
		<CSSTransition
			appear
			unmountOnExit
			addEndListener={(node, done) => {
				node.addEventListener('transitionend', done, false);
			}}
			classNames={'slide-left'}
			in={checked}
		>
			<nav
				ref={ref}
				css={css`
					position: absolute;
					top: 0;
					right: 0;
					z-index: 10;
					display: flex;
					flex-direction: column;
					row-gap: 90px;
					justify-content: space-between;
					width: var(--side-menu-width);
					height: 100vh;
					padding: 120px calc(var(--padding-h-main) + 12px) 30px;
					color: var(--muted-color-6);
					background: var(--muted-color-0);
					border-radius: var(--border-radius-0) 0 0 var(--border-radius-0);

					${boxShadow(4)};

					${cssExtra};
				`}
				{...delegatedAttributes}
			>
				<DaresayLink
					invert
					aria-label={'home'}
					cssExtra={css`
						position: absolute;
						top: calc((var(--header-height) - 32px) / 2);
						left: var(--padding-h-main);

						${staggerHandler.current.staggerStyle};

						&:is(:link, :visited, :hover, :active) {
							color: var(--muted-color-6);
						}
					`}
					fill={'var(--muted-color-6)'}
					height={'32px'}
					type={DaresayLogoType.WORDMARK_FULL}
				/>
				<div
					css={css`
						display: flex;
						flex-direction: column;
						row-gap: 10px;
						font-size: 1.15em;
					`}
				>
					{user ? (
						<>
							<SideMenuTextLink
								cssExtra={css`
									${smallLinkStyle};
									${staggerHandler.current.staggerStyle};
								`}
								to={'/account'}
							>
								Account
							</SideMenuTextLink>
							<SideMenuTextButton
								cssExtra={css`
									${smallLinkStyle};
									${staggerHandler.current.staggerStyle};

									&:hover {
										color: var(--accent-color-r);
									}
								`}
								onClick={() => {
									logout()
										.then(() => {
											window.location.href = '/';
										})
										.catch(error => {
											// TODO: display error
											Sentry.captureException(error);
											window.location.href = '/';
										});
								}}
							>
								Log out
							</SideMenuTextButton>
						</>
					) : (
						<>
							<SideMenuTextLink
								cssExtra={css`
									${smallLinkStyle};
									${staggerHandler.current.staggerStyle};
								`}
								to={'/login'}
							>
								Log in
							</SideMenuTextLink>
							<SideMenuTextLink
								cssExtra={css`
									${smallLinkStyle};
									${staggerHandler.current.staggerStyle};
								`}
								to={'/signup'}
							>
								Sign up
							</SideMenuTextLink>
						</>
					)}
					<CardHorizontalRule
						cssExtra={css`
							width: 12px;
							${staggerHandler.current.staggerStyle};
						`}
					/>
					<SideMenuTextLink cssExtra={staggerHandler.current.staggerStyle} to={'/'}>
						Home
					</SideMenuTextLink>
					<SideMenuTextLink cssExtra={staggerHandler.current.staggerStyle} to={'/contact'}>
						Contact
					</SideMenuTextLink>
				</div>
				<div
					css={css`
						display: flex;
						flex-direction: column;
						row-gap: 20px;
						align-items: center;

						${staggerHandler.current.staggerStyle};
					`}
				>
					<SocialLinkSet fill={'var(--muted-color-2)'} />
					<PolicyLinkSet />
				</div>
			</nav>
		</CSSTransition>
	);
}
