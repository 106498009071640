import { useState } from 'react';

import { css } from '@emotion/react';

import AccountSection from '../AccountSection';
import RedeemCodeForm from '../../../components/input/forms/RedeemCodeForm';
import { NotificationContext, useNotificationDispatch } from '../../../hooks/notification';
import AccountActionContainer from '../AccountActionContainer';
import AccountActionContainerSubmit from '../AccountActionContainerSubmit';
import BoxNotification from '../../../components/layout/notification/BoxNotification';

const formStyle = css`
	display: flex;
	flex-direction: column;
	row-gap: 1em;
	align-self: stretch;
	max-width: 320px;
	margin: 3em 0;
	font-size: 0.9em;
`;

const parseRedeemCodeError = (_error: Error): string | undefined => {
	return undefined;
};

export default function AccountRedeemCode() {
	const notification = useNotificationDispatch();
	const errorNotification = useNotificationDispatch();

	const [inProgress, setInProgress] = useState(false);

	return (
		<NotificationContext.Provider value={notification}>
			<BoxNotification
				cssExtra={css`
					margin-bottom: 60px;

					& > div:first-of-type {
						min-height: 60px;
					}
				`}
				displayEmpty={false}
			/>
			<AccountSection
				cssExtra={css`
					@media (max-width: 500px) {
						& > .action-container {
							flex-direction: column;
							column-gap: 1.5em;
							align-items: flex-start;

							& > div:first-of-type {
								align-self: stretch;
							}
						}
					}
				`}
				header={'Redeem Code'}
			>
				<AccountActionContainer
					content={
						<NotificationContext.Provider value={errorNotification}>
							<RedeemCodeForm
								cssExtra={formStyle}
								renderSubmitButton={button => (
									<AccountActionContainerSubmit inProgress={inProgress}>
										{button}
									</AccountActionContainerSubmit>
								)}
								onSubmitError={error => {
									setInProgress(false);
									errorNotification.setNotification({
										type: 'error',
										message: parseRedeemCodeError(error) ?? 'Code is invalid',
									});
								}}
								onSubmitStart={() => {
									setInProgress(true);
									notification.removeNotification();
									errorNotification.removeNotification();
								}}
								onSubmitSuccess={() => {
									setInProgress(false);
									notification.setNotification({
										type: 'success',
										message: 'Code applied to your account',
									});
								}}
							/>
						</NotificationContext.Provider>
					}
					description={'Enter valid codes to access offers, games, and promotions'}
					label={'Enter code'}
				/>
			</AccountSection>
		</NotificationContext.Provider>
	);
}
