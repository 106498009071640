import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';

import AuthCard from '../../components/layout/card/AuthCard';
import { rectButtonStyle } from '../../components/layout/card/Card';
import SignupForm from '../../components/input/forms/SignupForm';
import CardFooterNavLink from '../../components/layout/card/CardFooterNavLink';
import CardPolicyDisclaimer from '../../components/layout/card/CardPolicyDisclaimer';

import type { FormValues } from '../../components/input/forms/SignupForm';

export default function SignupCard() {
	const { handleSubmit, control } = useForm<FormValues>({ mode: 'onTouched' });

	return (
		<AuthCard
			alternativeLink={
				<CardFooterNavLink emphasis={'Log in'} to={'/login'}>
					Have an account?
				</CardFooterNavLink>
			}
			authButtonPrefix={'Sign up with'}
			authMode={'signup'}
			disclaimer={<CardPolicyDisclaimer />}
			form={
				<SignupForm
					control={control}
					cssExtra={css`
						& + button[type='submit'] {
							${rectButtonStyle};
						}
					`}
					handleSubmit={handleSubmit}
				/>
			}
			gradientColors={['var(--accent-color-y)', 'var(--accent-color-g)', 'var(--accent-color-b) 80%']}
			panelConfig={{
				accentColor: 'var(--accent-color-y)',
				backgroundColor: 'var(--accent-color-b-alt-3)',
				text: 'Sign up',
			}}
			welcomeText={'Welcome to Daresay'}
		/>
	);
}
