import { css } from '@emotion/react';

import CloseGraphic from '../../../../public/images/ui/close.svg';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'button'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function CloseButton(props: Props) {
	const { cssExtra, ...delegatedProps } = props;

	return (
		<button
			css={[
				css`
					transition: color var(--ease-time) var(--ease-fn);

					&:hover {
						color: var(--accent-color-r);
					}

					& > svg {
						height: 0.75em;
					}
				`,
				cssExtra,
			]}
			type={'button'}
			{...delegatedProps}
		>
			<CloseGraphic />
		</button>
	);
}
