import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'span'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function ArticleLabeledText(props: Props) {
	const { cssExtra, children, ...delegatedAttributes } = props;
	return (
		<span
			css={css`
				font-weight: 700;
				color: var(--accent-color-r);

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			{children}
		</span>
	);
}
