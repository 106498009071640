import { css } from '@emotion/react';

import StyledAnchor, { Style } from '../../buttons/core/StyledAnchor';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'section'> {
	header: string;
	id: string;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export const contentClass = 'article-subsection';

export default function ArticleSubsection(props: Props) {
	const { header, className, cssExtra, children, ...delegatedAttributes } = props;
	return (
		<section
			className={className ? `${className} ${contentClass}` : contentClass}
			css={[
				css`
					scroll-margin-top: calc(var(--header-height) + 1em);

					&:not(:first-child) {
						margin-top: 3em;
					}

					&:not(:last-child) {
						margin-bottom: 3em;
					}
				`,
				cssExtra,
			]}
			{...delegatedAttributes}
		>
			<StyledAnchor
				cssExtra={css`
					white-space: normal;

					&:hover {
						color: var(--muted-color-4);
					}
				`}
				href={`#${delegatedAttributes.id}`}
				styleType={Style.TEXT}
			>
				<h3
					css={css`
						width: fit-content;
						margin-bottom: 1.4em;
						text-transform: capitalize;

						&::before {
							font-size: 0.85em;
							color: var(--accent-color-b-alt-3);
							content: counter(article-h3, upper-alpha) '.\\2000\\2000';
							counter-increment: article-h3;
						}

						@media (max-width: 600px) {
							&::before {
								white-space: pre;
								content: counter(article-h3, upper-alpha) '\\A';
							}

							& > span {
								display: block;
								margin-top: 1em;
							}
						}
					`}
				>
					<span>{header}</span>
				</h3>
			</StyledAnchor>
			{children}
		</section>
	);
}
