import { createContext, useContext, useState } from 'react';

import { nanoid } from 'nanoid';

export interface NotificationMessage {
	id: string;
	message: string;
	type: 'info' | 'success' | 'warn' | 'error';
}

export function useNotificationDispatch() {
	// eslint-disable-next-line react/hook-use-state
	const [notification, _setNotification] = useState<NotificationMessage | null>(null);

	return {
		notification,
		setNotification(this: void, message: Omit<NotificationMessage, 'id'> & { id?: string }) {
			_setNotification({
				...message,
				id: message.id ?? nanoid(6),
			});
		},
		removeNotification(this: void, id?: string) {
			if (notification === null) return true;

			if (!id || notification.id === id) {
				_setNotification(null);
				return true;
			}

			return false;
		},
	};
}

export type NotificationDispatch = ReturnType<typeof useNotificationDispatch>;

export const NotificationContext = createContext<NotificationDispatch>(null as unknown as NotificationDispatch);

export function useNotification() {
	const context = useContext(NotificationContext);

	return context;
}
