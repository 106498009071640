import { css } from '@emotion/react';

import StyledNavLink, { Style } from '../buttons/core/StyledNavLink';
import { textLinkStyle } from './PolicyLinkSet';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'span'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function PolicyDisclaimer(props: Props) {
	const { cssExtra, ...delegatedAttributes } = props;
	return (
		<span
			css={css`
				padding: 0 12px;
				font-size: 0.8em;
				text-align: center;
				cursor: default;

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			By continuing, you agree to our{' '}
			<StyledNavLink cssExtra={textLinkStyle} styleType={Style.TEXT} to={'/terms'}>
				Terms of Use
			</StyledNavLink>
			.
		</span>
	);
}
