import { css } from '@emotion/react';

import StyledNavLink, { Style } from './core/StyledNavLink';

import type { ReactElement } from 'react';
import type { SerializedStyles } from '@emotion/react';
import type { Props as StyledNavLinkProps } from './core/StyledNavLink';

interface Props extends StyledNavLinkProps {
	cssExtra?: SerializedStyles | SerializedStyles[];
	children: string | ReactElement | ReactElement[];
}

export default function SideMenuTextLink(props: Props) {
	const { children, cssExtra, ...delegatedProps } = props;

	return (
		<StyledNavLink
			css={css`
				font-weight: 400;

				&:is(:link, :visited, :active) {
					color: var(--muted-color-5);
				}

				&:is(:link, :visited, :hover, :active).active {
					color: var(--accent-color-b-alt-4);
				}

				&:hover {
					color: var(--background-color);
					text-shadow: 0.005em 0 0 currentColor;
				}

				${cssExtra};
			`}
			styleType={Style.TEXT}
			{...delegatedProps}
		>
			{children}
		</StyledNavLink>
	);
}
