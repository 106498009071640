import { css } from '@emotion/react';

import AccountSection from '../AccountSection';
import { AuthProvider } from '../../../components/buttons/AuthButton';
import AccountConnectedAccount from './AccountConnectedAccount';
import { NotificationContext, useNotificationDispatch } from '../../../hooks/notification';
import BoxNotification from '../../../components/layout/notification/BoxNotification';

import type { User } from '../../../../types';

type ConfigItem<T extends keyof User['auth']> = {
	provider: AuthProvider;
	strategy: T;
	description: string;
	getIdentifier?: (account: User['auth'][T]) => string | undefined;
};

export const config: ConfigItem<keyof User['auth']>[] = [
	{ provider: AuthProvider.GOOGLE, strategy: 'google', description: 'Connect account to login with Google' },
	{ provider: AuthProvider.DISCORD, strategy: 'discord', description: 'Connect account to integrate with Discord' },
	// { provider: AuthProvider.APPLE, strategy: 'apple', description: 'Connect account to login with Apple' },
	// { provider: AuthProvider.STEAM, strategy: 'steam', description: 'Connect account to integrate with Steam' },
	{
		provider: AuthProvider.REDDIT,
		strategy: 'reddit',
		description: 'Connect account to integrate with Reddit',
		getIdentifier: account => {
			const { username } = account as Record<string, unknown>;
			return typeof username === 'string' ? `/u/${username}` : undefined;
		},
	},
	{
		provider: AuthProvider.TWITCH,
		strategy: 'twitch',
		description: 'Connect account to integrate with Twitch streaming',
	},
	{
		provider: AuthProvider.FACEBOOK,
		strategy: 'facebook',
		description: 'Connect account to discover friends playing Daresay games',
	},
];

export default function AccountConnectedAccounts() {
	const notification = useNotificationDispatch();

	// TODO: show notifications if `render-data` param is provided

	return (
		<NotificationContext.Provider value={notification}>
			<BoxNotification
				cssExtra={css`
					margin-bottom: 60px;

					& > div:first-of-type {
						min-height: 60px;
					}
				`}
				displayEmpty={false}
			/>
			<AccountSection
				cssExtra={css`
					& > div:not(:last-child) {
						border-bottom: 1px solid var(--muted-color-5);
					}
				`}
				header={'Connected Accounts'}
			>
				{config.map(el => (
					<AccountConnectedAccount
						key={el.provider}
						cssExtra={css`
							@media (max-width: 500px) {
								flex-direction: column;
								column-gap: 1.5em;
								align-items: flex-start;

								& > .connected {
									text-align: start;
								}
							}
						`}
						description={el.description}
						failureRedirectUri={window.location.pathname}
						getIdentifier={el.getIdentifier}
						provider={el.provider}
						redirectUri={window.location.pathname}
						strategy={el.strategy}
					/>
				))}
			</AccountSection>
		</NotificationContext.Provider>
	);
}
