import { useId, useState } from 'react';

import { css } from '@emotion/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as Sentry from '@sentry/react';

import { SentinelError } from '@daresay/api-client';

import AccountSection from '../AccountSection';
import AccountSubsection from '../AccountSubsection';
import StyledButton, { Style } from '../../../components/buttons/core/StyledButton';
import SwitchBox from '../../../components/input/core/SwitchBox';
import AccountActionContainer from '../AccountActionContainer';
import { useUser } from '../../../hooks/user';
import { NotificationContext, useNotificationDispatch } from '../../../hooks/notification';
import InlineNotification from '../../../components/layout/notification/InlineNotification';
import BoxNotification from '../../../components/layout/notification/BoxNotification';
import CardDancingCirclesSpinner from '../../../components/layout/card/CardDancingCirclesSpinner';

import type { User, NotificationRecords } from '../../../../types';

const notificationTypes: Array<keyof User['settings']['notifications']> = ['email'];

const config: { description: string; label: string; notificationKey: keyof NotificationRecords }[] = [
	{
		description: 'Game releases, new features, improvements, and other product updates',
		label: 'Product news',
		notificationKey: 'product',
	},
	{
		description: 'Occasional news about promotions like deals and discounts',
		label: 'Promotions and offers',
		notificationKey: 'promotion',
	},
];

const parseNotificationsUpdateError = (error: Error): string | undefined => {
	Sentry.captureException(error);
	return undefined;
};

export default function AccountNotificationSettings() {
	const notification = useNotificationDispatch();
	const errorNotification = useNotificationDispatch();

	const [inProgress, setInProgress] = useState(false);

	const { user, updateNotificationSettings } = useUser();

	if (!user) throw new Error('User is not available');

	const initialNotificationSettings = (() => {
		const notificationKeys = config.map(el => el.notificationKey);
		return notificationTypes.reduce<{
			[P in keyof User['settings']['notifications']]?: User['settings']['notifications'][P];
		}>((acc, type) => {
			acc[type] = notificationKeys.reduce((_acc, key) => {
				_acc[key] = user.settings.notifications[type][key];
				return _acc;
			}, {} as Record<keyof NotificationRecords, boolean>);
			return acc;
		}, {});
	})() as User['settings']['notifications'];

	const [notificationSettings, setNotificationSettings] = useState(initialNotificationSettings);

	const [formId] = useId();

	const { executeRecaptcha } = useGoogleReCaptcha();

	return (
		<NotificationContext.Provider value={notification}>
			<BoxNotification
				cssExtra={css`
					margin-bottom: 60px;

					& > div:first-of-type {
						min-height: 60px;
					}
				`}
				displayEmpty={false}
			/>
			<AccountSection header={'Notifications'}>
				<NotificationContext.Provider value={errorNotification}>
					<AccountSubsection header={'Daresay Updates'}>
						<form
							noValidate
							id={formId}
							onReset={e => {
								e.preventDefault();

								notification.removeNotification();
								errorNotification.removeNotification();

								setNotificationSettings(initialNotificationSettings);
							}}
							onSubmit={e => {
								e.preventDefault();

								setInProgress(true);
								notification.removeNotification();
								errorNotification.removeNotification();

								(async () => {
									if (!executeRecaptcha) throw new SentinelError('Unexpected error', '');
									const recaptchaToken = await executeRecaptcha('UPDATE_SETTINGS_NOTIFICATIONS');

									return updateNotificationSettings({
										notifications: notificationSettings,
										recaptchaToken,
									});
								})()
									.then(res => {
										setNotificationSettings(res.payload);
										notification.setNotification({
											type: 'success',
											message: 'Notifications saved',
										});
									})
									.catch((error: Error) => {
										errorNotification.setNotification({
											type: 'error',
											message:
												parseNotificationsUpdateError(error) ??
												'An unexpected error has occurred',
										});
									})
									.finally(() => {
										setInProgress(false);
									});
							}}
						>
							{/* TODO: need to make this dynamic based on notification types */}
							{config.map(el => {
								return (
									<AccountActionContainer
										key={el.notificationKey}
										description={el.description}
										label={el.label}
									>
										<SwitchBox
											inputProps={{
												checked: notificationSettings.email[el.notificationKey],
												name: el.notificationKey,
												onChange: e => {
													setNotificationSettings({
														...notificationSettings,
														email: {
															...notificationSettings.email,
															[el.notificationKey]: e.currentTarget.checked,
														},
													});
												},
											}}
										/>
									</AccountActionContainer>
								);
							})}
						</form>
					</AccountSubsection>
					<div
						css={css`
							display: flex;
							flex-wrap: wrap-reverse;
							row-gap: 2em;
							column-gap: 3em;
							align-items: center;
							justify-content: flex-end;
							margin-top: 2.5em;
						`}
					>
						<CardDancingCirclesSpinner show={inProgress} />
						<InlineNotification
							invert
							cssExtra={css`
								font-size: 0.9em;
							`}
						/>
						<div
							css={css`
								display: flex;
								flex-wrap: inherit;
								row-gap: 1em;
								column-gap: 2em;
								align-items: inherit;
								justify-content: inherit;
								margin-left: auto;
							`}
						>
							<StyledButton
								css={css`
									color: var(--muted-color-1);

									&:hover {
										color: var(--accent-color-r);
									}
								`}
								form={formId}
								styleType={Style.TEXT}
								type={'reset'}
							>
								Cancel
							</StyledButton>
							<StyledButton
								css={css`
									min-width: 96px;
								`}
								form={formId}
								styleType={Style.RECT}
								type={'submit'}
							>
								Save
							</StyledButton>
						</div>
					</div>
				</NotificationContext.Provider>
			</AccountSection>
		</NotificationContext.Provider>
	);
}
