import { Controller } from 'react-hook-form';

import TextInput, { transformRegisterRules } from './core/TextInput';

import type { FieldPath } from 'react-hook-form/dist/types';
import type { Require } from '@daresay/types';
import type { ControllerProps, FieldValues } from 'react-hook-form';
import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
	controllerProps: Omit<Require<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'defaultValue'>, 'render'>;
	inputProps?: ComponentPropsWithoutRef<'input'>;
	labelProps?: ComponentPropsWithoutRef<'label'>;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function TextRedemptionCodeInput<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
	const { controllerProps, inputProps, labelProps, cssExtra } = props;

	const rules = transformRegisterRules('Code', {
		required: true,
		...controllerProps.rules,
	});

	return (
		<Controller
			{...controllerProps}
			render={({ field, fieldState }) => {
				return (
					<TextInput
						cssExtra={cssExtra}
						errorMessage={fieldState.error?.message}
						inputProps={{
							type: 'text',
							spellCheck: false,
							autoComplete: 'off',
							autoCorrect: 'off',
							...inputProps,
							...field,
						}}
						labelProps={{ children: 'Code', ...labelProps }}
					/>
				);
			}}
			rules={rules}
		/>
	);
}
