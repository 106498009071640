import { css } from '@emotion/react';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

interface Props extends ComponentPropsWithoutRef<'span'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function CardHeading(props: Props) {
	const { cssExtra, ...delegatedProps } = props;
	return (
		<span
			css={css`
				margin-bottom: 8px;
				font-size: 1.2em;
				font-weight: 700;
				text-align: center;
				cursor: default;

				${cssExtra};
			`}
			{...delegatedProps}
		/>
	);
}
