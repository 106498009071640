import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

import DancingCirclesSpinner from '../../graphics/animations/DancingCirclesSpinner';

import type { SerializedStyles } from '@emotion/react';

interface Props {
	show: boolean;
	cssExtra?: SerializedStyles | SerializedStyles[];
}

export default function CardDancingCirclesSpinner(props: Props) {
	const { show, cssExtra } = props;

	return (
		<CSSTransition
			unmountOnExit
			addEndListener={(node, done) => {
				node.addEventListener('transitionend', done, false);
			}}
			classNames={'fade'}
			in={show}
		>
			<DancingCirclesSpinner
				cssExtra={css`
					align-self: center;
					font-size: 0.6em;

					${cssExtra};
				`}
			/>
		</CSSTransition>
	);
}
