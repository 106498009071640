import { css } from '@emotion/react';

import { textButtonStyle } from './Card';
import { Style } from '../../buttons/core/StyledButton';
import StyledNavLink from '../../buttons/core/StyledNavLink';

import type { Props as StyledNavLinkProps } from '../../buttons/core/StyledNavLink';

interface Props extends StyledNavLinkProps {
	emphasis?: string;
}

export default function CardFooterNavLink(props: Props) {
	const { emphasis, cssExtra, children, ...delegatedProps } = props;

	return (
		<StyledNavLink
			cssExtra={css`
				${textButtonStyle};
				font-size: 0.95em;

				${cssExtra};
			`}
			styleType={Style.TEXT}
			{...delegatedProps}
		>
			{(() => {
				if (!emphasis) return children;

				return (
					<>
						{children}{' '}
						<span
							css={css`
								text-decoration: underline;
								white-space: nowrap;
							`}
						>
							{emphasis}
						</span>
					</>
				);
			})()}
		</StyledNavLink>
	);
}
