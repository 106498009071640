import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';

import DaresayLogo from '../graphics/vectors/DaresayLogo';

import type { NavLinkProps } from 'react-router-dom';
import type { Props as DaresayLogoProps } from '../graphics/vectors/DaresayLogo';

interface Props extends DaresayLogoProps {
	to?: NavLinkProps['to'];
	title?: string; // TODO: require if `url` provided
	fill?: string;
	invert?: boolean;
}

export default function DaresayLink(props: Props) {
	const { title, to, height, type, fill, invert, cssExtra } = props;

	const fillValue = fill ?? 'var(--primary-color)';

	const selector = invert ? '&:not(:hover)' : '&:hover';

	return (
		<NavLink
			css={css`
				&:is(:link, :visited, :hover, :active) {
					color: var(--primary-color);
				}

				& stop {
					transition: stop-color var(--ease-time) var(--ease-fn);
				}

				& [fill]:not([fill^='url']):not([fill='currentColor' i]) {
					transition: fill var(--ease-time) var(--ease-fn);
				}

				${selector} stop {
					stop-color: ${fillValue};
				}

				// noinspection Stylelint
				${selector} [fill]:not([fill^='url']):not([fill='currentColor' i]) {
					fill: ${fillValue};
				}

				${cssExtra};
			`}
			rel={'noopener'}
			role={'link'}
			title={title ?? 'Home | Daresay'}
			to={to ?? '/'}
		>
			<DaresayLogo height={height} type={type} />
		</NavLink>
	);
}
