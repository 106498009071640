import { css } from '@emotion/react';

import SocialLink from '../buttons/SocialAnchor';
import { SocialLogoType } from '../graphics/vectors/social/SocialLogo';
import { useMeta } from '../../hooks/dom';

import type { ComponentPropsWithoutRef } from 'react';
import type { SerializedStyles } from '@emotion/react';

export interface Props extends ComponentPropsWithoutRef<'div'> {
	cssExtra?: SerializedStyles | SerializedStyles[];
	fill?: string;
}

export default function SocialLinkSet(props: Props) {
	const meta = useMeta();

	const { fill: _fill, cssExtra, ...delegatedAttributes } = props;
	const fill = _fill ?? 'var(--muted-color-1)';

	return (
		<div
			css={css`
				display: flex;
				column-gap: 20px;
				align-items: center;
				justify-content: space-between;

				${cssExtra};
			`}
			{...delegatedAttributes}
		>
			<SocialLink
				fill={fill}
				height={'18px'}
				title={'Follow us on Instagram'}
				type={SocialLogoType.INSTAGRAM}
				url={meta.current['daresay:social:instagram:url'] as string}
			/>
			<SocialLink
				fill={fill}
				height={'19px'}
				title={'Follow us on Twitter'}
				type={SocialLogoType.TWITTER}
				url={meta.current['daresay:social:twitter:url'] as string}
			/>
		</div>
	);
}
