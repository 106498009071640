import { useId } from 'react';

import type { ComponentPropsWithoutRef } from 'react';

export default function DiscordLogo(props: ComponentPropsWithoutRef<'svg'>) {
	const defsId = useId();

	return (
		<svg viewBox={'0 0 160 160'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
			<defs>
				<clipPath id={defsId}>
					<rect fill={'none'} height={'123.9437'} width={'160'} y={'18.0282'} />
				</clipPath>
			</defs>
			<g clipPath={`url(#${defsId})`}>
				<path
					d={
						'M135.4468,29.0655a131.9444,131.9444,0,0,0-32.5675-10.1012.4945.4945,0,0,0-.5235.2476,91.9221,91.9221,0,0,0-4.0552,8.33,121.8092,121.8092,0,0,0-36.5785,0,84.2836,84.2836,0,0,0-4.1183-8.33.5138.5138,0,0,0-.5235-.2476A131.5729,131.5729,0,0,0,24.5129,29.0655a.4651.4651,0,0,0-.2143.1841C3.5559,60.2386-2.1263,90.466.6612,120.3186a.5493.5493,0,0,0,.2081.3746A132.6822,132.6822,0,0,0,40.8219,140.889a.5173.5173,0,0,0,.5613-.1841A94.8337,94.8337,0,0,0,49.5565,127.41a.5065.5065,0,0,0-.2774-.7047,87.3631,87.3631,0,0,1-12.4809-5.9489.5132.5132,0,0,1-.05-.8509c.8388-.6285,1.6778-1.2825,2.4787-1.9428a.4948.4948,0,0,1,.5171-.07c26.1851,11.9551,54.5335,11.9551,80.41,0a.493.493,0,0,1,.5235.0635c.8011.66,1.64,1.3206,2.485,1.9491a.5129.5129,0,0,1-.0442.8509,81.9868,81.9868,0,0,1-12.4872,5.9426.51.51,0,0,0-.2711.711,106.47,106.47,0,0,0,8.167,13.2883.5071.5071,0,0,0,.5613.19,132.2416,132.2416,0,0,0,40.0156-20.1958.5134.5134,0,0,0,.2082-.3682c3.3361-34.5129-5.5878-64.4924-23.6563-91.0691A.4067.4067,0,0,0,135.4468,29.0655Zm-81.98,73.076c-7.8835,0-14.3792-7.2376-14.3792-16.1262s6.37-16.1262,14.3792-16.1262c8.0724,0,14.5053,7.3012,14.3791,16.1262C67.8459,94.9039,61.4761,102.1415,53.4668,102.1415Zm53.1649,0c-7.8833,0-14.3791-7.2376-14.3791-16.1262s6.37-16.1262,14.3791-16.1262c8.0725,0,14.5052,7.3012,14.3792,16.1262C121.0109,94.9039,114.7042,102.1415,106.6317,102.1415Z'
					}
					fill={'#5865f2'}
				/>
			</g>
		</svg>
	);
}
